import { useEffect, useRef } from "react"

export const DirectoryPathSelector = ({ currentDirectory, setCurrentDirectory }: { currentDirectory: string, setCurrentDirectory: Function }) => {
  const splitDirectory = currentDirectory.split("/")
  const directoryPath = splitDirectory.slice(0, splitDirectory.length - 1).join("/")

  const directoryArr = directoryPath.split("/")

  const handleDirectorySelect = (idx: number) => {
    const selectedDirectory = directoryArr.slice(0, idx + 1).join("/")
    setCurrentDirectory(`${selectedDirectory}/`)
  }

  const selectableClassName = "underline text-blue-500 cursor-pointer"
  const directoryRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    directoryRef.current?.scrollTo(100000, 0)
  }, [currentDirectory])

  return (
    <div ref={directoryRef} className="flex flex-row gap-2 items-center text-xs overflow-x-auto overflow-y-hidden pl-2">
      <p onClick={() => { setCurrentDirectory("/") }} className="cursor-pointer text-blue-500 underline">root</p>
      <p>/</p>
      {
        directoryArr.map((item, idx) => {
          return (
            <div className="flex gap-2 items-center my-3" key={idx}>
              <p className={`${selectableClassName} whitespace-nowrap`} onClick={() => {
                handleDirectorySelect(idx)
              }}>{item}</p>
              <p style={{ display: directoryArr.length - 1 === idx ? 'none' : 'block' }}>/</p>
            </div>
          )
        })
      }
    </div>
  )
}

import { create } from 'zustand'

interface ISelectedRecordStore {
    tableName: string,
    recordId: string,
    tag: string,
    storedFieldName: string,
    storedValue: any,
    storedField: {[key: string]: any},
    dealershipGroupId: {dealershipGroupId: string},
    dealershipId: {dealershipId: string},
    setTableName: (table:string) => void,
    removeStoredField: () => void,
    setStoredField: (field:string , value:any) => void,
    setTableRecord: (table:string, id:number) => void,
    setDealershipGroupId: (id:number) => void,
    setDealershipId: (id:number) => void,
}

const useSelectedRecordStore = create<ISelectedRecordStore>((set) => ({
    tableName: "",
    recordId: "",
    tag: "",
    storedFieldName: "",
    storedValue: "",
    storedField: {storedFieldName: "", storedValue: ""},
    setTableName : (table:string) => set(() => ({ tableName: table })),
    removeStoredField: () => set(() => ({ storedField: {storedFieldName: "", storedValue: ""}})),
    setStoredField: (field: string , value: any) => set(() => ({ storedField: {storedFieldName: field, storedValue: value}})),
    setTableRecord: (table:string, id:number) => set(() => ({ tableName: table, recordId: id.toString() })),
    dealershipGroupId: {dealershipGroupId: ""},
    setDealershipGroupId: (id:number) => set(() => ({ dealershipGroupId: { dealershipGroupId: id===-1? "" : id.toString()}})),
    dealershipId: {dealershipId: ""},
    setDealershipId: (id:number) => set(() => ({ dealershipId: {dealershipId: id===-1? "" : id.toString()}}))
}))

// An intermediate hook is required before access the store for future possible hook implementations
export const useSelectedRecordHook = () => {
    return useSelectedRecordStore()
}
import axios from "axios";
import { getAuthenticationToken } from "../../api/apiAuthentication";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { api_host } from "../../config/hostConfig";

export interface IBrochureAssetQuery {
    id: number,
    display_name: string,
    vehicle_variant: {
        id: number
    },
    new_vehicle_configuration: IConfigurationQuery[]
}

export interface IConfigurationQuery {
    vehicle_configuration: IVehicleConfigurationQuery,
    new_vehicle_configuration_assets: IConfigurationAssetQuery[]
}

export interface IVehicleConfigurationQuery {
    body_colour: string,
    body_colour_logo: string
}


export interface IConfigurationAssetQuery {
    image_url: string,
    image_type: string
}

export const useFetchBrochureAssets = (shortLink: string) => {

    const host = api_host
  
    const queryClient = useQueryClient()
  
    const fetchBrochureAssets = async () => {
  
      let api_url = `${host}/api/brochure-assets/${shortLink}`

  
      const res = await axios.get(api_url, getAuthenticationToken())
      console.log("fetch brochure response data : " , res.data)
  
      // return the very first page 
      return res.data?.data?.[0];
    }
  
    const refreshAsset = () => {
      queryClient.invalidateQueries(shortLink)
    }

    const { isLoading, error, data } = useQuery<IBrochureAssetQuery[]>({
        queryKey: shortLink,
        queryFn: fetchBrochureAssets
      });
  
    return { isLoading, error, data, refreshAsset }
  }
import { useQuery, useQueryClient, useMutation } from "react-query";

export const useFetchList =  <T,>(getListAPI: (path: string) => Promise<any>, queryName: string, path:string) => {

    const getAPI = async () => {
        return await getListAPI(path)
    }

    const { status, data, error, isFetching } = useQuery<Array<T>, Error>(queryName, getAPI)

    const queryClient = useQueryClient()

    const invalidateQuery = () => {
        queryClient.invalidateQueries(queryName)
    }

    return { isFetching, data, invalidateQuery }
}

export const useFetchWithParams =  <T,>(getListAPI: (path: string , params: string , value: any) => Promise<any>, queryName: string, path:string , params: string , value: any) => {

    const getAPI = async () => {
        return await getListAPI(path , params , value)
    }

    const { status, data, error, isFetching } = useQuery<Array<T>, Error>(queryName, getAPI)

    const queryClient = useQueryClient()

    const invalidateQuery = () => {
        queryClient.invalidateQueries(queryName)
    }

    return { isFetching, data, invalidateQuery }
}

export const useFetchSingle = <T,>(getSingleAPI: (path: string , id: number) => Promise<any> , id: number , queryName: string , path:string) => {

    const fetch = async () => {
        return await getSingleAPI(path , id)
    }

    const { status, data, error, isFetching } = useQuery<T, Error>(queryName, fetch)

    const queryClient = useQueryClient()

    const invalidateQuery = () => {
        queryClient.invalidateQueries(queryName)
    }

    return { isFetching, data, invalidateQuery }
}

export const useUpdateSingle = <T,>(updateSingleAPI: (path:string , id: number, obj: T) => Promise<any>, id: number, invalidateQueryName: string , path:string) => {

    const queryClient = useQueryClient()

    const update = async (obj: T) => {
        return await updateSingleAPI(path , id, obj)
    }

    // const mutation = useMutation(update, {
    //     onSuccess: () => {
    //         queryClient.invalidateQueries(invalidateQueryName)
    //     }
    // })

    // const updateQuery = (obj: T) => {
    //     mutation.mutate(obj)
    // }

    const updateQuery = async (obj: T):Promise<T|null> => {
        try {
            const data = await update(obj)

            if(!data) return null

            await queryClient.invalidateQueries(invalidateQueryName)
            return data
        } catch (err) {
            console.log(err)
        }
        return null
    }

    return updateQuery
}

export const useCreateSingle = <T,>(createSingleAPI: (path:string , obj: T) => Promise<any>, invalidateQueryName: string , path:string) => {

    const queryClient = useQueryClient()

    const createQuery = async (obj: T):Promise<T|null> => {
        try {
            const data = await createSingleAPI(path , obj)

            if(!data) return null
            await queryClient.invalidateQueries(invalidateQueryName)
            return data
        } catch (err) {
            console.log(err)
        }
        return null
    }

    return createQuery
}

export const useCreateSingleWithoutInvalidate = <T,>(createSingleAPI: (path:string , obj: T) => Promise<any>, path:string) => {
    
    const createQuery = async (obj: T):Promise<T|null> => {
        try {
            const data = await createSingleAPI(path , obj)

            if(!data) return null
            return data
        } catch (err) {
            console.log(err)
        }
        return null
    }

    return createQuery
}

export const useDeleteSingle = <T,>(deleteSingleAPI: (path:string ,id:number) => Promise<any>, invalidateQueryName: string, path:string) => {

    const queryClient = useQueryClient()

    const deleteQuery = async (id: number):Promise<T|null> => {
        try {
            const data = await deleteSingleAPI(path , id)

            if(!data) return null
            queryClient.invalidateQueries(invalidateQueryName)
            return data
        } catch (err) {
            console.log(err)
        }
        return null
    }

    return deleteQuery
}
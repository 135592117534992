import { useState, useMemo, useEffect } from "react"
import { isImageLink } from "../utils/miscUtils"
import Pagination from "./Pagination"
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti"
import { v4 as uuidv4 } from 'uuid';

export interface DisplayTableRows {
    rowData: Array<{ data: JSX.Element, cellType?: string, clickableCallback?: Function, rowSpan?: number, colSpan?: number, cellWidth?: string }>
}

export interface DisplayData {
    data: Array<DisplayTableRows>,
    headers?: Array<JSX.Element>,
    columnsTitle?: Array<string>
}

export const TextCell = ({ data }: { data: string|object }) => {
    if (data===null) return (<></>)
    if (typeof (data) !== "string") return (<p>{JSON.stringify(data)}</p>)
    if (isImageLink(data)) return (
        <div className="whitespace-nowrap">
            <img width="75px" src={data} alt="Not found" onError={handleError}/>
            <p>URL: <a className="text-blue-500" target="_blank" rel="noopener" href={data}>Link</a></p>
        </div>
    )
    return (<p className="w-fit break-all">{data}</p>)
}

export const NumericalCell = ({ data }: { data: number|boolean }) => {
    if(typeof (data) === "boolean") return (<p>{data ? "true" : "false"}</p>)
    return (<p>{data}</p>)
}

const handleError = (e: any) => {
    e.target.onerror = null
    e.target.src = "https://static.vecteezy.com/system/resources/thumbnails/005/720/408/small_2x/crossed-image-icon-picture-not-available-delete-picture-symbol-free-vector.jpg"
}

const PageSizeSelector = ({ pageSize, setPageSize }: { pageSize: number, setPageSize: Function }) => {
    return (
        <div className="flex align-middle h-7 min-w[380px]:flex-row md:flex-row flex-col pb-1">
            <select value={pageSize} onChange={(e) => { setPageSize(parseInt(e.target.value)) }}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
            </select>
            <label className="pl-1 text-xs min-w[380px]:pl-2 min-w[380px]:text-sm md:text-sm">items per page</label>
        </div>
    )
}

export interface IHeaderCell {
    title: string,
    sortCallback?: Function,
    sortDir?: string
}

export const HeaderCell = ({ title, sortCallback, sortDir }: IHeaderCell) => {
    return (
        <div className="flex flex-row justify-center justify-items-center select-none px-2 py-2 hover:bg-gray-200 cursor-pointer" onClick={() => { sortCallback && sortCallback() }}>
            <p className="font-semibold">{title}</p>
            <div className="w-2">
                {
                    (sortCallback && sortDir) ?
                        (sortDir === "asc" ? <TiArrowSortedUp /> : <TiArrowSortedDown />) :
                        <></>
                }
            </div>
        </div>
    )
}


const DisplayTable = ({ headers, data, columnsTitle }: DisplayData) => {
    const [clicked, setClicked] = useState(false)
    const [width, setWidth] = useState<number[]>([])

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    useEffect(() => {
        setCurrentPage(1)
    }, [data])

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return data?.slice(firstPageIndex, lastPageIndex) || [];
    }, [currentPage, pageSize, data]);

    const changePageSize = (newPageValue: number) => {
        setCurrentPage(1)
        setPageSize(newPageValue)
    }

    useEffect(() => {

        //get headers that are not nested in other headers (eg. sort headers)
        const filteredCells = document.querySelectorAll("thead th:not(:has(th))");
        const newWidths: number[] = [];
        filteredCells.forEach(cell => {
            newWidths.push(cell.clientWidth);
        });
        setWidth(newWidths)
    }, [])

    return (
        <>
            <div className="flex flex-row justify-between align-middle pt-3 gap-1">
                <PageSizeSelector pageSize={pageSize} setPageSize={changePageSize} />
                <Pagination
                    currentPage={currentPage}
                    totalCount={data?.length || 0}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>

            <div className="overflow-y-auto shadow-md sm:rounded-md pr-2 pt-2 overflow-x-auto">
                <table className={`text-sm text-left text-gray-600 table ${clicked ? `table-fixed` : `table-auto`} border-collapse min-w-full w-min`} >
                    <thead className=" text-sm bg-gray-100 text-gray-600 border uppercase font-bold">
                        <tr>
                            {
                                headers ?
                                    headers.map((header, index) => {
                                        return (
                                            <th className="text-center pr-2 py-2 border-l border-r border-gray hover:cursor-auto resize-x overflow-x-hidden hover:bg-gray-200 whitespace-nowrap w-min" key={uuidv4()+index}
                                                style={{ width: `${width[index]}px`, minWidth: "80px" }} onMouseDown={() => { setClicked(true) }}
                                            >{header}</th>
                                        )
                                    }) :
                                    columnsTitle?.map((title, index) => {
                                        return (
                                            <th className="text-center pr-2 py-2 border-l border-r border-gray hover:cursor-auto resize-x overflow-x-hidden hover:bg-gray-200 whitespace-nowrap w-min" key={uuidv4()+index}
                                                style={{ width: `${width[index]}px`, minWidth: "80px" }} onMouseDown={() => { setClicked(true) }}
                                            >{title}</th>)
                                    })

                            }
                        </tr>
                    </thead>

                    <tbody>
                        {
                            currentTableData.length === 0 ? 
                            <tr className="hover:bg-blue-100 bg-gray-100 odd:bg-gray-50 border-b" key={uuidv4()}>
                                <td className="pl-3 pr-2 py-2 overflow-hidden" colSpan={headers?.length || columnsTitle?.length || 1}  key={uuidv4()}>No Data Found</td>
                            </tr>
                            :
                            currentTableData.map((row, rowIdx) => {
                                const rowData = row?.rowData?.map((cellData, cellIdx) => {
                                    return (
                                        <td className="pl-3 pr-2 py-2 overflow-hidden" key={uuidv4()+cellIdx+rowIdx} rowSpan={cellData?.rowSpan} colSpan={cellData?.colSpan}>
                                            <div className={cellData.clickableCallback ? "clickable" : ""} onClick={() => {
                                                if (cellData.clickableCallback)
                                                    cellData.clickableCallback()
                                            }}>
                                                {
                                                    cellData.data
                                                }
                                            </div>
                                        </td>
                                    )
                                }) || <td>Empty</td>

                                return (
                                    <>
                                        <tr className="hover:bg-blue-100 bg-gray-100 odd:bg-gray-50 border-b" key={uuidv4()+rowIdx}>{rowData}</tr>
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                currentPage={currentPage}
                totalCount={data?.length || 0}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </>
    )
}

export default DisplayTable;
import { useState, useMemo, useRef, useEffect } from "react";
import { MdExpandMore } from "react-icons/md"
import { BiErrorCircle, BiSearchAlt } from "react-icons/bi"
import { useFetchList } from "../../hooks/crudHook";
import SideBarWrapper from "../wrappers/SideBarWrapper";
import ConfirmationWrapper from "../wrappers/ConfirmationWrapper";
import { TableRelationalDisplayWithoutFetch } from "../TableRelationalDisplay";
import { useOnClickOutside } from "../../hooks/utilHooks/clickOutsideHook";
import { useScrollBodyLock } from "../../hooks/utilHooks/scrollLockHook";
import { FormInputChange } from "../Form";
import { fetchAll } from "../../api/manageAPI";
import { CreatePanel, ICreatePanelProps } from "../CreatePanel";
import { getDefaultWithPrimitiveType } from "../../utils/miscUtils";
import PopUpWrapper from "../wrappers/PopUpWrapper";

export interface IDropdownRelationalSearchProps {
  keyName: string,
  primitiveDataType: string,
  formComponent: {
    label: string,
    type: "dropdown-search",
    pathName: string,   //axios api url path for fetchAll
    queryName: string,
    labelKeys: Array<string>,
    required?: boolean,
    placeholder?: string,
    createForm?: ICreatePanelProps<any> | any,
    parentFormTag: string
  },
  defaultId?: number,
  error?: any
}

export interface IDropdownOption {
  label: string,
  value: string | number | readonly string[] | undefined
}

export interface IDropdownRelationalOption {
  value: string,
  optionData: any
}

// Show confirmation modal for relational items
const RelationalItemConfirmation = <T,>({ handleClose, data, itemTitle, cancelSelection, parentFormName, parentPredecessorName }: { handleClose: () => void, data: T, itemTitle: string, cancelSelection: () => void, parentFormName: string, parentPredecessorName: string }) => {

  return (
    <ConfirmationWrapper closeMenu={handleClose} cancelMenu={cancelSelection} sideBarTag={parentFormName} baseSidebarTag={parentPredecessorName}>
      <div className='w-[250px] md:w-[600px]'>
        <p className='font-bold pt-2 pb-4 pl-3'>Ensure selected data for {itemTitle} is correct</p>
        <TableRelationalDisplayWithoutFetch<T> data={data} />
      </div>
    </ConfirmationWrapper>
  )
}

// Show create form for relational items
const RelationalDetailForm = <T,>({ handleClose, createForm, parentFormName }: { handleClose: () => void, createForm: ICreatePanelProps<T>, parentFormName: string }) => {
  // const Form = createForm
  return (
    <PopUpWrapper closeMenu={handleClose} parentTag={parentFormName}>
      <div className=''>
        <CreatePanel {...createForm} handleClose={handleClose} />
      </div>
    </PopUpWrapper>
  )
}

// This component is essentially for fetching a list of items from the backend and displaying them in a dropdown list
const DropdownRelationalSearch = <T extends { [key: string]: any }>({ keyName, handleInputChange, defaultId, formComponent, primitiveDataType, error }: IDropdownRelationalSearchProps & FormInputChange) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState<number>(-1);
  const [defaultOptionIdx, setDefaultOptionIdx] = useState<number>(-1);

  const dropdownMenuRef = useRef(null)
  const selectDisplayRef = useRef(null)

  useOnClickOutside(dropdownMenuRef, () => setShowDropdown(false), selectDisplayRef)
  const { addScrollLock, removeScrollLock } = useScrollBodyLock()

  const { isFetching, data, invalidateQuery } = useFetchList<T>(fetchAll, formComponent.queryName || "defaultQueryName", formComponent.pathName)

  const [displayCreateForm, setDisplayCreateForm] = useState(false)
  const [displayRelationalConfirmation, setDisplayRelationalConfirmation] = useState(false)

  const searched = useMemo(() => {

    if (!Array.isArray(data)) {
      return []
    }

    const dataOptions = data?.map((item: any) => {
      let labelString = ""
      for (const lk of formComponent.labelKeys) {
        labelString += item[lk] + " "
      }
      return {
        value: labelString,
        optionData: item
      }
    })

    const options: Array<IDropdownRelationalOption> = dataOptions || []
    let results = options?.filter((option) => option.value.toLowerCase().includes(searchQuery)) || []

    return results
  }, [data, searchQuery])

  useEffect(() => {

    if (!defaultId) return

    if (!data || !data.length) return
    if (data.length === 0) return

    data.find((item: any, idx: number) => {
      if (item.id === defaultId) {
        setSelectedOption(idx)
        setDefaultOptionIdx(idx)
      }
    })
  }, [data])

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // When an option is selected, set the selected option and close the dropdown menu and display the confirmation page 
  const onSelectOption = (selectedIdx: number) => {
    setSelectedOption(selectedIdx);
    setShowDropdown(previous => !previous);
    handleInputChange?.(keyName, searched?.[selectedIdx]?.optionData?.id || "")
    setDisplayRelationalConfirmation(true)
  };

  // Show the create form when the create button is clicked
  const createNew = () => {
    addScrollLock(formComponent.parentFormTag)
    setShowDropdown(false)
    setDisplayCreateForm(true)
  }

  // Show the dropdown menu when the dropdown button is clicked
  const onClickDropdown = () => {
    setShowDropdown(previous => !previous);
    setSearchQuery("")
  }

  // Set the option to the default option if it exists when the page is cancelled
  const onCancelSelection = () => {
    const option = defaultId ? defaultOptionIdx : -1
    setSelectedOption(option)
    setShowDropdown(false);
    handleInputChange?.(keyName, searched?.[option]?.optionData?.id || getDefaultWithPrimitiveType(primitiveDataType))
  }

  return (
    <div key={keyName} className={`relative`}>

      {
        displayCreateForm &&
        <RelationalDetailForm handleClose={
          () => {
            removeScrollLock(formComponent.parentFormTag)
            setDisplayCreateForm(false)
          }
        } createForm={{ pathName: formComponent.pathName, queryNameList: formComponent.queryName, ...formComponent.createForm } || {}} parentFormName={keyName} />
      }

      {
        displayRelationalConfirmation &&
        <RelationalItemConfirmation itemTitle={keyName.toUpperCase()} handleClose={
          () => {
            removeScrollLock(formComponent.parentFormTag)
            setDisplayRelationalConfirmation(false)
          }
        }
          cancelSelection={() => {
            onCancelSelection()
            removeScrollLock(formComponent.parentFormTag)
            setDisplayRelationalConfirmation(false)
          }
          }
          data={searched?.[selectedOption]?.optionData}
          parentFormName={keyName}
          parentPredecessorName={formComponent.parentFormTag}
        />
      }

      {/* Button for Dropdown */}
      <label htmlFor={keyName} className="block mb-2 text-sm font-medium text-gray-900 pt-5">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
      <button ref={selectDisplayRef} type="button" className={`inline-flex justify-between bg-gray-50 border ${error ? `border-red-500` : `border-gray-300`} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5`}
        onClick={onClickDropdown}>
        <>
          {/* Display Names */}
          <p>{searched?.[selectedOption]?.value || formComponent.placeholder}</p>
          <MdExpandMore color="gray" />
        </>
      </button>
      {
        error &&
        <div className="flex flex-row gap-x-1 items-center">
          <div className="pt-1">
            <BiErrorCircle color="red" />
          </div>
          <p className="text-red-600 text-sm pt-1">{error}</p>
        </div>
      }

      {showDropdown ?
        <div ref={dropdownMenuRef} className="absolute z-30 w-full">
          {/* Show Dropdown */}
          <div className="pt-1">
            <div className="relative">
              {/* Search Input */}
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <BiSearchAlt color="gray" />
              </div>
              <input type="text"
                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                value={searchQuery}
                required={formComponent.required}
                onChange={onSearchChange}
                placeholder={formComponent.label} />
            </div>
          </div>
          {
            // Dropdown Options
            isFetching ?
              <div className="bg-white w-full text-center"><p>Loading</p></div>
              :
              <ul className="pt-2 border border-gray-500 bg-gray-100 overflow-y-scroll overflow-x-hidden max-h-64 text-sm text-gray-700 cursor-pointer">
                {
                  //if create form is provided, show create new option
                  formComponent.createForm ?
                    <li value={"Create New"} className="pl-2.5 hover:bg-blue-500" onClick={createNew}>
                      Create New
                    </li> :
                    <></>
                }
                {
                  searched?.map((option, idx) => (
                    <li key={idx} value={option.value} className="pl-2.5 hover:bg-blue-500" onClick={() => onSelectOption(idx)}>
                      {option.value}
                    </li>
                  ))
                }
              </ul>
          }

        </div>
        : <></>
      }
    </div>
  )
}

export default DropdownRelationalSearch;
import { useEffect, useState, useRef, useCallback } from "react";
import DisplayTable, { DisplayTableRows, HeaderCell } from "../../components/DisplayTable";
import { BatchHeader } from "./BatchUploadFileUploader"
import { cellFunction, obtainNested } from "../../utils/miscUtils";
import { ProgressBar } from "../../components/Loader";
import { v4 as uuidv4 } from 'uuid';
import { useCreateSingle } from "../../hooks/crudHook";
import { createObj } from "../../api/manageAPI";
import { useAlertToastHook } from "../../hooks/alertToastHook";
import * as ExcelJS from 'exceljs';

enum UploadStatus {
    PENDING = "Pending",
    SUCCESS = "Success",
    FAILED = "Failed"
}

enum ColourCoding {
    PENDING = "text-yellow-500 bg-yellow-200",
    SUCCESS = "text-green-500 bg-green-200",
    FAILED = "text-red-500 bg-red-200"
}

// Page that will perform uploading of csv data to the database and displays the status of each row
export const BatchDatabaseUpload = <T extends { [key: string]: any }>({ data, headers, pathName, queryNameList, dataType }: { data: any, headers: BatchHeader[], pathName: string, queryNameList: string, dataType: string }) => {
    const fileHeader = headers.map((item) => { return item.label });
    const keyHeader = headers.map((item) => { return item.key });
    const [tableData, setTableData] = useState<Array<DisplayTableRows>>([])
    const [processedDataCount, setProcessedDataCount] = useState<number>(0)
    const [uploadData, setUploadData] = useState<Array<any>>(data)
    const [errorDataRows, setErrorDataRows] = useState<Array<any>>([])
    const isUploadedActionCalled = useRef<boolean>(false);

    const query = useCreateSingle<T>(createObj, queryNameList, pathName);
    const { addMessage } = useAlertToastHook();

    const uploadedAction = useCallback(() => {
        uploadDataToDatabase(uploadData);
    }, [uploadData]);

    //performing upload into the database
    const uploadDataToDatabase = async (dataToProcess: Array<any>) => {
        let successCount = 0
        let errorRows: any[] = []

        for (const val of dataToProcess) {
            let { status, ...rest } = val;

            let obj: { [key: string]: any } = Object.keys(rest).reduce((acc: { [key: string]: any }, key) => {
                const newKey = keyHeader[Number(key)];
                acc[newKey] = rest[key];
                return acc;
            }, {});

            // object to be added to database
            const newObj = obtainNested(obj)

            console.log("newObj: ", newObj)
            const result = await query(newObj as T);

            if (result?.id) {
                // when upload is successful
                val.status = UploadStatus.SUCCESS
                dataToProcess[dataToProcess.indexOf(val)] = val
                successCount++
                setUploadData([...dataToProcess])
            }
            else {
                // when upload failed
                const currentValue = dataToProcess[dataToProcess.indexOf(val)]
                currentValue.status = UploadStatus.FAILED
                dataToProcess[dataToProcess.indexOf(val)] = currentValue
                errorRows = [...errorRows, val]
                setUploadData([...dataToProcess])
                setErrorDataRows([...errorDataRows, val])
            }
            setProcessedDataCount((prev: number) => prev + 1)
        }
        setErrorDataRows([...errorRows])
    }

    useEffect(() => {
        // if the data has not been uploaded, then call the uploadedAction
        if (!isUploadedActionCalled.current) {
            uploadedAction();
            isUploadedActionCalled.current = true;
        }

        // display the table data
        const tempData = uploadData.map((item: any, index: number) => {
            if (item.status === null)
                item.status = UploadStatus.PENDING
            const colour = getColor(item.status)
            return ({
                rowData: [
                    {
                        data:
                            <div className="flex justify-center">
                                <p className={`${colour} rounded-md p-1`}>{item?.status || UploadStatus.PENDING}</p>
                            </div>,
                        clickableCallback: () => { }
                    },
                    ...headers.map((header, index) => {
                        let Cell: Function = cellFunction(header.type)
                        let itemIndex = keyHeader.indexOf(header.key);
                        return {
                            data: <Cell key={index.toString() + uuidv4()} data={item[itemIndex]} />,
                            clickableCallback: () => { }
                        }
                    })
                ]
            } || {})
        })
        setTableData(tempData)
    }, [uploadData, uploadedAction]);

    const getColor = (status: UploadStatus) => {
        switch (status) {
            case UploadStatus.PENDING:
                return ColourCoding.PENDING
            case UploadStatus.SUCCESS:
                return ColourCoding.SUCCESS
            case UploadStatus.FAILED:
                return ColourCoding.FAILED
            default:
                return ColourCoding.PENDING
        }
    }

    //generate error file
    function generateError() {
        console.log("error: ", errorDataRows)
        console.log("file headers: ", fileHeader)
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.columns = fileHeader.map((item) => { return { header: item } });
        errorDataRows.map((row: any) => {
            // get the rows of data-> if the cell is undefined then leave it blank
            worksheet.addRow(row);
        })

        workbook.xlsx.writeBuffer().then(buffer => {
            // create a Blob object from the buffer
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // create a link element to download the file
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "SSP_Template_Error_" + dataType + ".xlsx";

            // append the link to the document body and click it programmatically
            document.body.appendChild(link);
            link.click();
        });



        //     // get the rows of data-> if the cell is undefined then leave it blank
        //     const rowData = headers.map((header) => { return header.key }).map((item) => { return row[item] || "" });
        //     worksheet.addRow(rowData);
        // });
        // workbook.xlsx.writeBuffer().then(buffer => {
        //     // create a Blob object from the buffer
        //     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        //     // create a link element to download the file
        //     const link = document.createElement('a');
        //     link.href = window.URL.createObjectURL(blob);
        //     link.download = "SSP_Template_Error_" + dataType + ".xlsx";

        //     // append the link to the document body and click it programmatically
        //     document.body.appendChild(link);
        //     link.click();
        // });
    }

    return (
        <div className="p-2 flex flex-col gap-2">
            <div>
                <p className="text-md">Your data is being uploaded now, please wait until all the rows are updated.</p>
                <ProgressBar current={processedDataCount} total={data.length} />
                <p className="pt-2">{processedDataCount} out of {data.length} rows have been processed.</p>
            </div>
            <div className="bg-white border border-gray-500 p-2 flex flex-col gap-1 rounded-md shadow-md">
                <p>Validation results:</p>
                <div className="flex flex-row gap-1">
                    <p className="text-red-800">Error: </p>
                    <p>{errorDataRows.length}</p>
                </div>
                <div className="flex flex-row gap-1">
                    <p className="text-green-800">Success: </p>
                    <p>{processedDataCount - errorDataRows.length}</p>
                </div>
            </div>
            <div className="border border-gray-200 rounded-md shadow-md p-2">
                <p>Data Upload Status for each row.</p>
                <DisplayTable
                    headers={[<p>Status</p>, ...fileHeader.map((item, index) => { return (<HeaderCell key={uuidv4() + index.toString()} title={item} />) })]}
                    data={tableData} />
            </div>
            {
                errorDataRows.length > 0 &&
                <div>
                    <button className="bg-blue-700 rouned-md shadow-md p-2" onClick={() => { generateError() }}>
                        Download Error Rows
                    </button>
                    <p>
                        Please download the error rows and correct the data before uploading again.
                    </p>
                </div>
            }
            {
                processedDataCount === data.length &&
                <p>The rows that have been indicated with "Success" status have been uploaded into the database!</p>
            }
        </div>
    )
}
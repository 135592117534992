import { useMemo, useState } from "react";
import TextInput from "./formComponents/TextInputField";
import MultipleFormFieldWrapper from "./wrappers/MultipleFormFieldWrapper";
import { FormInputChange } from "./Form";
import { flattenData } from "../utils/miscUtils";
import CheckBox from "./formComponents/CheckboxField";

const CheckBoxForm = ({ handleInputChange, configNames }: FormInputChange & { configNames: any[] }) => {

    const [selectFields, setSelectFields] = useState<Array<{ label: string, value: any }>>([])
    const [forAllSelection, setForAllSelection] = useState<boolean>(false)


    // const colourNames = [
    //     {
    //         name: "Crimson Red",
    //         url: "www.google.com"
    //     },
    //     {
    //         name: "Blooming Blue",
    //         url: "www.google.com"
    //     },
    //     {
    //         name: "Gorgeous Green",
    //         url: "www.google.com"
    //     },
    //     {
    //         name: "Sunny Yellow",
    //         url: "www.google.com"
    //     }
    // ]

    const transformMapper: { [key: string]: string[] } = {
        label: ["colour.colourName"],
        value: ["colour.colourName"]
    }

    const cardTransformer = (data: any) => {
        const goalObject: any = {};
        let mapper = transformMapper

        for (const key in mapper) {
            const fields = mapper[key];
            let obj: any;

            if (fields.length === 1) {
                obj = data?.[fields[0]];
            } else {
                obj = {};
                fields.map(field => {
                    if (key === "value") {
                        const [objectKey, propertyKey] = field.split(".");
                        if (propertyKey === undefined) {
                            obj[objectKey] = data?.[field];
                        }
                        else {
                            obj[objectKey] = { ...obj[objectKey], [propertyKey]: data?.[field] };
                        }
                    }
                    else {
                        obj[field] = data?.[field];
                    }
                });
            }
            goalObject[key] = obj
        }
        return goalObject;
    }

    const selectedFields:Array<{label:string , value:any}> = useMemo(() => {
        const flattenedData = flattenData(configNames)
        let transformedData: Array<{ label: string, value: any }> = flattenedData.map((data) => {
            return cardTransformer(data)
        }
        )
        setSelectFields(transformedData)
        return transformedData
    },[configNames])

    const handleChange = (keyName: string, value: any) => {
        handleInputChange(keyName, value)
    }

    const handleCheckboxChange = (keyName: string, value: any) => {
        setForAllSelection(value)
        handleInputChange(keyName, value)
    }

    return (
        <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 pt-5">Gallery Assets</label>
            <div className="flex flex-row gap-x-2">
                <CheckBox
                    keyName="isGallerySharedForAllConfigs"
                    primitiveDataType="boolean"
                    formComponent={{
                        label: "Apply to all vehicle configurations",
                        type: "checkbox",
                    }} handleInputChange={handleCheckboxChange}checked={forAllSelection}/>
            </div>
            {
                forAllSelection ?
                    <TextInput
                        keyName={"galleryImagesCount"}
                        primitiveDataType="number"
                        formComponent={{
                            label: "Gallery Assets",
                            type: "text",
                            inputType: "number float",
                            placeholder: "Enter Number of Gallery Assets to upload"
                        }} handleInputChange={handleChange} />
                    :
                    <div>
                        {
                            selectFields.length > 0 ?
                                <MultipleFormFieldWrapper
                                    keyName={"galleryColourImageCount"}
                                    primitiveDataType={"object"}
                                    formComponent={{
                                        label: "",
                                        type: "multiple-form",
                                        children: [
                                            {
                                                keyName: "bodyColour",
                                                primitiveDataType: "object",
                                                formComponent: {
                                                    type: "select",
                                                    label: "Body Colour",
                                                    placeholder: "Select Body Colour",
                                                    options: selectedFields,
                                                }
                                            },
                                            {
                                                keyName: "galleryImagesCount",
                                                primitiveDataType: "number",
                                                formComponent: {
                                                    label: "Gallery Assets",
                                                    type: "text",
                                                    inputType: "number float",
                                                    placeholder: "Enter Number of Gallery Assets to upload"
                                                }
                                            }
                                        ]
                                    }}
                                    handleInputChange={handleChange}
                                    maxLength={selectFields.length}
                                />
                                :
                                <div></div>
                        }
                    </div>
            }
        </div>
    )
}

export default CheckBoxForm;

/**
 * <SelectDropdown keyName={"body-colour"} primitiveDataType={"string"} formComponent={{
                            type: "select",
                            label: "Body Colour",
                            placeholder: "Select Body Colour",
                            options: [...selectFields],
                        }} handleInputChange={handleChange} />
                        <TextInput
                            keyName={"gallery-assets"}
                            primitiveDataType={"number"} formComponent={{
                                label: "Gallery Assets",
                                type: "text",
                                inputType: "number float",
                                placeholder: "Enter Number of Gallery Assets to upload"
                            }} handleInputChange={handleChange} />
                        <button type="button">
                            Save Assets
                        </button>
 */
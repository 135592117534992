import { BiErrorCircle } from "react-icons/bi";
import { FormInputChange } from "../Form";
import { useEffect} from "react";

export interface ITextInput {
  keyName: string,
  primitiveDataType: string,
  formComponent: {
    label: string,
    type: "text" | "password",
    required?: boolean,
    placeholder?: string,
    disabled?: boolean,
    inputType?: "text" | "phone" | "email" | "country_code" | "number float"
  }
  loading?: boolean,
  defaultValue?: string | number | readonly string[] | undefined,
  error?: any,
  nested?: boolean // Check if text input is within another form component
}

const TextInput = ({ keyName, loading, defaultValue, handleInputChange, formComponent, error , nested=false }: ITextInput & FormInputChange) => {

  useEffect(() => {
    if (!formComponent.inputType) return;
  }, [])

  return (
    <div key={keyName}>
      <label htmlFor={keyName} className="block mb-2 text-sm font-medium text-gray-900 pt-5">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
      {
        !loading ?
          <>
            <input
              id={"input_" + keyName}
              className={`border ${error ? `border-red-600` : `border-gray-300`}   text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 disabled:focus:ring-black disabled:focus:border-black block w-full p-2.5 hover:border-blue-500 ${formComponent.disabled ? `bg-gray-300` : ``}`}
              type={formComponent.type}
              value={nested? defaultValue: undefined} // if nested, show the defaultValue
              defaultValue={nested? undefined: defaultValue}
              placeholder={formComponent.placeholder}
              disabled={formComponent.disabled}
              required={formComponent.required}
              onChange={(e) => { handleInputChange(keyName, e.target.value) }}
            />
            {
              error ?
                <div className="flex flex-row gap-x-1 items-center">
                  <div className="pt-1">
                    <BiErrorCircle color="red" />
                  </div>
                  <p className="text-red-600 text-sm pt-1">{error}</p>
                </div> : null
            }
          </>
          :
          <div className="justify-start animate-pulse block w-full h-9 rounded-lg bg-gray-300"><p> </p></div>

      }
    </div>
  )
}

export default TextInput;
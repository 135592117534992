import { ChangeEvent, useRef, useState } from "react";
import { FileUploadPostFields, uploadFile } from "../api/uploadFileAPI";
import { SpinnerLoader } from "./Loader";

interface IImageFolderUploader {
    files: Array<File> | null;
    setFiles: Function;
    fileUrl: Array<string>;
    setFileUrl: Function;
    setDragIndex: Function;
    dragIndex: number;
    tableName: string;
    setDragActive: Function;
}

const ImageFolderUploader = ({ files, setFiles, fileUrl, setFileUrl, setDragIndex, dragIndex, tableName, setDragActive }: IImageFolderUploader) => {

    let folderRef = useRef<HTMLInputElement>(null);
    let urlArray = fileUrl
    const [loading, setLoading] = useState<boolean>(false);

    const handleFolder = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        const newFiles = e.target.files;
        setFiles(Array.from(newFiles));
    }

    //handle upload in there
    async function handleUpload(newFile: File | undefined | null, fileUploadData: FileUploadPostFields) {
        if (!newFile) return;
        const response = await uploadFile(newFile, fileUploadData);
        if (!response) return

        //perform upload here to get url
        return response.url
    }

    const onUploadPress = async () => {
        if (!files) return;

        //perform upload of images for each file in the folder
        for (const item of files) {

            const postData = {
                previous_file_name: "",
                table_name: tableName,
                field_name: "",
                record_id: "",
                tag: ""
            }

            const url = await handleUpload(item, postData);
            urlArray = [...urlArray, url]
        }
        setFileUrl(urlArray)
        setLoading(false)
    }

    const onCancelPress = async () => {
        setFiles([])
        setFileUrl([])
        if (folderRef.current) {
            folderRef.current.value = "";
        }
    }

    //handle dragging event
    const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.setData("text/plain", fileUrl?.[dragIndex] || "no name");
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }

    const otherAtt = { directory: "", webkitdirectory: "" }

    return (
        <div className="bg-gray-200 flex flex-col w-[20vw] gap-0.5 pt-2 px-2">
            <p className=" text-blue font-bold pb-3">Upload Images</p>
            {/* Input */}
            <input type="file" {...otherAtt} onChange={handleFolder} ref={folderRef} />
            { //Uploaded images for preview
                files ?
                    <div>
                        {
                            fileUrl.length === 0 ?
                                <div>
                                    <p className="text-red-500">Warning: Please check again before uploading. Once images have been updated, no changed can be made.</p>
                                    {
                                        files.map((item, index) => {
                                            return (
                                                <div className='flex-center pb-0.5 break-b' key={index.toString() + item}>
                                                    <div className="border border-gray-400 p-2">
                                                        <p className="text-sm text-gray-900 bg-inherit text-bold underline">Attached Document:</p>
                                                        <p className="text-sm text-gray-900 bg-inherit w-full">{item?.name || "No Name"}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="flex flex-row justify-between">
                                        {
                                            loading ?
                                                <button className={`hover:bg-blue-400 w-[62px] bg-blue-700 p-2 border border-gray-500 rounded-md ${fileUrl.length > 0 ? `disabled` : ``}`}>
                                                    <div className="w-[23px] h-[23px] text-white border-b-2 border-white rounded-full animate-spin mx-auto" />
                                                </button>
                                                :
                                                <button className={`hover:bg-blue-400 p-2 w-[62px] border bg-blue-700 text-white border-gray-500 rounded-md ${fileUrl.length > 0 ? `disabled` : ``}`} onClick={() => {
                                                    onUploadPress();
                                                    setLoading(true)
                                                }}>Upload</button>
                                        }
                                        <button className="hover:bg-blue-400 px-2 py-1 border border-gray-500 bg-blue-700 text-white rounded-md" onClick={onCancelPress}>Cancel</button>
                                    </div>
                                </div>
                                :
                                <div className=" overflow-y-auto">
                                    {files?.map((item: File, index: number) => {
                                        return (
                                            <div className='flex-center pb-0.' onClick={() => { setDragIndex(index); }}>
                                                <div className="border border-gray-400 p-2" draggable onDragEnter={handleDrag} onDrag={handleDrag} onDragOver={handleDrag}>
                                                    <p className="text-sm text-gray-900 bg-inherit text-bold underline">Uploaded Document:</p>
                                                    <p className="text-sm text-gray-900 bg-inherit w-full">{item?.name || "No Name"}</p>
                                                    <img className=" hover:cursor-pointer" src={fileUrl[index]} alt="No image" />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                        }
                    </div>
                    : <></>
            }
        </div>
    )
}
export default ImageFolderUploader
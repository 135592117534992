interface sellingPriceLevelBody {
    priceType: string,
    prices: {
      dealershipGroupId?: number,
      ptId?: number,
      dealershipGroupBrandBranchId?: number,
      sellingPrice: number
    }[]
}

interface vehiclePopulateDataBody {
    vehicleVariantId: number,
    sellingPriceLevel: sellingPriceLevelBody,
    displayName: string,
    dealershipGroupId?: number,
    summaryBlockMeta?: {
      icon: string,
      text: {
        en: string,
        id: string
      }
    }[],
    configurations: {
      colourName: string,
      colourLogo: string
    }[],
    galleryImagesCount: number,
    isGallerySharedForAllConfigs: boolean,
    features: {
      title: string,
      description: string,
      disclaimer: string,
      category: string
    }[],
    brochuresCount: number
  }

  

// shapes the vehicle creation data into the required format in the vehiclePopulateDataBody interface
export const vehicleGenerationHelper = (vehicleBodyShape:any) => {
  
  console.log("vehicleBodyShape", vehicleBodyShape)
    let summaryBlockMeta =[]
    if(vehicleBodyShape.vehicleDetails?.summary_block_meta !== "[]") {
        summaryBlockMeta = vehicleBodyShape.vehicleDetails?.summary_block_meta
    }
    else{
      summaryBlockMeta = vehicleBodyShape.vehicle?.summaryBlockMeta?.map((item: any) => {
        return {
            "icon": item.icon,
            "text": {
                "en": item["text.en"],
                "id": item["text.id"]
            }
        }
    })
    }

    const configuration = vehicleBodyShape.vehicleDetails.configuration.map((item: any) => {
        return {
            "colourName": item.colour.colourName,
            "colourLogo": item.colour.colourLogo
        }
    })

    const features = vehicleBodyShape.features.map((item: any) => {
        return {
            "title": item.title,
            "description": item.description,
            "disclaimer": item.disclaimer,
            "category": item.category
        }
    })

    const newObject = {
        "vehicleVariantId": vehicleBodyShape?.vehicleDetails.id,
        "sellingPriceLevel": vehicleBodyShape?.sellingPriceLevel,
        "displayName": vehicleBodyShape?.vehicleDetails?.displayName,
        "summaryBlockMeta": summaryBlockMeta,
        "configurations": configuration,
        "galleryImagesCount": Number(vehicleBodyShape?.galleryImagesCount),
        "isGallerySharedForAllConfigs": vehicleBodyShape?.isGallerySharedForAllConfigs,
        "features": features,
        "brochuresCount": vehicleBodyShape?.brochure
    }
    return newObject
    
}
import { fetchIndividual } from "../api/manageAPI"
import { useFetchSingle } from "../hooks/crudHook"
import { isImageLink } from "../utils/miscUtils"
import { SpinnerLoader } from "./Loader"

interface ITableRelationalDisplay<T> {
    id: number,
    queryName: string,      //list for invalidateQuery
    pathName: string        //axios api url path for fetchIndividual
}

type TDisplayData = {
    data: object[]
}

const renderData = (valueData: any) => {

    switch (typeof (valueData)) {
        case "string":
            if (isImageLink(valueData)) {
                return <img src={valueData} />
            }
            return <p>{valueData}</p>
        case "number":
            return <p>{valueData}</p>
        case "boolean":
            return <p>{valueData ? "true" : "false"}</p>
        default:
            return <p>null</p>
    }
}

//Displaying sequential Data
export const DisplayData = ({ data }: TDisplayData) => {

    let displayData = data as Object as { [key: string]: any }
    let dataKeyArray = data?.map((item: any) => {
        if (item === null) { return [] }
        return Object.keys(item)
    }) || []

    return (
        <div className="whitespace-normal break-all px-3 text-left">
            {
                dataKeyArray.map((dataHeader, headerIdx) => {
                    return (
                        dataHeader.map((key, idx) => {
                            if (key === "password") return
                            return (
                                <div key={idx} className="pt-1">
                                    <p className="uppercase"><strong>{key}</strong></p>
                                    {
                                        renderData(displayData?.[headerIdx]?.[key])
                                    }
                                </div>
                            )
                        })
                    )
                })
            }


        </div>
    )
}


export const TableRelationalDisplayWithoutFetch = <T,>({ data }: { data: T }) => {

    let displayData = data ? data as Object as { [key: string]: any } : null
    let dataKeys = displayData ? Object.keys(displayData) : []

    return (
        <div className="whitespace-normal break-all px-3">
            {
                data &&
                dataKeys.map((key, idx) => {
                    const parts = key.split(".")
                    const keyValue = parts[parts.length - 1] === "id" ? key : parts[parts.length - 1]
                    return (
                        <div key={idx}>
                            <p className="uppercase"><strong>{keyValue}</strong></p>
                            {
                                typeof (displayData?.[key]) === "object" ?
                                    renderData(JSON.stringify(displayData?.[key])) :
                                    renderData(displayData?.[key])
                            }
                        </div>
                    )
                })
            }

        </div>
    )
}

const TableRelationalDisplay = <T,>({ id, queryName, pathName }: ITableRelationalDisplay<T>) => {

    const { isFetching, data, invalidateQuery } = useFetchSingle<T>(fetchIndividual, id, queryName, pathName)

    let displayData = data ? data as Object as { [key: string]: any } : null
    let dataKeys = displayData ? Object.keys(displayData) : []

    return (
        <div className="whitespace-normal break-all px-3">
            {
                !isFetching ?
                    data &&
                    dataKeys.map((key, idx) => {
                        return (
                            <div key={idx}>
                                <p className="uppercase"><strong>{key}</strong></p>
                                {
                                    typeof (displayData?.[key]) === "object" ?
                                        renderData(JSON.stringify(displayData?.[key])) :
                                        renderData(displayData?.[key])
                                }
                            </div>
                        )
                    })
                    :
                    <div className="h-screen">
                        <SpinnerLoader/>
                    </div>
            }

        </div>
    )
}

export default TableRelationalDisplay
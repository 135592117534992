import { useState, useRef, useMemo, useEffect } from "react";
import { BatchHeader } from "./BatchUploadFileUploader";
import * as ExcelJS from 'exceljs';
import { ProgressBar, SpinnerLoader } from "../../components/Loader";
import { TiTick } from "react-icons/ti";
import { v4 as uuidv4 } from 'uuid';
import { api_host } from "../../config/hostConfig";
import { useCreateSingle } from "../../hooks/crudHook";
import { createObj } from "../../api/manageAPI";
import { TDataInputs, TValidateDuplicates, validateDuplicates, validateSelfAndDatabase } from "../../api/validationAPI";

interface BatchUploadValidatorProps {
    data: Array<any>;
    setPage: Function;
    page: number;
    pageArray: Array<number>;
    headers: BatchHeader[];
    dataType: string;
    pathName: string;
    queryNameList: string;
    setBatchData: Function;
    config: any;
}

interface ErrorFields {
    field: string,
    row: number,
    error: string,
    description: string,
    cellRef: Array<string>
}

type recordList = {
    duplicatedRecords: Array<any>,
    uniqueRecords: Array<any>
}

// Page that performs validation of the data rows and identify error rows
const BatchUploadValidator = ({ data, setPage, page, pageArray, headers, dataType, pathName, queryNameList, setBatchData, config }: BatchUploadValidatorProps) => {

    const [excelData, setExcelData] = useState<{ [key: string]: any }>(data);
    const [errorRows, setErrorRows] = useState<Array<ErrorFields>>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [modifyError, setModifyError] = useState<boolean>(false);

    const fileHeader = headers.map((item) => { return item.label });
    const keyHeader = headers.map((item) => { return item.key });
    const tableRef = useRef<HTMLTableElement | null>(null);
    const createQuery = useCreateSingle(createObj, "validationList", "validate-duplicates");
    
    //construct table data
    const constructTable = (data: any, config: any) => {
        let tables = [];
        const tableColumns = keyHeader;
        const columnIndexes = tableColumns.map((column) => {
            const dataHeaderIndex = keyHeader.indexOf(column);
            return dataHeaderIndex;
        })

        //get the table data set the data as an array of {id: number , data: Array<any>}
        const tableData = data.map((record: any, idx: number) => {
            const tableRecord = columnIndexes.map((columnIndex) => {
                if (record[columnIndex] === undefined || record[columnIndex] === null) {
                    return null;
                }
                return record[columnIndex];
            })
            return {
                id: idx + 1,
                data: tableRecord
            }
        })

        tables = tableData
        return tables;
    }

    useEffect(() => {
        checkData()
        setLoading(false)
    }, []);

    const checkData = async () => {
        setLoading(true)
        setErrorRows([])
        const constructedTable = constructTable(data, config);
        const newObj: TValidateDuplicates = {
            data: constructedTable,
            keyHeader: keyHeader,
            fileHeader: fileHeader,
            config: {
                keys: config.compositeKeys
            }
        }

        const result = await validateDuplicates(newObj);
        if (!result) {
            console.log("error in generating duplicated results")
        }

        if (result.status !== 200) {
            console.log("result: " , result.data)
            setErrorRows(result.data)
        }

        // check in the database for the unique records
        const selfAndDatabaseValidation = await validateSelfAndDatabase(newObj , pathName);
        if (!selfAndDatabaseValidation) {
            setLoading(false)
            console.log("error in generating validation results")
            return
        }

        // if (selfAndDatabaseValidation?.error?.length > 0) {
        //     console.log("self and database validation error: \n", selfAndDatabaseValidation.error)
        //     selfAndDatabaseValidation?.error.map((item: any) => {
        //         const radio = {
        //             row: item?.data?.id+2,
        //             field: "Row" , 
        //             error: item?.error || "",
        //             cellRef : [],
        //             description: item?.description || ""
        //         }
        //         setErrorRows((prev) => [...prev, radio])
        //     })

            // perform setting the results data into the errorRows -> need to ensure that the unique records are also set for the display at the bottom
            // Case 1: Failed in the field error
            // Field , Cell Ref , Error -> type mismatch in submitted data , Description -> expected " " received  ""
            // add into error Rows
            // Case 2: Failed in the database duplication
            // Field (Composite-Keys) , Cell Ref -> unique ones , Error -> Item already exists in the database , Description -> Item already exists in the database

        // }
        // else {
        //     console.log("no errors here")
        // }

        setLoading(false)
    }



    //validate data in the table would have beem an already constructed table
    async function validateData(processedData: { [key: string]: any }) {
        setLoading(true)
        setErrorRows([]);
        //duplicateEntries(processedData);
        setLoading(false);
    }

    //convert table object into 
    const tableIntoObject = () => {
        const tableData = excelData;
        const rows = tableRef.current?.getElementsByTagName('tr') || [];
        console.log("rows: " , rows)
        //get the unique error rows
        let unique = errorRows.map((item) => { return (item.row - 2) }).filter((item, index, array) => array.indexOf(item) === index)
        console.log("unique: " , unique)

        // unique.map((item) => {
        //     console.log("item unique: " , item)
        //     const rowData: any[] = [];
        //     const cells = rows?.[item + 1].getElementsByTagName('td');
        //     for (let j = 0; j < cells.length - 1; j++) {
        //         let cellData = cells[j + 1].textContent === "" ? null : cells[j + 1].textContent;
        //         if (headers[j].type === "number")
        //             rowData[j] = Number(cellData)
        //         else if (headers[j].type === "boolean")
        //             rowData[j] = cellData === "true" || "TRUE" ? true : false
        //         else
        //             rowData[j] = cellData
        //     }
        //     tableData[item] = rowData
        //     console.log("rowData: " , rowData)
        // })


        unique.map((item , index)=>{
            console.log("item unique: " , item)
            
            const rowData: any[] = [];
            const cells = rows?.[index + 1]?.getElementsByTagName('td');
            console.log("cells: " , cells)
            for (let j = 0; j < cells.length - 1; j++) {
                let cellData = cells[j + 1].textContent === "" ? undefined : cells[j + 1].textContent;
                console.log("header type: " , headers[j].type , "\nheader name: " , headers[j])
                if (headers[j].type === "number" && cellData !== undefined)
                    rowData[j] = Number(cellData)
                else if (headers[j].type === "boolean")
                    rowData[j] = cellData === "true" || "TRUE" ? true : false
                else
                    rowData[j] = cellData
            }
            tableData[item] = rowData
            console.log("rowData: " , rowData)

        })

        // for (let dataItem in tableData) {
        //     console.log("dataItem: ", dataItem)
        //     let item = excelData[dataItem]
        //     console.log("item: ", item)

        
        //     if (unique.includes(item.id)) {
        //         console.log("in comparison")
        //         const index = unique.indexOf(item.id)
        //         const rowData: any[] = [];
        //         const cells = rows?.[index + 1].getElementsByTagName('td');
        //         for (let j = 0; j < cells.length - 1; j++) {
        //             let cellData = cells[j + 1].textContent === "" ? null : cells[j + 1].textContent;
        //             if (headers[j].type === "number")
        //                 rowData[j] = Number(cellData)
        //             else if (headers[j].type === "boolean")
        //                 rowData[j] = cellData === "true" || "TRUE" ? true : false
        //             else
        //                 rowData[j] = cellData;
        //         }
        //         item.data = rowData
        //         console.log("rowData: " , rowData)
        //     }
        // }
        setExcelData(tableData)
        checkData()
    }

    //generate error file
    function generateError() {
        let rows = errorRows.filter((item, index) => errorRows.indexOf(item) === index);
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.columns = fileHeader.map((item) => { return { header: item } });
        data.forEach((row) => {
            // get the rows of data-> if the cell is undefined then leave it blank
            const rowData = fileHeader.map((item, idx) => { return row[idx] || "" });
            worksheet.addRow(rowData);
        });
        rows.forEach((item) => {
            item.cellRef.forEach((cell) => {
                worksheet.getCell(cell).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFE53935' },
                    bgColor: { argb: 'FFE53935' }
                };
                worksheet.getCell(cell).border = {
                    top: { style: 'thin', color: { argb: 'D4D4D4D4' } },
                    left: { style: 'thin', color: { argb: 'D4D4D4D4' } },
                    bottom: { style: 'thin', color: { argb: 'D4D4D4D4' } },
                    right: { style: 'thin', color: { argb: 'D4D4D4D4' } }
                };
            })
        });
        workbook.xlsx.writeBuffer().then(buffer => {
            // create a Blob object from the buffer
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // create a link element to download the file
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "SSP_Template_Review_" + dataType + ".xlsx";

            // append the link to the document body and click it programmatically
            document.body.appendChild(link);
            link.click();
        });
    }

    //display of in app errors
    const generateErrorTables = () => {
        let unique = errorRows.map((item) => { return item.row }).filter((item, index, array) => array.indexOf(item) === index)

        console.log("error table:  " , unique)
        console.log("excel data: " , excelData )
        return (
            <div className="flex flex-col gap-1">
                <div className="flex flex-row justify-between">
                    <p className="text-[#3D426B] font-bold pt-2">Edit Error</p>
                    <button className="bg-blue-700 text-white hover:bg-blue-500 rounded-sm px-2 py-0.5 w-fit" onClick={() => { setModifyError(previous=>!previous); tableIntoObject() }}>Validate Data</button>
                </div>
                <div className="overflow-x-auto overflow-y-auto">
                    <table ref={tableRef}>
                        <thead>
                            <tr className="border border-black rounded-sm">
                                <th>Row</th>
                                {
                                    fileHeader.map((item, index) => {
                                        return (
                                            <th key={index.toString() + uuidv4()} className="border border-black rounded-sm">{item}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                unique.map((item, idx) => {
                                    console.log("unique item: " , item)
                                    return (
                                        <tr className="border border-red-500 rounded-sm bg-red-400" key={item + uuidv4()}>
                                            <td className="border border-black rounded-sm">{item}</td>
                                            {
                                                // keyHeader.map((header, headerIndex) => {
                                                //     return (
                                                //         <td className="border border-black rounded-sm" contentEditable suppressContentEditableWarning>{
                                                //             getTableData(excelData[Number(item) - 2]?.data?.[headerIndex] || "")}</td>
                                                //     )
                                                // })
                                                excelData[Number(item) - 2]?.map((data:any, dataIndex:number) => {
                                                    return (
                                                        <td className="border border-black rounded-sm" contentEditable suppressContentEditableWarning>{
                                                            getTableData(data)}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    //get table data for display
    const getTableData = (tableData: any = "") => {
        if (typeof tableData === "boolean") {
            return (tableData ? "true" : "false")
        }
        else if (typeof tableData === "number" && Number.isNaN(tableData)) {
            return (undefined)
        }
        return tableData
    }

    return (
        <div className=" bg-[#C8D9F0] rounded-md p-2 border border-[#A7C7E7] h-screen">
            <div className="flex flex-col gap-2 pl-2">
                <p className="text-[#3D426B] font-bold pt-2">#3 Processing Data Types</p>
                <p>Data validation is conducted to ensure that all uploaded data is of the correct type.</p>
                <p>Any identified errors are declared in the table below.</p>
                <p className="text-[#3D426B] font-bold pb-0.5">Validation progress:</p>
                {/*<ProgressBar current={currentProccessed} total={excelData.length} />*/}
                {
                    loading ?
                        <div className="flex flex-row gap-2">
                            {/* <SpinnerLoader/> */}
                            <p className="text-[#3D426B] font-bold">Processing...</p>
                            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
                        </div>
                        :
                        errorRows.length > 0 &&
                        <div className="flex flex-col gap-1">
                            <h1 className="text-[#3D426B] font-bold ">Error Rows</h1>
                            <p>The errors have been identified in the following rows.</p>
                            <p>You can choose to: </p>
                            <div className="flex flex-row justify-between pb-1">
                                <p>1. Modify the errors within the given page and continue with the upload journey.</p>
                                <button className="bg-blue-700 text-white hover:bg-blue-500 rounded-sm px-2 py-0.5 disabled:bg-gray-300 disabled:cursor-not-allowed" disabled={false} onClick={() => { setModifyError(previous => !previous) }}>Edit Error</button>
                            </div>
                            <div className="flex flex-row justify-between">
                                <p>2. Download the error file and reupload in the batch upload page again.</p>
                                <button className="bg-blue-700 text-white hover:bg-blue-500 rounded-sm px-2 py-0.5" onClick={generateError}>Download Error File</button>
                            </div>
                            <div className="flex flex-row justify-between pb-1">
                                <p>3. Continue uploading rows without error.</p>
                                <button className="bg-blue-700 text-white hover:bg-blue-500 rounded-sm px-2 py-0.5" onClick={()=>{setPage(pageArray[pageArray.indexOf(page)+1]);}}>Proceed to upload data</button>
                            </div>
                            <div className="bg-white w-full rounded-sm">
                                <table className="w-full pr-2 pt-2 shadow-md sm:rounded-md">
                                    <thead className="bg-gray-100 text-gray-600 border uppercase font-bold">
                                        <tr>
                                            <th key={"Error Row" + uuidv4()}>Row</th>
                                            <th key={"Error Field" + uuidv4()}>Field</th>
                                            <th key={"Error Cell" + uuidv4()}>Cell Reference</th>
                                            <th key={"Error Type" + uuidv4()}>Error Type</th>
                                            <th key={"Error Desc" + uuidv4()}>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            errorRows.map((item) => {
                                                return (
                                                    <tr className="border border-red-500 rounded-sm" key={item.cellRef + uuidv4()}>
                                                        {
                                                        }
                                                        <td key={item.row + uuidv4()}>{item.row}</td>
                                                        <td key={item.field + uuidv4()}>{item.field}</td>
                                                        <td key={item.cellRef + uuidv4()}>{item.cellRef.join("-")}</td>
                                                        <td key={item.error + uuidv4()}>{item.error}</td>
                                                        <td key={item.description + uuidv4()}>{item.description}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <p>
                                    {errorRows.map((item) => { return item.row }).filter((item, index, array) => array.indexOf(item) === index).length} out of {data.length} rows have errors.
                                </p>
                            </div>
                        </div>
                }
                {
                    !loading && errorRows.length <= 0 &&
                    <>

                        <div className="bg-green-200 border border-green-700 p-2 flex flex-row gap-1">
                            <div className="pt-0.5">
                                <TiTick color="green" size={"16px"} />
                            </div>
                            <p className="text-green-800">
                                All datarows have been validated. You may proceed to the next page to confirm before uploading.
                            </p>
                        </div>
                        {/** can have a setting that enables them to upload in the same page */}
                        {/**Error here!! If proceed is selected in the first choice, no data is displayed */}
                        <button className="bg-blue-700 text-white hover:bg-blue-500 rounded-sm px-2 py-0.5 w-fit flex justify-start"
                            onClick={() => {
                                setPage(pageArray[pageArray.indexOf(page) + 1]);
                                // let val = excelData.map((item: any) => { return item.data });
                                setBatchData(excelData)
                            }}>
                            Proceed
                        </button>
                    </>
                }
                {
                    modifyError && errorRows.length > 0 && generateErrorTables()
                }
                {
                    !modifyError && errorRows.length > 0 &&
                    <button className="bg-blue-700 text-white hover:bg-blue-500 rounded-sm px-2 py-0.5 w-fit flex justify-start" onClick={() => { setPage(pageArray[pageArray.indexOf(page)-1]);}}>
                        Upload New CSV
                    </button>
                }
            </div>
        </div>
    )
}
export default BatchUploadValidator;

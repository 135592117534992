import axios from "axios"
import { api_host } from "../config/hostConfig"
import { getAuthenticationToken } from "./apiAuthentication"
import { useQuery, useQueryClient, useMutation } from "react-query";

export const fetchDependablePath = async <T,>( path: string) => {
    const api_url = `${api_host}/api/${path}`
    try{
        const response = await axios.get(api_url, getAuthenticationToken())
        if(response.status !== 200) return null
        return response?.data.message || null
    }
    catch (err: any){
        console.log(err)
        return null
    }
}

export const useFetchDependablePath =  <T,>(queryName: string, path:string) => {

    const getAPI = async () => {
        return await fetchDependablePath(path)
    }

    const { status, data, error, isFetching } = useQuery<Array<T>, Error>(queryName, getAPI)

    const queryClient = useQueryClient()

    const invalidateQuery = () => {
        queryClient.invalidateQueries(queryName)
    }

    return { isFetching, data, invalidateQuery }
}
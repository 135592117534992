import { RxCross2 } from 'react-icons/rx';
import { useEffect, useRef } from 'react'
import { useOnClickOutside } from '../../hooks/utilHooks/clickOutsideHook';
import { useScrollBodyLock } from '../../hooks/utilHooks/scrollLockHook';

type PopUpProps = {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element),
    closeMenu: () => void,
    parentTag: string
}

const PopUpWrapper = ({ children, closeMenu, parentTag }: PopUpProps) => {

    const { addScrollLock, removeScrollLock, getScrollLockClassName } = useScrollBodyLock()

    const menuRef = useRef(null)

    const onClosePopUp = () => {
        removeScrollLock(parentTag)
        closeMenu()
    }

    useOnClickOutside(menuRef, onClosePopUp)

    useEffect(() => {
        return (() => removeScrollLock(parentTag))
    }, [])

    return (
        <>
            <div className="z-10 fixed bg-gray-500 opacity-50 h-[100vh] w-[100vw] top-0 left-0" />
            <div ref={menuRef} className={`${getScrollLockClassName(parentTag)} pl-2 shadow-sm fixed  ${window.innerWidth < 600? 'right-0 left-0 top-0 bottom-0' : window.innerWidth < 758 ? 'left-[20px] right-[20px] top-[20px] bottom-[20px]' : 'left-[100px] right-[100px] top-[40px] bottom-[40px]'} bg-gray-100 z-10 flex flex-col pb-5 rounded-md`}>
                <button className="bg-red-600 rounded-lg mt-3 w-fit ml-auto mr-2" onClick={onClosePopUp}><RxCross2 size="35px" /></button>
                <div className='w-full'>
                    <>
                        {children}
                    </>
                </div>
            </div>
        </>
    )
}

export default PopUpWrapper
import { RxCross2 } from 'react-icons/rx';
import {useEffect, useRef} from 'react'
import { useOnClickOutside } from '../../hooks/utilHooks/clickOutsideHook';
import { useScrollBodyLock } from '../../hooks/utilHooks/scrollLockHook';

type SideBarProp = {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element),
    closeMenu: () => void,
    sideBarTag: string
}

const SideBarWrapper = ({ children, closeMenu, sideBarTag }: SideBarProp) => {

    const {addScrollLock, removeScrollLock, getScrollLockClassName} = useScrollBodyLock()

    const menuRef = useRef(null)

    const onCloseSideBar = ()=> {
        removeScrollLock(sideBarTag)
        closeMenu()
    }

    useOnClickOutside(menuRef, onCloseSideBar)

    useEffect(()=> {

        return(()=> removeScrollLock(sideBarTag))
    }, [])

    return (
        <>
            <div className="z-10 fixed bg-gray-500 opacity-50 h-[100vh] w-[100vw] top-0 left-0" />
            <div  ref={menuRef} className={`${getScrollLockClassName(sideBarTag)} pl-2 shadow-sm fixed top-0 right-0 bg-gray-100 h-full translate-x-0 ease-in-out duration-500 z-20 flex flex-col pb-5`}>
                <button className="bg-red-600 rounded-lg mt-3 w-fit ml-auto mr-2" onClick={onCloseSideBar}><RxCross2 size="35px" /></button>
                <>
                    {children}
                </>
            </div>
        </>
    )
}

export default SideBarWrapper
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { fetchAll } from "../../api/manageAPI";
import { useFetchList } from "../../hooks/crudHook";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchList from "../../components/filterDataComponents/SearchList";
import { useSelectedRecordHook } from "../../hooks/selectedRecordHook";
import { useSequentialHook } from "../../hooks/sequentialFileHook";
import { SpinnerLoader } from "../../components/Loader";

const Vehicle = <T extends { [key: string]: any }>() => {
    const [active, setActive] = useState<boolean[]>([]);
    const [result, setResult] = useState<T[]>([]);
    const [dealershipList, setDealershipList] = useState<any[]>([])
    const navigate = useNavigate();

    const { isFetching, data, invalidateQuery } = useFetchList<T>(fetchAll, "dealershipGroupList", "dealership-group");
    const { isFetching: isFetching2, data: data2, invalidateQuery: invalidateQuery2 } = useFetchList<T>(fetchAll, "dealershipList", "dealership");
    const { setDealershipGroupId, setDealershipId, setStoredField} = useSelectedRecordHook();
    const { addPage } = useSequentialHook();

    useEffect(() => {
        if (!data || !data2) return;
        let temp: any[] = [];
        let dealerships: any[] = [];

        //obtain the dealerships that are found in dealership groups
        data?.map((dealershipGroup: any) => {
            let item = {}
            let dealershipList: any[] = [];
            data2?.map((dealership: any) => {
                if (dealership.dealership_group_id === dealershipGroup.id) {
                    dealershipList.push(dealership);
                }
            })
            item = { dealershipGroup: dealershipGroup, dealership: dealershipList }
            dealerships.push(dealershipList);
            temp.push(item);
        })

        let dealershipList: any[] = [];

        // obtain the dealerships that are not found in dealership groups
        data2?.map((dealership: any) => {
            if (dealership.dealership_group_id === null) {
                dealershipList.push(dealership);
            }
        })
        temp.push({ dealershipGroup: { name: "Others" }, dealership: dealershipList })
        dealerships.push(dealershipList);
        setResult(dealerships)
        setDealershipList(temp);
    }, [data, data2])

    useEffect(() => {
        if (data && data.length > 0) {
            setActive(new Array(data.length + 1).fill(false));
            setResult(new Array(data.length + 1).fill([]));
        }
    }, [])


    return (
        <div className="pt-6 px-2 text-left h-screen flex flex-col">
            <div className="flex flex-row justify-between pb-2">
                <h1 className="text-lg font-bold p-2">Vehicle</h1>
                <button className="bg-blue-800 text-white p-2 rounded-md hover:bg-blue-300"
                onClick={()=>{
                    setDealershipId(-1);
                    setDealershipGroupId(-1);
                    setStoredField("" , "")
                    addPage({ pageName: "Dealership Vehicles" })
                    navigate("/management/vehicle")
                }}
                >Show all vehicles</button>
            </div>
            {
                dealershipList.length > 0 ?
                    <div className="p-2 rounded-md bg-white boarder-gray-500">
                        {
                            dealershipList.map((item: any, index: number) => {
                                return (
                                    <div className="flex flex-col" key={index}>
                                        <div className="flex flex-row justify-between h-[50px] items-center">
                                            <div className="flex flex-row gap-2">
                                                <p className="text-xl">Dealership Group: {item.dealershipGroup.id}: </p>
                                                <p className="text-lg text-blue-800">{item.dealershipGroup.name}</p>
                                            </div>
                                            <div>
                                                {
                                                    active[index] ?
                                                        // drop down has been clicked
                                                        <button className="flex flex-row gap-2 items-center"
                                                            onClick={() => {
                                                                const newActive = [...active];
                                                                newActive[index] = !newActive[index];
                                                                setActive(newActive);
                                                            }}>
                                                            <p>Hide all Dealerships</p>
                                                            <MdExpandLess />
                                                        </button>
                                                        :
                                                        // drop down has not been clicked
                                                        <div className="flex flex-row gap-4 items-center">
                                                            <button className="bg-blue-800 text-white p-2 rounded-md hover:bg-blue-300"
                                                                onClick={() => {
                                                                    setDealershipGroupId(item.dealershipGroup.id);
                                                                    setDealershipId(0);
                                                                    addPage({ pageName: "Dealership Vehicles" })
                                                                    setStoredField("title", "Dealership Group " + item.dealershipGroup.name);
                                                                    navigate("/management/vehicle", { state: { from: "vehicle" } })
                                                                    // navigate("/management/vehicle", { state: { query: "dealershipGroupId=" + item.dealershipGroup.id } })
                                                                }}
                                                            >
                                                                Show vehicles in dealership group
                                                            </button>
                                                            <button className="flex flex-row gap-2 items-center"
                                                                onClick={() => {
                                                                    const newActive = [...active];
                                                                    newActive[index] = !newActive[index];
                                                                    setActive(newActive);
                                                                }}>
                                                                <p>Display Dealerships</p>
                                                                <MdExpandMore />
                                                            </button>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            active[index] &&
                                            <>
                                                <div className=" bg-gray-200 rounded-sm p-2" >
                                                    {
                                                        item.dealership.length > 0 ?
                                                            <>
                                                                <div className="p-2 flex flex-row justify-between">
                                                                    {/**set the search list to have a list of dealerships */}
                                                                    <SearchList data={item.dealership || []} columnNames={["id", "name"]} setSearchResult={setResult} index={index} resultList={result} />
                                                                    <p>Dealership Count: {result[index]?.length || 0}</p>
                                                                </div>
                                                                <div className=" max-h-[300px] overflow-y-auto snap-y scroll-smooth pt-2 px-2">
                                                                    {
                                                                        result[index].length === 0 ?
                                                                            <p className="h-[50px]">No dealerships found</p>
                                                                            :
                                                                            result[index]?.map((dealership: any, idx: number) => {
                                                                                return (
                                                                                    <div className="text-md snap-start" key={idx}>
                                                                                        <button className="hover:bg-blue-200 w-full text-left border-t border-b border-gray-400 h-[50px]" onClick={() => {
                                                                                            setDealershipGroupId(item.dealershipGroup.id);
                                                                                            setDealershipId(dealership.id);
                                                                                            addPage({ pageName: "Dealership Vehicles" })
                                                                                            setStoredField("title", "Dealership " + dealership.name)
                                                                                            navigate("/management/vehicle")
                                                                                            // navigate("/management/vehicle", { state: { query: "dealershipId=" + dealership.id, name: dealership.name } })
                                                                                        }
                                                                                        }>
                                                                                            <p className="pl-2">{dealership.name}</p>
                                                                                        </button>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                    }
                                                                </div>
                                                            </>
                                                            :
                                                            <div className="text-md snap-start">
                                                                <div className="text-end pr-2">
                                                                    <p>Dealership Count: {item.dealership.length}</p>
                                                                </div>
                                                                <p className="h-[50px]">No dealerships found</p>
                                                            </div>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className="flex flex-grow justify-center items-center">
                        <SpinnerLoader />
                    </div>
            }
        </div>
    )
}

export default Vehicle;
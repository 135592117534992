import { BiEdit } from "react-icons/bi";
import { cellFunction } from "../utils/miscUtils";
import { nestedProps } from "./CRUD";
import { TResetDelete, TableProps } from "./CRUD";
import { BsArrowRightCircleFill, BsTrashFill } from "react-icons/bs";
import { featureSwitches } from "../config/featureSwitch";

// Determine the Return Component for either Delete or Password Reset
export const ResetDelete = <T extends { [key: string]: any }>({ ...field }: TResetDelete<T> & { onClose: () => void }) => {
    return (
        <>
            <div className='flex justify-between pt-4 '>
                <h2 className="text-lg font-bold pl-2" >{field.header}</h2>
            </div>
            <field.component id={field.id} item={field.item} query={field.query} onClose={field.onClose} />
        </>
    )
}


// tableCell
export function TableCell<T extends { [key: string]: any }>(item: T, index: number, field: TableProps, openFunction: Function) {
    let Cell: Function = cellFunction(field.dataType)
    if (field?.relationalTable) {
        return {
            data: field.dataFieldName && item[field.dataFieldName] ?
                <button className="bg-blue-300 rounded-md p-1 shadow-md w-full"
                    onClick={() => {
                        openFunction(item[field.relationalTable?.idFieldName ||field.dataFieldName], field.relationalTable?.queryName, field.relationalTable?.pathName)
                        //openRelationalTable(item[field.dataFieldName] ,  field.relationalTable?.queryName || "", field.relationalTable?.pathName || "" , setFunction)
                    }}><Cell data={
                        item[field.dataFieldName]
                    } key={index} /></button>
                : <p className="text-center"></p>
        }
    }
    else {
        return {
            data: <Cell data={item[field.dataFieldName]} key={index} />,
            clickableCallback: () => { }
        }
    }
}

//Action cell with Update, Delete, and Reset Password buttons
export const ActionCell = <T extends { [key: string]: any }>({ index, item, title, reset = false, setStore, crudRecord, nestedInputs }: { index: number, item: T, title: string, reset?: boolean, setStore: Function, crudRecord: Function, nestedInputs?: nestedProps }) => {
    return (
        <div className="flex flex-row gap-3 items-center" key={index}>
            {
                featureSwitches.edit &&
                <button className="flex mx-auto"
                onClick={() => { crudRecord(item.id, index, "update", title) }}><BiEdit color="green"
                title="Update record"
                />
                </button>
            }
            {
                featureSwitches.delete &&
                <button className="flex mx-auto"
                    onClick={() => { crudRecord(item.id, index, "delete", title) }}><BsTrashFill color="red"
                        title="Delete record"
                    />
                </button>
            }
            {
                featureSwitches.resetPassword && reset &&
                <button className="flex mx-auto text-xs rounded-md p-3 bg-blue-100 border-blue-800 hover:bg-blue-400"
                    onClick={() => { crudRecord(item.id, index, "resetPassword", title) }}>Reset Password
                </button>
            }
            {
                //added here
                nestedInputs &&
                <button className="flex mx-auto"
                    onClick={() => { setStore(nestedInputs?.queryParameter || "", item.id); /*navigate("/management/" + nestedInputs?.pathName + queryParam + item.id, { state: location.pathname })*/ }}
                    title={`View related ${nestedInputs?.pathName}`}
                >
                    <BsArrowRightCircleFill />
                </button>
            }
        </div>
    )
}
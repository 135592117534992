import { RxCross2 } from 'react-icons/rx';
import { useEffect, useRef } from 'react'
import { useOnClickOutside } from '../../hooks/utilHooks/clickOutsideHook';
import { useScrollBodyLock } from '../../hooks/utilHooks/scrollLockHook';

type SideBarProp = {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element),
    closeMenu: () => void,
    cancelMenu: () => void,
    sideBarTag: string,
    baseSidebarTag: string
}

const ConfirmationWrapper = ({ children, closeMenu, sideBarTag, cancelMenu, baseSidebarTag }: SideBarProp) => {

    const { addScrollLock, removeScrollLock, getScrollLockClassName } = useScrollBodyLock()

    const menuRef = useRef(null)

    const onCloseSideBar = () => {
        removeScrollLock(sideBarTag)
        closeMenu()
    }

    const onCancelSelection = () => {
        cancelMenu()
        removeScrollLock(sideBarTag)
        closeMenu()
    }

    useOnClickOutside(menuRef, onCloseSideBar)

    useEffect(() => {
        addScrollLock(baseSidebarTag) // lock the base sidebar -- parent of the sidebarTag 
        return (() => removeScrollLock(sideBarTag))
    }, [])


    return (
        <>
            <div className="z-10 fixed bg-gray-500 opacity-50 top-0 left-0" />
            <div ref={menuRef} className={`${getScrollLockClassName(sideBarTag)} pl-2 shadow-sm fixed top-0 right-0 bg-gray-100 h-screen translate-x-0 ease-in-out duration-500 z-20 flex flex-col pb-5`}>
                <div className='mt-3 mr-2 flex flex-row gap-2 justify-end'>
                    <button className="bg-red-500 rounded-lg w-fit text-xl p-2 " onClick={onCancelSelection}><RxCross2 /></button>
                    <button className="bg-blue-500 rounded-lg " onClick={onCloseSideBar}>Confirm</button>
                </div>
                <>
                    {children}
                </>
            </div>
        </>
    )
}

export default ConfirmationWrapper
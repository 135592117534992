import { create } from 'zustand'

interface ISequentialFileStore {
    pageList: Array<{[key:string]:any}>,
    addPage: (pageFields: object) => void,
    removePage: (id:number)=>void,
    removeAllPages: () => void,
}



const useSequentialStore = create<ISequentialFileStore>((set , get) => ({
    pageList: [],

    addPage: (pageFields: {[key:string]:any}) => set((state) => {
        return { pageList: [...state.pageList, pageFields]}
    }),

    removePage: (id: number) => set((state) => {
        const tempList = [...state.pageList]
        tempList.splice(id, 1)
        return { pageList: tempList}
    }),

    removeAllPages: () => set(() => ({ pageList: [] }))
}))

// An intermediate hook is required before access the store for future possible hook implementations
export const useSequentialHook = () => {
    return(useSequentialStore())
}
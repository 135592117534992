import {BsFillQuestionCircleFill} from 'react-icons/bs';

interface ITooltip{
    tooltipInfo : Array<any> | string,
    header: string
}

const Tooltip = ({tooltipInfo , header} : ITooltip) =>{

    const handleTooltipShow = () => {
        const tooltip = document.getElementById("tooltip-default");
        tooltip?.classList.remove("invisible");
        tooltip?.classList.add("visible");
        tooltip?.classList.remove("opacity-0");
        tooltip?.classList.add("opacity-100");
    }
    
    const handleTooltipHide = () =>{
        const tooltip = document.getElementById("tooltip-default");
        tooltip?.classList.remove("visible");
        tooltip?.classList.add("invisible");
        tooltip?.classList.remove("opacity-100");
        tooltip?.classList.add("opacity-0");
    }

    return(
        <>
        <div className="">
        {/*Tooltip Icon*/}
        <button data-tooltip-target="tooltip-default"
            onMouseOver={handleTooltipShow}
            onMouseOut={handleTooltipHide}
            className="text-white font-medium rounded-lg text-sm text-center w-5 h-5 pr-2">
                <BsFillQuestionCircleFill size="16px" color="gray"/>
        </button>

        {/* Tooltip Description*/}
        <div>
        <div id="tooltip-default"
            className="absolute z-20 invisible inline-block px-2 py-2 text-xs font-medium text-white bg-gray-400 rounded-md shadow-sm opacity-0 transition-opacity duration-300">
            <p className="uppercase underline pb-2">{header}</p>
            {
                typeof tooltipInfo === "string" ?
                    <p>{tooltipInfo}</p> 
                :
                tooltipInfo.map((info, index) => {
                    return(
                        <p key={index}>
                            {info}
                        </p>
                    )
                })
            }
            </div>
        </div>
        </div>
        </>
    )
}

export default Tooltip;
const local_test_env = "local"

const allEndpoints = {
    "local": "http://localhost:7071",
    "intg-arista": "https://api.arista.ssp.dev.gotvsd.com",
    "intg-mazda": "https://api.mazda.ssp.dev.gotvsd.com",
    "intg-wheeltek": "https://api.wheeltek.ssp.dev.gotvsd.com",
    "prod-arista": "https://api.arista.ssp.gotvsd.com",
    "prod-mazda": "https://api.mazda.ssp.gotvsd.com",
    "prod-wheeltek": "https://api.wheeltek.ssp.gotvsd.com"
}

export const api_host = process.env.REACT_APP_API_HOST || allEndpoints[local_test_env]
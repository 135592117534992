import { useState } from "react"
import { useAlertToastHook } from "../hooks/alertToastHook"
import { SpinnerLoader } from "./Loader"
import TableRelationalDisplay, { TableRelationalDisplayWithoutFetch } from "./TableRelationalDisplay"

interface IDelete<T> {
    query: Function ,
    onClose: () => void,
    id: number ,
    item: T
}


const Delete = <T extends { [key: string]: any }>({item , query , onClose , id} : IDelete<T>) => {

    const [isFetching, setIsFetching] = useState<boolean>(false)
    const { addMessage } = useAlertToastHook()

    const handleDelete = async (value: number) => {
        setIsFetching(previous => !previous)

        const results = await query(value)

    
        if (!results) return;

        if(results){
            setIsFetching(previous => !previous)
            onClose();
            addMessage("Id " + results.id + " has been deleted" , true)
        }
        else{
            setIsFetching(previous => !previous)
            onClose();
            addMessage("Error" , false)
        }
      }

    return(
        <>
            {
                isFetching?
                <SpinnerLoader/>
                :
                <div className="pt-3">
                    <TableRelationalDisplayWithoutFetch data={item}/>
                    <div className="border-t border-gray-500 w-full py-2" />
                    <div className=" flex justify-center">
                        <button className="bg-white rounded-md text-sm hover:bg-slate-300 border border-gray-500 px-2 py-1 " onClick={() => handleDelete(id)}>Delete</button>
                    </div>
                </div>
            }
        </>
    )
}

export default Delete;


import { useState, useRef } from "react";
import { BsImageFill } from 'react-icons/bs';
import { FileUploadPostFields, uploadFile } from "../../api/uploadFileAPI";
import { useSelectedRecordHook } from "../../hooks/selectedRecordHook";
import { FormInputChange } from "../Form";
import { BiErrorCircle } from "react-icons/bi";

export interface IFileUploadField {
    keyName: string,
    primitiveDataType: string,
    formComponent: {
        type: "file",
        label: string,
        placeholder?: string,
        required?: boolean
    }
    defaultValue?: string,
    loading?: boolean,
    error?: any
}

async function handleUpload(file: File | undefined | null, fileUploadData: FileUploadPostFields) {
    if (!file) return "";


    const response = await uploadFile(file, fileUploadData);
    if (!response) return

    //perform upload here to get url
    return response.url
}

const FileUploaderField = ({ keyName, formComponent, defaultValue, loading, handleInputChange, error }: IFileUploadField & FormInputChange) => {
    const [file, setFile] = useState<File | undefined | null>();
    const [fileUrl, setFileUrl] = useState<string | any>(defaultValue || "");
    const [updateFileUrl, setUpdateFileUrl] = useState<string | any>(defaultValue || "");
    const [dragActive, setDragActive] = useState<boolean>(false);

    const previousFileUrl = useRef(defaultValue)

    const { tableName, recordId } = useSelectedRecordHook()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files?.[0]) return;
        setFile(event.target.files?.[0]);
    }

    const onUploadPress = async () => {

        const postData = {
            previous_file_name: previousFileUrl.current || "",
            table_name: tableName,
            field_name: keyName,
            record_id: recordId,
            tag: ""
        }

        const url = await handleUpload(file, postData);
        setFileUrl(url)
        previousFileUrl.current = url
        handleInputChange?.(keyName, url)
    }

    const onCancelPress = async () => {
        setFile(null)
        setFileUrl(defaultValue || "")
        handleInputChange?.(keyName, defaultValue || "")
    }


    const onClickOrCancelPress = () => {
        setFile(null)
        setFileUrl("")
        handleInputChange?.(keyName, "")
    }

    //handle dragging event
    const handleDrag = function (event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    }


    //handle dropping event
    const handleDrop = function (event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        setFile(event.dataTransfer.files[0]);
    };

    return (
        <div>
            <div key={keyName}>
                <label htmlFor={keyName} className="block mb-2 text-sm font-medium text-gray-900 pt-5" >{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
                {
                    !loading ?
                        <div className="flex items-center justify-center w-full">
                            <label className={!file ? `flex flex-col w-full h-full border-2 ${error ? `border-red-500` : `border-gray-500`} hover:bg-gray-100 hover:border-blue-500` : `flex flex-col w-full border-2 ${error ? `border-red-500` : `border-gray-500`}`}>
                                {
                                    !file ?
                                        <>
                                            {
                                                updateFileUrl && fileUrl ?
                                                    <div className=""
                                                        onDragEnter={handleDrag}
                                                        onDragOver={handleDrag}
                                                        onDragLeave={handleDrag}
                                                        onDrop={handleDrop}>
                                                        <input
                                                            type="file" className="opacity-0"
                                                            placeholder={formComponent.placeholder}
                                                            accept="image/*"
                                                            onClick={onClickOrCancelPress}
                                                            onChange={handleChange} />
                                                        <div className='p-3 flex-center'>
                                                            <p className="text-sm text-gray-900 bg-inherit text-bold underline">Uploaded File:</p>
                                                            <p className="break-all">{updateFileUrl}</p>
                                                            <img src={updateFileUrl} alt="Unable to load image" />
                                                        </div>
                                                    </div>

                                                    :
                                                    <>
                                                        <div className=""
                                                            onDragEnter={handleDrag}
                                                            onDragOver={handleDrag}
                                                            onDragLeave={handleDrag}
                                                            onDrop={handleDrop}>
                                                            <input
                                                                type="file" className="opacity-0"
                                                                placeholder={formComponent.placeholder}
                                                                accept="image/*"
                                                                onChange={handleChange} />
                                                            <div className="flex flex-col items-center justify-center p-7">
                                                                <BsImageFill size={"16px"} color="gray" />
                                                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                                                    Upload an Image
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <input
                                                            type="file" className="opacity-0"
                                                            placeholder={formComponent.placeholder}
                                                            accept="image/*"
                                                            onChange={handleChange} />
                                                    </>

                                            }
                                        </>
                                        :
                                        // After uploading the file
                                        <div className='p-3 flex-center'>
                                            <p className="text-sm text-gray-900 bg-inherit text-bold underline">Attached Document:</p>
                                            <p className="text-sm text-gray-900 bg-inherit w-full">{file?.name || "Empty"}</p>
                                            {
                                                fileUrl !== "" ?
                                                    <>
                                                        <p className="text-sm  text-gray-900 bg-inherit text-bold underline">Uploaded File:</p>
                                                        <p className="break-all">{fileUrl}</p>
                                                        <img src={fileUrl} alt="No image" />
                                                    </>
                                                    :
                                                    <p>File not uploaded yet</p>
                                            }

                                        </div>
                                }
                            </label>
                        </div>
                        :
                        <div className="w-full animate-pulse bg-gray-300 h-32"><p> </p></div>
                }

            </div>

            {/* Upload and cancel button */}
            {
                !loading && (file || updateFileUrl) ?
                    // Display Buttons only when files are attached
                    <div className="flex py-2 space-x-1">
                        <button type='button' className={
                            !fileUrl ? //Disable Update Button when Images are uploaded
                                `text-sm px-1 text-gray-50 bg-green-900 rounded shadow-xl`
                                : `text-sm px-1 text-gray-50 bg-gray-600 rounded shadow-xl cursor-default`}
                            disabled={fileUrl}
                            onClick={async (e) => {
                                e.preventDefault();
                                onUploadPress();
                            }}>
                            Upload
                        </button>
                        <button type='button' className="text-sm px-1 text-gray-50 bg-red-900 rounded shadow-xl"
                            onClick={(e) => {
                                e.preventDefault();
                                onClickOrCancelPress();
                            }}>
                            Cancel
                        </button>
                        <p className="text-gray-500 text-xs"><span className='text-red-500 text-xs'>* </span> Please upload image before creating the Object</p>
                    </div>


                    : null

            }
            {
                error &&
                <div className="flex flex-row gap-x-1 items-center">
                    <div className="pt-1">
                        <BiErrorCircle color="red" />
                    </div>
                    <p className="text-red-600 text-sm pt-1">{error}</p>
                </div>
            }
        </div>
    )
}

export default FileUploaderField
import { useMemo, useState, useEffect } from "react"
import { uploadFile } from "../../api/uploadFileAPI"
import { useAlertToastHook } from "../../hooks/alertToastHook"

type FileDataType = {
  name: string,
  path: string,
  blobData: File
}

const FileDropZone = ({ fileText, setFileData }: { fileText: string, setFileData: Function }) => {

  const handleDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files) {
      const fileData: FileDataType[] = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileDataObj: FileDataType = {
          name: file.name,
          path: file.webkitRelativePath,
          blobData: file
        }
        fileData.push(fileDataObj)
      }
      setFileData(fileData)
      // @ts-ignore
      e.target.value = null
    }
  }

  return (
    <div className="relative w-[80%] h-28 shadow rounded mx-auto my-5 overflow-hidden">
      <input
        className="absolute border shadow p-3 rounded-md w-full h-full left-0 top-0 opacity-0"
        type="file"
        /* @ts-expect-error */
        webkitdirectory="" mozdirectory="" directory=""
        // placeholder={formComponent.placeholder}
        onChange={handleDrop}
      />
      <div className="flex flex-col gap-3 justify-center p-7 w-full h-full pointer-events-none">
        {/* <BsImageFill size={"16px"} color="gray" /> */}
        {
          fileText !== "" &&
          <p className="text-xs whitespace-nowrap overflow-hidden text-ellipsis w-full">{fileText}</p>
        }
        <p className="pt-1 text-xs tracking-wider text-gray-400 group-hover:text-gray-600">
          Drag and drop files here
        </p>
      </div>
    </div>
  )
}

export const AssetUploader = ({ currentDirectory, refreshDirectory }: { currentDirectory: string, refreshDirectory: Function }) => {

  const [open, setOpen] = useState<boolean>(false)

  const [fileData, setFileData] = useState<FileDataType[]>([])

  const [isUploading, setIsUploading] = useState(false)

  const { addMessage } = useAlertToastHook()

  const handleUploadFiles = async () => {
    let count = 0
    setIsUploading(true)
    for (const file of fileData) {

      const prefixPath = currentDirectory.startsWith("/") ? currentDirectory.slice(1, -1) : currentDirectory

      const uploadFieldData = {
        previous_file_name: "",
        table_name: "",
        field_name: "",
        record_id: "",
        tag: "",
        fileNamePath: `${prefixPath}${file.path}`
      }

      console.log("upload File: " , uploadFieldData)

      const fileUploadResponse = await uploadFile(file.blobData, uploadFieldData, "http://localhost:3002")

      if (fileUploadResponse.data) count++
    }
    refreshDirectory()

    setIsUploading(false)

    if (count === fileData.length) {
      addMessage("Successfully uploaded all files", true)
    } else {
      addMessage(`${count}/${fileData.length} has been uploaded successfully`, true)
    }
    setOpen(false)
  }

  const handleUploadInputOpen = () => {
    setOpen(prev => !prev);
  }

  const handleClearFiles = () => {
    setFileData([])
  }

  const listedFiles = useMemo(() => {
    let listedFileText = ""

    if (fileData.length === 0) return ""

    for (let i = 0; i < fileData.length; i++) {
      const file = fileData[i]
      listedFileText += file.name + (i !== fileData.length - 1 ? ", " : "")
    }
    return `${fileData.length} files(s) selected: ${listedFileText}`
  }, [fileData])

  useEffect(() => {
    if (!open) setFileData([])
  }, [open])

  const UploaderScreen = () => {
    return (
      <>
        <button className="rounded p-2 self-end text-xs shadow mr-8" onClick={handleUploadInputOpen}>Close</button>
        <p className="text-center">Upload Files</p>
        <FileDropZone fileText={listedFiles} setFileData={setFileData} />

        <div className="flex w-full justify-around">
          {
            fileData.length > 0 &&
            <>
              <button onClick={handleClearFiles} className="border rounded shadow p-2">Clear files</button>
              <button onClick={handleUploadFiles} className="border rounded shadow p-2">Upload to cloud</button>
            </>
          }
        </div>
      </>
    )
  }

  return (
    <div className="flex flex-col items-center">
      <button className=" text-[0.6rem] border shadow p-1 px-2 rounded-lg" onClick={handleUploadInputOpen}>Upload</button>
      {
        open && (
          <div className="bg-white h-[100vh] w-full absolute top-0 z-20 flex flex-col justify-items-start pt-2">
            {
              isUploading ? <p className="text-center">Uploading...</p> : <UploaderScreen />
            }
          </div>
        )
      }
    </div>

  )
}
import { useState } from "react"
import { isImageLink } from "../../utils/miscUtils"
import Skeleton from "react-loading-skeleton"
import { uploadAssetFile } from "../../api/uploadFileAPI"
import { RxCross2 } from "react-icons/rx"

const BrochureDropZoneDisplay = ({ fileLink, refreshTimer, setImageLoaded }: { fileLink: string, refreshTimer: number, setImageLoaded: Function }) => {

    const [imageNotFound, setImageNotFound] = useState<boolean>(false)

    const handleImageLoad = () => {
        if (!imageNotFound){
            setImageLoaded(true);
        }else{
            setImageLoaded(false)
        }
    };

    return (
        <div className="flex justify-start items-center">
            {
                isImageLink(fileLink) ?
                    <div className="whitespace-nowrap">
                        <img width="150px" src={`${fileLink}?timestamp=${refreshTimer}`} alt="Not found" onError={(e) => {
                            // handleError(e);
                            setImageNotFound(true)
                        }} onLoad={handleImageLoad} />
                        <p>URL: <a className="text-blue-500" href={fileLink} target="_blank">Link</a></p>
                    </div>
                    :
                    <div>
                        <p>Not image</p>
                    </div>
            }
        </div>
    )
}

type FileDataType = {
    name: string,
    blobData: File
}

const BrochureDropZoneUploader = ({ pathName, setFileUrl }: { pathName: string, setFileUrl: Function }) => {
    const [fileHere, setFileHere] = useState<FileDataType>({ name: "", blobData: new File([], "") })
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [message, setMessage] = useState<string>("")
    const [successfulUpload, setSuccessfulUpload] = useState<boolean>(false)

    const handleDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files

        if (!files) {
            return
        }

        const file = files[0]
        console.log("file: ", file)
        const fileDataObj: FileDataType = {
            name: file.name,
            blobData: file
        }
        setFileHere(fileDataObj)
        // @ts-ignore
        e.target.value = null
    }

    const handleUploadFiles = async (file: FileDataType) => {
        let count = 0
        setIsUploading(true)

        const uploadFieldData = {
            previous_file_name: "-",
            fileNamePath: pathName
        }

        const fileUploadResponse = await uploadAssetFile(file.blobData, uploadFieldData)

        if (fileUploadResponse) count++

        setIsUploading(false)
        setFileHere({ name: "", blobData: new File([], "") })

        if (count === 1) {
            setFileUrl(fileUploadResponse.url)
            setMessage("File uploaded successfully!")
            setSuccessfulUpload(true)
        } else {
            setMessage("File upload failed. Please try again!")
            setSuccessfulUpload(false)
        }
    }

    const handleCancel = () => {
        setFileHere({ name: "", blobData: new File([], "") })
    }

    const DisplayMessage = ({ message, success }: { message: string, success: boolean }) => {
        const [displayMessage, setDisplayMessage] = useState<boolean>(true)
        console.log("message: ", displayMessage)
        return (
            <div>
                {
                    displayMessage &&
                    <div className={`flex flex-row w-full justify-between p-1 rounded-md  ${success ? `bg-green-200 border-green-400` : `bg-red-200 border-red-400`}`} >
                        <p>
                            {message}
                        </p>
                        <button className="flex items-center" onClick={() => { setDisplayMessage(previous => !previous); setMessage("") }}>
                            <RxCross2 />
                        </button>
                    </div>
                }
            </div>
        )
    }

    return (
        <div>
            {
                isUploading ?
                    <div className="flex flex-col w-full">
                        <Skeleton height={150} />
                        <div>
                            Uploading asset! Please wait!
                        </div>
                    </div>
                    :
                    <div className="flex flex-col">
                        <div className="relative w-[80%] h-28 shadow rounded mx-auto my-5 overflow-hidden">
                            <input
                                className="absolute border shadow p-3 rounded-md w-full h-full left-0 top-0 opacity-0"
                                type="file"
                                onChange={handleDrop}
                            />
                            <div className="flex flex-col gap-3 justify-center p-7 w-full h-full pointer-events-none">
                                <p className="pt-1 text-xs tracking-wider text-gray-400 group-hover:text-gray-600">
                                    Drag and drop files here
                                </p>
                            </div>
                        </div>
                        {
                            message !== "" &&
                            <DisplayMessage message={message} success={successfulUpload} />
                        }
                        {
                            fileHere.name !== "" &&
                            <div className="flex flex-col">
                                <p>Uploaded File: {fileHere.name}</p>
                                <div className="flex flex-row justify-center gap-x-4">
                                    <button className="hover:bg-blue-300 border-gray-300 bg-gray-200" onClick={async (e) => {
                                        e.preventDefault();
                                        handleUploadFiles(fileHere)
                                    }}>
                                        Upload
                                    </button>
                                    <button className="hover:bg-blue-300 border-gray-300 bg-gray-200" onClick={() => { handleCancel() }}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
            }
        </div>
    )
}


export const BrochureAsset = ({ fileLink, pathName, setImageLoaded }: { fileLink: string, pathName: string, setImageLoaded: Function }) => {

    const [refreshTimestamp, setRefreshTimestamp] = useState<number>(Date.now());

    const handleFileUrlChange = () => {
        setRefreshTimestamp(Date.now());
    };

    return (
        <div className="flex flex-row gap-x-2 w-full">
            <BrochureDropZoneDisplay fileLink={fileLink} refreshTimer={refreshTimestamp} setImageLoaded={setImageLoaded} />
            <BrochureDropZoneUploader pathName={pathName} setFileUrl={handleFileUrlChange} />
        </div>
    );
}
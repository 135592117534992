import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { FormInputChange, FormInputs, obtainFormComponentType } from "../Form";
import { getDefaultWithPrimitiveType } from "../../utils/miscUtils";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

type FormDataValue = string | number | boolean | File | File[] | object | null;
type FormDataType = { [key: string]: FormDataValue };

export interface IMultipleFormFieldWrapperProps {
  keyName: string;
  primitiveDataType: string;
  formComponent: {
    label: string;
    type: "multiple-form";
    children: FormInputs[];
    defaultInput?: any[];
    required?: boolean;
  };
  col?: boolean,
  maxLength?: number,
  expandable?: boolean,
  displayHeader?: boolean
}
function objectToFormData(json: string) {
  const newObj: { [key: string]: any } = {}
  for (const key in JSON.parse(json)) {
    newObj[key] = getDefaultWithPrimitiveType(JSON.parse(json)[key])
  }
  return newObj;
}

// create the form data object
const createFormData = (fields: Array<any>) => {
  let newFormData: { [key: string]: any } = {}
  for (const key in fields) {
    //if default value is set, use that 
    if (fields[key].defaultValue) {
      newFormData = {
        ...newFormData,
        [fields[key].keyName]: fields[key].defaultValue
      }
    }
    //if input type is json, convert to form data
    else if (fields[key].jsonInput) {
      newFormData = {
        ...newFormData,
        [fields[key].keyName]: objectToFormData(fields[key].jsonInput)
      }
    }
    //else set to default value based on primitive data type
    else {
      newFormData = {
        ...newFormData,
        [fields[key].keyName]: getDefaultWithPrimitiveType(fields[key].primitiveDataType)
      }
    }
  }
  return newFormData
}

const MultipleFormFieldWrapper = ({ handleInputChange, formComponent, keyName, primitiveDataType, col = true, maxLength, expandable = false , displayHeader=true}: IMultipleFormFieldWrapperProps & FormInputChange) => {

  const [formData, setFormData] = useState<FormDataType[]>([])
  const [childArray, setChildArray] = useState<any[]>(
    formComponent.children ? [formComponent.children] : [[]]
  )
  const [expand, setExpand] = useState<boolean>(false)

  // useEffect(() => {
  //   const children:any[] = formComponent.children
  //   const newFormData = children.map((child: FormInputs[]) => {
  //     console.log("child in  useEffect", child)
  //     return createFormData(child)
  //   })
  //   setFormData(newFormData)
  // }, [])

  useEffect(() => {
    const children = childArray
    children.map((child: FormInputs[]) => {
      child.map((component: FormInputs, idx: number) => {
        const defaultInput = formComponent.defaultInput?.[idx]
        const defaultValue = defaultInput?.defaultId || defaultInput?.defaultValue || defaultInput?.checked || getDefaultWithPrimitiveType(component?.primitiveDataType)
        handleInputChange(component.keyName, defaultValue)
      })
    })
  }, [childArray])

  const createChangeHandler = (index: number) => (keyName: string, value: any) => {
    handleChange(keyName, value, index);
  };

  const handleChange = (key: string, value: any, index: number) => {
    // set the inner form data for the specific item
    let form = [...formData] // whole array of form objects
    let innerForm = formData[index] // specific inner form object
    if (!innerForm) {
      innerForm = { [key]: value }
    }
    else {
      innerForm = { ...innerForm, [key]: value };
    }
    form[index] = innerForm
    setFormData(form)
    handleInputChange(keyName, form);
  };

  const addChild = () => {
    const newChildArray = [...childArray, formComponent.children]
    setChildArray(newChildArray)
  }

  const removeChild = (idx: number) => {

    setChildArray(prevChildArray => {
      const updatedChildren = [...prevChildArray];
      updatedChildren.splice(idx, 1);
      return updatedChildren;
    });

    const newFormData = [...formData]
    newFormData.splice(idx, 1)
    setFormData(newFormData)
    handleInputChange(keyName, newFormData);
  };


  return (
    <div className="w-full pt-2">
      {
        !expandable ?
          <label htmlFor={keyName} className="block text-sm font-medium text-gray-900">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
          :
          <div className="w-full flex flex-row justify-between border border-gray-300 p-1 rounded-md">
            <label htmlFor={keyName} className="block text-sm font-medium text-gray-900 py-1">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
            <button className="w-full flex justify-end items-center" type="button" onClick={() => { setExpand(previous => !previous) }}>
              {
                expand ?
                  <AiFillCaretUp />
                  :
                  <AiFillCaretDown />
              }
            </button>
          </div>
      }
      {
        expandable && !expand ?
          <div key={keyName}>
          </div>
          :
          <div key={keyName}>
            {
              <div className={`flex  ${col ? `flex-col max-h-[600px] gap-y-3 overflow-y-auto` : `flex-row max-w-full gap-x-3 overflow-x-auto`} `}>
                {
                  childArray.map((child: FormInputs[], outerIndex) => {
                    return (
                      <div key={outerIndex} className={` ${childArray.length > 1 ? `bg-gray-200` : `border border-gray-200 rounded-md`} p-0.5 rounded-md`}>
                        <div className="bg-gray-300 rounded-md">
                        <div className="flex flex-row justify-end" >
                          {
                            childArray.length > 1 &&
                            <div>
                              <button className=" border border-none hover:border-gray-400 p-1 hover:bg-gray-400 hover:p-1 rounded-xl" type="button" onClick={() => {
                                removeChild(outerIndex)
                              }}>
                                <RxCross2 className="cursor-pointer" size="15px" />
                              </button>
                            </div>
                          }
                        </div>
                        {
                          displayHeader &&
                          <div>
                            <h3 className=" font-semibold border-b-2 p-2">{formComponent.label} {outerIndex + 1}</h3>
                          </div>
                        }
                        </div>
                        {
                          child.map((component: FormInputs, innerIndex: number) => {
                            const changeHandler = createChangeHandler(outerIndex);
                            return (
                              <div key={`${component.keyName}_${outerIndex}_${innerIndex} `}>
                                {obtainFormComponentType(component, changeHandler, { defaultValue: formData?.[outerIndex]?.[component.keyName] || getDefaultWithPrimitiveType(component.primitiveDataType) }, true, outerIndex)}
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            }
            {
              maxLength && childArray.length < maxLength &&
              <div className="pt-2">
                <button className="p-2 rounded-md border border-gray-400 hover:bg-white" type="button" onClick={() => {
                  addChild()
                }}>
                  Add {formComponent.label}
                </button>
              </div>
            }
            {
              maxLength === null || maxLength === undefined &&
              <div className="pt-2">
                <button className="p-2 rounded-md border border-gray-400 hover:bg-white" type="button" onClick={() => {
                  addChild()
                }}>
                  Add {formComponent.label}
                </button>
              </div>
            }
          </div>
      }
    </div>
  )
}



export default MultipleFormFieldWrapper;
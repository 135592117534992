import { useEffect, useState } from "react";
import { FormInputChange } from "../Form";
import { DisplayData } from "../TableRelationalDisplay";
import { useFetchList } from "../../hooks/crudHook";
import { fetchAll } from "../../api/manageAPI";

export interface CardProps {
    title?: string;
    description?: string | number | boolean | { [key: string]: any };
    imageUrl: string;
    value:  { [key: string]: any } | string | number | boolean
}

export interface ICard {
    keyName: string;
    primitiveDataType: string;
    formComponent: {
        label: string;
        type: "card";
        cardOptions: CardProps[];
        placeholder?: string;
        required?: boolean;
    }
    defaultValue?: string | number | readonly string[] | undefined
}


export const IndividualCard = ({ imageUrl, title = "", description = "", fullDisplay = false }: CardProps & { fullDisplay?: boolean }) => {

    return (
        <div className={`p-2 ${fullDisplay ? `w-full max-w-[500px]` : `w-[125px]`} text-left`}>
            <div className="flex justify-center rounded-md p-3 pb-2">
                <img src={imageUrl} width={fullDisplay ? "300px" : "125px"} height={fullDisplay ? "200px" : "125px"} alt={title ? title : "Unable to show image"} />

            </div>
            <h2 className="p-3">{title}</h2>
            {
                typeof description === "object" ?
                    <div className="break-before-auto">
                        <DisplayData data={[description]} />
                    </div>
                    :
                    <p className="break-before-auto">{description.toString()}</p>
            }
        </div>
    )
}

const CardField = ({ keyName, formComponent, handleInputChange, defaultValue }: ICard & FormInputChange) => {
    const [selectedCardIndex, setSelectedCardIndex] = useState<number>(-1);
    useEffect(() => {
        if (defaultValue === undefined || defaultValue === null || defaultValue === "") {
            setSelectedCardIndex(-1)
            return;
        }
        if (defaultValue) {
            const index = formComponent.cardOptions.findIndex((card) => {
                return defaultValue === card.value
            })
            setSelectedCardIndex(index)
        }
    }, [defaultValue])

    const handleSelection = (index: number) => {
        setSelectedCardIndex(index);
        handleInputChange(keyName, formComponent.cardOptions[index].value);
    }

    return (
        <div key={keyName}>
            {/** Component Label + Placeholder */}
            <label htmlFor={keyName} className="block mb-2 text-sm font-medium text-gray-900npm ">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
            <p className="text-sm text-gray-500 pt-0.5">{formComponent.placeholder ? formComponent.placeholder : "Click on a card to select it"}</p>
            {/**List of Card Component*/}
            <div className="w-[475px] overflow-x-auto">
                <div className="flex flex-row gap-x-2 snap-x scroll-smooth">
                    {
                        formComponent.cardOptions.map((card: CardProps, index: number) => (
                            <button type="button" className={` snap-x rounded-md border border-gray-400 hover:bg-blue-200 ${index === selectedCardIndex ? `bg-gray-400` : ``}`} key={card.imageUrl}
                                onClick={() => {
                                    handleSelection(index);
                                }}
                            >
                                <IndividualCard {...card} />
                            </button>
                        ))
                    }
                </div>
            </div>
            {/** Displays the chosen card value */}
            <div className="flex flex-row gap-x-1 pt-0.5 text-sm text-gray-500">
                <p className="font-bold">Selected Card:</p>
                <p> {selectedCardIndex === -1 ? "None" : formComponent.cardOptions[selectedCardIndex].value.toString()}</p>
            </div>
        </div>
    )
}

export default CardField
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useRef, useState , ChangeEvent} from "react";
import { useAlertToastHook } from "../hooks/alertToastHook";


interface IDragDrop {
    setFile: Function;
    validTypes: Array<string>;
    validTypeMessage: string;
    borderDisplay?:boolean;
    accept: string;
}

const DragAndDropFile = ({ setFile, validTypes , validTypeMessage , borderDisplay=true , accept}: IDragDrop) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [dragActive, setDragActive] = useState(false);
    const { addMessage } = useAlertToastHook();

    //handle dragging event
    const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }


    const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        //validate the file type when dropped
        const droppedFile = e.dataTransfer.files;
        if (droppedFile[0] && validTypes.includes(droppedFile[0].type)) {
            console.log("droppedFile type: ", droppedFile[0].type)
            setFile(droppedFile[0]);
        }
        else {
            addMessage("Invalid file type. " + validTypeMessage, false);
        }
    };

    //handle upload button click
    const handleInputUploadButton = () => {
        inputRef.current?.click();
    };
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        setFile(e.target.files[0]);
    };

    return (
        <div className={`${borderDisplay? `border border-[#3D426B] border-dashed` : ``} bg-slate-50 ${dragActive ? `border-2 ` : ``} opacity-90`} onDragEnter={handleDrag} onDragOver={handleDrag} onDragLeave={handleDrag} onDrop={handleDrop}>
            <input
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept={accept}
            />
            <div className="p-2 w-full h-[250px] flex flex-col justify-center items-center">
                <div className="pb-2 flex flex-col items-center">
                    <AiOutlineCloudUpload size={"80px"} color="#3D426B" />
                    <p className="text-[#3D426B] font-bold">Drag or Drop your file here or</p>
                </div>
                <button className="bg-slate-50 rounded-md border-[#3D426B] border hover:bg-[#deeff7]" onClick={handleInputUploadButton}><p className="p-1">Click here to upload</p></button>
                <p>{`(`}{validTypeMessage}{`)`}</p>
            </div>
        </div>
    )
}

export default DragAndDropFile;

import { usePagination } from '../hooks/usePaginations';
import { RxArrowLeft, RxArrowRight, RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx'

interface PaginationProps {
  onPageChange: (page: number) => void,
  totalCount: number,
  currentPage: number,
  pageSize: number
}

const Pagination = ({ onPageChange, totalCount, currentPage, pageSize }: PaginationProps) => {

  const { startItemIndex, lastItemIndex, lastPage, paginationText } = usePagination({ currentPage, totalCount, pageSize });

  const onFirst = () => {
    onPageChange(1)
  }

  const onLast = () => {
    onPageChange(lastPage)
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const buttonSize = "26px"

  return (
    <div className='flex flex-row justify-end gap-0.5 min-w[380px]:gap-6 md:gap-6 '>
      <p className="max-w[380px]:text-xs md:text-base ">{paginationText}</p>
      <div className='flex flex-row gap-2'>
        <button onClick={onFirst} disabled={currentPage === 1 || totalCount === 0}>
          <RxDoubleArrowLeft size={buttonSize} color={currentPage === 1 || totalCount === 0 ? "#BBBBBB" : "black"} />
        </button>
        <button onClick={onPrevious} disabled={currentPage === 1 || totalCount === 0}>
          <RxArrowLeft size={buttonSize} color={currentPage === 1 || totalCount === 0 ? "#BBBBBB" : "black"} />
        </button>
        <button onClick={onNext} disabled={currentPage === lastPage || totalCount === 0}>
          <RxArrowRight size={buttonSize} color={currentPage === lastPage || totalCount === 0 ? "#BBBBBB" : "black"} />
        </button>
        <button onClick={onLast} disabled={currentPage === lastPage || totalCount === 0}>
          <RxDoubleArrowRight size={buttonSize} color={currentPage === lastPage || totalCount === 0 ? "#BBBBBB" : "black"} />
        </button>
      </div>
    </div>
  )
};

export default Pagination;
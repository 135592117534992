import { useState, useEffect, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import { CardProps } from "./CardField";
import Searchbar from "../filterDataComponents/Searchbar";
import CreateUpdate from "../CreateUpdate";
import { useCreateSingle, useFetchList } from "../../hooks/crudHook";
import { createObj, fetchAll } from "../../api/manageAPI";
import { FormInputChange, FormInputs } from "../Form";
import { flattenData } from "../../utils/miscUtils";
import PopUpWrapper from "../wrappers/PopUpWrapper";
import MultipleSelectField from "./MultipleSelectField";
import { DisplayData } from "../TableRelationalDisplay";

export interface INestedFormProps {
    keyName: string;
    primitiveDataType: string;
    formComponent: {
        label: string;
        type: "nested-form";
        transformMapper: { [key: string]: string[] };
        pathName: string;
        queryName: string;
        pageName: string;
        nestedCreateFields: FormInputs[];
        required?: boolean;
    };
    defaultValue?: string | number | readonly string[] | undefined | any[] | { [key: string]: any };
    setData? : Function
}


export const VehicleCards = ({ imageUrl, title = "", description = "", fullDisplay = false }: CardProps & { fullDisplay?: boolean }) => {

    return (
        <div className={`p-2 ${fullDisplay ? `w-full max-w-[500px]` : `w-[125px]`} text-left`}>
            <div className="flex justify-center rounded-md p-3 pb-2">
                <img src={imageUrl} width={fullDisplay ? "300px" : "125px"} height={fullDisplay ? "200px" : "125px"} alt={"Unable to show image"} />

            </div>
            <h2 className="p-3 font-bold capitalize text-base">{title}</h2>
            {
                typeof description === "object" ?
                    <div className="break-before-auto">
                        <DisplayData data={[description]} />
                    </div>
                    :
                    <p className="break-before-auto">{description.toString()}</p>
            }
        </div>
    )
}


const NestedForm = <T extends { [key: string]: any }>({ keyName, formComponent, defaultValue, handleInputChange , setData }: INestedFormProps & FormInputChange) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedCardIndex, setSelectedCardIndex] = useState<number>(-1);

    const [openSideBar, setOpenSideBar] = useState<boolean>(false);
    const createQuery = useCreateSingle<T>(createObj, formComponent.queryName, formComponent.pathName);
    const [dataVal, setDataVal] = useState<CardProps[]>([])
    const [confirmedData , setConfirmedData] = useState<{[key:string]:any}>({})
    const { isFetching, data, invalidateQuery } = useFetchList<T>(fetchAll, formComponent.queryName, formComponent.pathName);
    const [searchData, setSearchData] = useState<Array<{ [key: string]: any }>>(dataVal.length > 0 ? dataVal : []);
    const [originalData , setOriginalData] = useState<Array<{ [key: string]: any }>>(dataVal.length > 0 ? dataVal : []);
    const multipleSelectData:{[key:string]:any} = useRef()
    const [newlyCreatedVehicle , setNewlyCreatedVehicle] = useState<{[key:string]:any} | null>({})

    const handleCardSelection = (index: number) => {
        const cardData = searchData[index]
        // obtain the index of the selected data in the original data
        const indexInOriginalData = originalData.findIndex((data) => {
            return JSON.stringify(data) === JSON.stringify(cardData)
        })
        setSelectedCardIndex(indexInOriginalData)
        // setConfirmedData(dataVal[indexInOriginalData])
        if(setData){
            const dataToForm = {
                configuration: multipleSelectData.current.configuration,
                summaryBlockMeta:  data?.[indexInOriginalData]["summary_block_meta"]
            }
            setData(dataToForm)
        }
        if(!dataVal[indexInOriginalData]?.value){
            return
        }
        let dataToReturn = {displayName: dataVal[indexInOriginalData].title}
        if(typeof dataVal[indexInOriginalData]?.value === "object"){
            const val = {...dataVal[indexInOriginalData].value as object , ...multipleSelectData.current , ...dataToReturn}
            setConfirmedData(val)
            handleInputChange(keyName, val)
        }
        else{
            setConfirmedData({...dataVal[indexInOriginalData].value as object  , ...dataToReturn})
            handleInputChange(keyName, dataVal[indexInOriginalData]?.value)
        }
        // handleInputChange(keyName, formComponent.cardData[index].value)
        setIsDialogOpen(false)
    }

    const handleClearSelection = () => {
        setSelectedCardIndex(-1)
        setConfirmedData({})
        multipleSelectData.current = {configuration:[] , summaryBlockMeta:null}
        if(setData){
            setData(multipleSelectData.current)
        }
        // setConfirmedData({ title: "", imageUrl: "", description: "", value: {} })
        handleInputChange(keyName, null)
    }

    const handleSideBarClose = () => {
        setOpenSideBar(false)
    }

    const cardTransformer = (data: any): CardProps => {
        const goalObject: any = {};
        let mapper = formComponent.transformMapper

        for (const key in mapper) {
            const fields = mapper[key];
            let obj: any;

            if (fields.length === 1) {
                obj = data?.[fields[0]];
            } else {
                obj = {};
                fields.map((field, index) => {
                    if (key === "value") {
                        const [objectKey, propertyKey] = field.split(".");
                        if (propertyKey === undefined) {
                            obj[objectKey] = data?.[field];
                        }
                        else {
                            obj[objectKey] = { ...obj[objectKey], [propertyKey]: data?.[field] };
                        }
                    }
                    else if (key==="title"){
                        if(index === 0){
                            obj = data?.[field]
                        }
                        else{
                            obj = obj + " " + data?.[field]
                        }
                    }
                    else {
                        obj[field] = data?.[field];
                    }
                });
            }
            if (JSON.stringify(obj) === JSON.stringify({})) {
                obj = ""
            }
            goalObject[key] = obj
        }

        return goalObject;
    }
    const handleMultipleSelect = (key: string, value: any) => {
        let item: { [key: string]: any } = { [key]: value }
        multipleSelectData.current = item
    }



    useEffect(() => {
        if (defaultValue === undefined || defaultValue === null || defaultValue === "") {
            setSelectedCardIndex(-1)
            return;
        }
        if (defaultValue) {
            const index = dataVal?.findIndex((card) => {
                return JSON.stringify(defaultValue) === JSON.stringify(card.value)
            })
            setSelectedCardIndex(index)
            // setConfirmedData(formComponent.cardData[index])
        }
    }, [])



    useEffect(() => {
        if (!data) return;
        if (data?.length < 0) {
            return;
        }

        const flatten = flattenData(data)

        const value: CardProps[] = flatten.map((item: any) => {
            return cardTransformer(item)
        })

        if (value.length > 0) {
            setDataVal(value)
            setOriginalData(value)
            setSearchData(value)
        }

    }, [data])

    const GridCardSelection = ({ index, card, value }: { index: number, card: { [key: string]: any }, value: number }) => {
        const path = "vehicle/" + value
        const { isFetching, data, invalidateQuery } = useFetchList<T>(fetchAll, "VehicleImageList"+value, path);
        const [firstImage , setFirstImage] = useState<string>(card?.imageUrl)
        const [selectDisabled , setSelectDisabled] = useState<boolean>(true)

        useEffect(() => {
            if(!data) return;
            setFirstImage(data?.[0]?.overviewImage?.image_url || card?.imageUrl)
        },[])

        return (
            <div className="hover:bg-gray-300 rounded-md bg-white" key={index}>
                <VehicleCards fullDisplay={true} imageUrl={firstImage} value={card?.value} description={card?.description} title={card?.title} />
                <MultipleSelectField
                    keyName={"configuration"}
                    primitiveDataType={"string"}
                    formComponent={{
                        label: "Body Colour Configuration",
                        type: "multiple-select"
                    }} handleInputChange={handleMultipleSelect}
                    colourNames={data || []}    
                    setDisabled={setSelectDisabled}
                />
                <div className="flex flex-row gap-x-3 p-2">
                    <button type="button" className={`${selectDisabled? `bg-gray-300`:`bg-gray-700 `} p-1 text-white rounded-md `} title={selectDisabled? `Select configuration`:`select vehicle`} 
                            disabled={selectDisabled} onClick={() => {handleCardSelection(index);}}>
                        Select
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div key={keyName}>
            <label htmlFor={keyName} className="block text-sm font-medium text-gray-900 pt-2">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
            {/*Selection Button to trigger Dialog component */}
            <div className="flex flex-row gap-x-2">
                {
                    selectedCardIndex === -1 &&
                    <button className="bg-white p-2 rounded-md border border-gray-300 hover:bg-blue-200" type="button" onClick={() => setIsDialogOpen(true)}>Select {formComponent.pageName}</button>
                }
                {
                    selectedCardIndex !== -1 &&
                    <button className="bg-white p-2 rounded-md border border-gray-300 hover:bg-blue-200" type="button" onClick={() => { console.log("clicked"); handleClearSelection() }}>
                        Clear Selection
                    </button>
                }
            </div>
            {/* Confirmed Selected Vehicle */}
            <div className="flex flex-row gap-x-1 pt-0.5 text-sm text-gray-500">
                <p className="font-bold">Selected {formComponent.pageName}:</p>
                <p>{JSON.stringify(confirmedData)!=="{}" ? confirmedData?.displayName.toString() : "None"}</p>
                {/* <p> {confirmedData.value ? JSON.stringify(confirmedData.value) : "None"}</p> */}
            </div>

            {/* Dialog content */}
            <dialog className="z-30 w-full h-fit rounded-md bg-gray-200 max-h-screen overflow-y-auto" open={isDialogOpen} onClose={() => { setIsDialogOpen(false) }}>
                <div className="flex flex-col">
                    <div className="bg-gray-200 sticky -top-4 pb-2">
                        {/* Title and Button to close dialog */}
                        <div className="flex flex-row justify-between">
                            <h1 className="text-lg font-bold pb-1">{formComponent.pageName}</h1>
                            <button type="button" onClick={() => {setIsDialogOpen(false); setNewlyCreatedVehicle({})}}><RxCross2 /></button>
                        </div>
                        {/* Searchbar and Create New button */}
                        <div className="flex flex-row justify-between">
                            <Searchbar data={dataVal || []} columnNames={["description.vehicle.make", "description.vehicle.model", "description.vehicle.year", "description.name" , "title"]} setSearchResult={setSearchData} />
                            <button type="button" className=" bg-gray-700 p-1 text-white rounded-md" onClick={() => { setOpenSideBar(true); console.log("create new selected"); }}>Create New</button>
                        </div>
                        {
                            JSON.stringify(newlyCreatedVehicle)!=="{}" &&
                            <div className="pt-2">
                            {newlyCreatedVehicle === null ?
                            <p className="w-full p-2 bg-red-300 border-red-400 rounded-md ">
                                Error creating vehicle
                            </p>
                            :
                            <p className="w-full p-2 bg-green-300 border-red-400 rounded-md ">
                                Vehicle has been created successfully
                            </p>}
                            </div>
                        }
                    </div>
                    {/* Card selection Grid */}
                    {
                        isFetching ?
                            <div>
                                <p>Loading...</p>
                            </div>
                            :
                            <div className="grid grid-cols-3 gap-2 pt-2">
                                {
                                    searchData.length > 0 ?
                                        searchData.map((card, index) => {
                                            return (
                                                <div key={index}>
                                                    <GridCardSelection index={index} card={card} value={card?.value?.id || 0} />
                                                </div>
                                            )
                                        }) :
                                        <div>
                                            <p>No results found</p>
                                        </div>
                                }
                            </div>
                    }
                </div>
                { // SideBar to create new vehicle variant
                    openSideBar &&
                    <PopUpWrapper closeMenu={handleSideBarClose} parentTag={""}>
                        <div className="w-full">
                            <div className='flex justify-between pt-4 pl-4 '>
                                <h2 className="text-lg  font-bold">Create Vehicle Variant</h2>
                            </div>
                            <div>
                                <CreateUpdate fields={formComponent.nestedCreateFields}
                                    query={createQuery}
                                    onClose={() => {setOpenSideBar(false)}} action={"created"} pathName={"vehicle"} setReturnData={setNewlyCreatedVehicle}/>

                            </div>
                        </div>
                    </PopUpWrapper>
                }
            </dialog>
        </div >
    )
}

export default NestedForm


{/**
const dataFromDB: Array<{ [key: string]: any }> = [
        {
            id: 1,
            name: "Boss 429",
            vehicle: {
                make: "Ford",
                model: "Mustang",
                year: 1969
            },
            displayName: "Ford Mustang 1969 Boss 429"
            // imageUrl: "https://www.motortrend.com/uploads/sites/5/2018/11/01-classic-recreations-1969-boss-429-mustang.jpg"
        },
        {
            id: 2,
            name: "S",
            vehicle: {
                make: "Mercedes-AMG",
                model: "A45",
                year: 2020
            },
            displayName: "Mercedes-AMG 2020 A45 S"
            // imageUrl: "https://www.topgear.com/sites/default/files/images/cars-road-test/2020/10/216c69178c1cd4bb11de0bf2fe811ca6/img_5770.jpg"
        },
        {
            id: 3,
            name: "Saloon",
            vehicle: {
                make: "Mercedes-Benz",
                model: "S-Class",
                year: 2022
            },
            vehicle_variant: {
                id: 3,
                name: "Saloon"
            },
            displayName: "Mercedes-Benz 2022 S-Class Saloon"
            // imageUrl: "https://mediacloud.carbuyer.co.uk/image/private/s--An_TCPHP--/f_auto,t_content-image-full-desktop@1/v1620898674/autoexpress/2021/05/Mercedes%20S-Class%202021%20UK-18.jpg"
        },
        {
            id: 4,
            name: "Sedan",
            vehicle: {
                make: "Mercedes-Benz",
                model: "C-Class",
                year: 2020
            },
            displayName: "Mercedes-Benz 2020 C-Class Sedan"
            // imageUrl: "https://cdn.carbuzz.com/gallery-images/1600/816000/900/816902.jpg",
        },
        {
            id: 5,
            name: "Coupe",
            vehicle: {
                make: "Mercedes-Benz",
                model: "GLC",
                year: 2020
            },
            displayName: "Mercedes-Benz 2020 GLC Coupe"
            // imageUrl: "https://www.cnet.com/a/img/resize/394fbb965b4d9a09d76e1999ec8ec37330b44aaa/hub/2016/03/24/683c83ef-ac36-426b-8ef1-5233e2d34b53/2017mercedes-benzglccoupe-019.jpg?auto=webp&fit=crop&height=675&width=1200",
        }
    ]
*/}
    // useEffect(() => {
    //     console.log("dataVal: ", dataVal)
    // },[dataVal])

    // Transforms the original data that is rertrieved from the backend into the format that the card component expects
    // const cardTransformer = (data: any) => {
    //     const goalObject: any = {};
    //     let mapper = formComponent.transformMapper

    //     for (const key in mapper) {
    //         const fields = mapper[key];
    //         let obj: any;

    //         if (fields.length === 1) {
    //             obj = data?.[fields[0]];
    //         } else {
    //             obj = {};
    //             fields.map(field => {
    //                 if (key === "value") {
    //                     const [objectKey, propertyKey] = field.split(".");
    //                     obj[objectKey] = { ...obj[objectKey], [propertyKey]: data?.[field] };
    //                 }
    //                 else {
    //                     obj[field] = data?.[field];
    //                 }
    //             });
    //         }

    //         goalObject[key] = obj
    //     }

    //     return goalObject;
    // }

            // const flatten = flattenData(data)
        // console.log("flatten: ", flatten)
        // const value: CardProps[] = flatten.map((item: any) => {
        //     return cardTransformer(item)
        // })
        // console.log("value: ", value)
        // if (value.length > 0) {
        //     setDataVal(value)
        //     setSearchData(value)
        // }
import React, { useState, useEffect } from "react";
import { BiSearchAlt } from "react-icons/bi"
import { MdCancel } from "react-icons/md";
import {  searchData } from "../../utils/filteringData";


interface Props {
    data: Array<{ [key: string]: any }>;            // unmodified data
    columnNames: Array<string>;                     // column names to be searched
    setSearchResult: Function,                      // function to set search result
    resultList: Array<{ [key: string]: any }>;      // list of modified data
    index : number                                  // index of the data that will be modified
}

const SearchList = <T extends { [key: string]: any }>({ data, columnNames, setSearchResult, index , resultList}: Props) => {
    const [searchQuery, setSearchQuery] = useState<string>("");

    useEffect(() => {
        if (searchQuery ===  ""){
            setSearchResult([...resultList.slice(0,index), [...data], ...resultList.slice(index+1)])
        }
    }, [searchQuery])

    useEffect(() => {
        onSearch()
    }, [data])

    const onSearch = () => {
        let dataProcessFilter = data;

        if (searchQuery !== '') {
            dataProcessFilter = searchData(dataProcessFilter, columnNames, searchQuery) as T[];
        }
        setSearchResult([...resultList.slice(0,index), [...dataProcessFilter], ...resultList.slice(index+1)])
    }

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearchQuery(e.target.value.toLowerCase());
    }

    return (
        <div className="border rounded-sm bg-white border-gray-400 inline-flex w-fit shadow-md">
            <p className=" pt-1.5 pl-2">
                <BiSearchAlt color="gray" size={"20px"} />
            </p>
            <input
                className="bg-inherit pl-2 focus:outline-none w-[97px] min-w[380px]:w-fit md:w-fit"
                type="text"
                placeholder="Type to search..."
                value={searchQuery}
                onChange={onSearchChange}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onSearch()
                    }
                }}
            />
            {/**Cancel Button */}
            {
                <button className="pr-2"
                    onClick={() => {
                        setSearchQuery("")
                    }}
                    ><MdCancel color={searchQuery? `gray` : `white`} size={"16px"} /></button>
            }
            <button className="bg-blue-900 text-white pr-2 pl-2 rounded-r-sm py-1 hover:text-blue-200 hover:bg-blue-700"
                onClick={onSearch}
                title="Search">
                Search
            </button>
        </div>
    );
}

export default SearchList;
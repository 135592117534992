import { useState, useMemo, useEffect } from "react";
import { useAlertToastHook } from "../hooks/alertToastHook";
import { useSelectedRecordHook } from "../hooks/selectedRecordHook";
import FormTemplate, { FormInputs } from "./Form";
import { SpinnerLoader } from "./Loader";
import { createUpdateNested, getDefaultWithPrimitiveType, validatorType } from "../utils/miscUtils";
import { vehicleGenerationHelper } from "../pages/Vehicle/VehicleHelper";

type FormDataValue = string | number | boolean | File | File[] | object | null;
type FormDataType = { [key: string]: FormDataValue };

interface ICreateUpdate<T> {
  fields: Array<any>,         //form fields
  query: Function,            //updateQuery / createQuery
  onClose: () => void,
  item?: T,                   //item to be updated
  action: string,             // create / update -- used for Message
  parentFormTag?: string
  pathName: string
  setReturnData?: Function
}

function objectToFormData(json: string) {
  const newObj: { [key: string]: any } = {}
  for (const key in JSON.parse(json)) {
    newObj[key] = getDefaultWithPrimitiveType(JSON.parse(json)[key])
  }
  return newObj;
}


// create the form data object
const createFormData = (fields: Array<any>) => {
  let newFormData: { [key: string]: any } = {}
  for (const key in fields) {
    //if default value is set, use that 
    if (fields[key].defaultValue) {
      newFormData = {
        ...newFormData,
        [fields[key].keyName]: fields[key].defaultValue
      }
    }
    //if input type is json, convert to form data
    else if (fields[key].jsonInput) {
      newFormData = {
        ...newFormData,
        [fields[key].keyName]: objectToFormData(fields[key].jsonInput)
      }
    }
    //else set to default value based on primitive data type
    else {
      newFormData = {
        ...newFormData,
        [fields[key].keyName]: getDefaultWithPrimitiveType(fields[key].primitiveDataType)
      }
    }
  }
  return newFormData
}


const CreateUpdate = <T extends { [key: string]: any }>({ fields, query, item, onClose, action, pathName , setReturnData }: ICreateUpdate<T>) => {

  const [formData, setFormData] = useState<FormDataType>({})
  const [errorData, setErrorData] = useState<Array<any>>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const { tableName, dealershipId } = useSelectedRecordHook()

  useEffect(() => {
    let newFormData: { [key: string]: any } = {}
    // use the default values if they exist
    newFormData = createFormData(fields)

    //set formData value if default values exists
    if (item) {
      const table = "salesperson."
      for (const key in newFormData) {
        let keyValue = key
        if (key.includes(table)) {
          keyValue = key.replace(table, "")
        }
        newFormData[key] = item[keyValue]
      }
    }
    // if fields are dependent on the dealership id filtering
    if (dealershipId.dealershipId && tableName === "salesperson") {
      newFormData = {
        ...newFormData,
        "salesperson.dealershipId": Number(dealershipId.dealershipId) === 0 ? -1 : Number(dealershipId.dealershipId)
      }
    }
    else if (dealershipId.dealershipId) {
      newFormData = {
        ...newFormData,
        "dealership.id": Number(dealershipId.dealershipId) === 0 ? -1 : Number(dealershipId.dealershipId)
      }
    }

    setFormData(newFormData)
  }, [item])


  const handleInputChange = (keyName: string, value: any) => {
    // if input type is number float , modify into number
    fields.map((field) => {
      if (field.keyName === keyName && field?.primitiveDataType === "number") {
        value = Number(value)
      }
    })
    setFormData(prev => {
      return {
        ...prev,
        [keyName]: value
      }
    });
  };

  const { addMessage } = useAlertToastHook();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFetching(previous => !previous)
    //object to be modified or created
    if (tableName === "Salesperson") {
      delete formData["salesperson.id"]
    }
    // validate the form data , if there are errors, return
    const validateFormData = handleValidation(fields, formData)
    const includesValueOtherThanEmptyString = validateFormData.some((item) => item !== "")
    if (includesValueOtherThanEmptyString) {
      setErrorData(validateFormData)
      setIsFetching(previous => !previous)
      return
    }

    let submitFormData = formData
    if(pathName === "new-vehicle" && action ==="created"){
      submitFormData =vehicleGenerationHelper(submitFormData)
    }
    const newObj = createUpdateNested(submitFormData);
    const result = await query(newObj as T);

    if(pathName==="new-vehicle" && action ==="created"){
      setIsFetching(previous => !previous)
      onClose();
      if(result){
        if (setReturnData) {
          setReturnData(result)
          addMessage("Created Vehicle", true)
        }
      }
      else{
        if(setReturnData){
          setReturnData(null)
          addMessage("Error", false)
        }
      }
      return
    }
    if (result?.id === undefined) {
      setIsFetching(previous => !previous)
      onClose();
      addMessage("Error", false)
      return
    }
    if (result) {
      setIsFetching(previous => !previous)
      onClose();
      addMessage(tableName + " has been " + action, true)
      // addMessage("Id " + result.id + " has been " + action, true)
    }
    else {
      setIsFetching(previous => !previous)
      onClose();
      addMessage("Error", false)
    }
  };

  const handleValidation = (inputFields: any, formData: { [key: string]: any }) => {
    const validatFormData: Array<any> = []

    for (const key in inputFields) {
      let field = inputFields[key]
      const keyName = field.keyName

      // if type is a radio button, ignore the field
      if (field?.formComponent.type === "radio") {
        validatFormData.push("")
        continue;
      }

      // if the field is required and the value is empty or set to the default of -1
      if (field?.formComponent.required === true && field?.formComponent?.disabled !== true) {
        if (formData[keyName] === "" || formData[keyName] === null || formData[keyName] === undefined || formData[keyName] === -1) {
          validatFormData.push("This field is required")
          continue;
        }
      }
      // check based on primitive data type
      switch (field?.primitiveDataType) {
        case "number":
          if (formData[keyName] !== -1 && isNaN(formData[keyName])) {
            validatFormData.push("This field must be a number")
          }
          else {
            validatFormData.push("")
          }
          break
        case "string":
          if (formData[keyName] === "") {
            validatFormData.push("")
            break;
          }
          if (field?.formComponent.type === "text") {
            const validation = validatorType(field?.formComponent.inputType, formData[keyName])
            validatFormData.push(validation ? validation : "")
          }
          else {
            validatFormData.push("")
          }
          break
        default:
          validatFormData.push("")
          break
      }
    }
    return validatFormData
  }

  //value type to obtain the default value
  const valueTypeTransformer = (field: any) => {
    return field.map((individualField: any) => {
      let key = individualField.keyName
      let tableName = "Salesperson"

      if (key.includes(tableName.toLowerCase() + ".")) {
        key = key.replace(tableName.toLowerCase() + ".", "")
      }
      switch (individualField.formComponent.type) {
        case "checkbox":
          return {
            defaultValue: item?.[key] || formData[key]
          }
        case "dropdown-search":
          return {
            defaultValue: item?.[key] || formData[key]
          }
        // modify this  
        case "radio":
          const component = individualField?.formComponent?.radioFields
          component.map((radioField: any) => {
            if (radioField.component.keyName === key) {
              return {
                defaultValue: item?.[key] || formData[key]
              }
            }
          })
          return {
            defaultValue: item?.[key] || formData[key]
          }

        default:
          if (typeof (item?.[key]) === "object" || individualField.type === "json")
            return {
              defaultValue: JSON.stringify(item?.[key]) || JSON.stringify(formData[key])
            }
          if (individualField.defaultValue) {
            return {
              defaultValue: individualField.defaultValue
            }
          }
          return {
            defaultValue: item?.[key] || (formData[key] === -1 ? "" : formData[key])
          }
      }
    })
  }

  //static and handleInputChange
  const formFieldsConfigs = fields.map((field) => {
    return ({
      ...field,
    })
  })

  const [fieldValues, setFieldValues] = useState(valueTypeTransformer(fields))

  useEffect(() => {
    setFieldValues(valueTypeTransformer(fields))
  }, [formData, errorData])

  return (
    <>
      {
        isFetching ?
          <div className="flex items-center justify-center h-screen">
            <SpinnerLoader />
          </div>
          :
          <div> {/*handleInput static data  */}
            <FormTemplate inputFieldsParams={formFieldsConfigs as Array<FormInputs>} onSubmit={handleFormSubmit} defaultValues={fieldValues} onChange={handleInputChange} errors={errorData} pathName={pathName} action={action}/>
          </div>
      }
    </>
  )
}

export default CreateUpdate;

import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import { BatchHeader } from "./BatchUploadFileUploader";
import { cellFunction } from "../../utils/miscUtils";
import { v4 as uuidv4 } from 'uuid';
import DisplayTable, { DisplayTableRows, HeaderCell } from "../../components/DisplayTable";
import ImageFolderUploader from "../../components/ImageFolderUploader";
import { RxCross2 } from "react-icons/rx";
import { BsUpload } from "react-icons/bs";

interface BatchUploadImageUploaderProps {
    headers: BatchHeader[];
    data: any;
    tableName: string;
    setPage: Function;
    page: number;
    pageArray: Array<number>;
    setBatchData: Function;
}

const BatchUploadImageUploader = ({ headers, data, tableName, setPage, page, pageArray , setBatchData }: BatchUploadImageUploaderProps) => {

    // sidebar images preview
    const [files, setFiles] = useState<Array<File> | null>(null);
    const [fileUrl, setFileUrl] = useState<Array<string>>([]);
    const [dragActive, setDragActive] = useState<boolean>(false);
    const [dragIndex, setDragIndex] = useState<number>(-1);
    const [newInput, setNewInput] = useState<Array<any>>(data);

    // display row table
    const fileHeader = headers.map((item) => { return item.label });
    const keyHeader = headers.map((item) => { return item.key });
    const [tableData, setTableData] = useState<Array<DisplayTableRows>>([])

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.setData("text/plain", fileUrl?.[dragIndex] || "No Name");
    }

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, key: string, curIndex: number) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        const updatedNewInput = newInput.map((item, i) => {
            console.log("key: " , key)
            console.log("curIndex: " , curIndex)


            const indexOfKey = keyHeader.indexOf(key);
            console.log("indexOfKey: " , indexOfKey)


            if(i === curIndex) {
                item[indexOfKey] = e.dataTransfer.getData("text/plain");
            }
            return item;
        });
        setNewInput(updatedNewInput);
    }

    const handleCancel = (key: string, curIndex: number) => {
        const updatedNewInput = newInput.map((item, i) => {

            const indexOfKey = keyHeader.indexOf(key);
            // console.log("indexOfKey: " , indexOfKey)


            if(i === curIndex) {
                item[indexOfKey] = "";
            }
            return item;
        });
        setNewInput(updatedNewInput);
    }
    console.log("newInput", newInput)

    // table image
    useEffect(() => {
        const tempData = newInput.map((item: any, index: number) => {
            return ({
                rowData: [
                    ...headers.map((header) => {
                        if (header.type === "image") {
                            let Cell: Function = cellFunction(header.type);
                            let itemIndex = keyHeader.indexOf(header.key);
                            return {
                                data:
                                    <div onDrop={(event: React.DragEvent<HTMLDivElement>) => { handleDrop(event, header.key, index) }} onDragStart={handleDragStart} onDragOver={handleDragOver}>
                                        {
                                            !item[itemIndex] || item[itemIndex] === "" ?
                                                <div className="border border-gray-400 p-2">
                                                    <p className="flex justify-center"><BsUpload size={"14px"}/></p>
                                                    <p className="text-xs break-words flex justify-center pt-1">Drop image here</p>
                                                </div>
                                                :
                                                <div className="flex flex-row gap-2">
                                                    <div className="flex flex-col">
                                                    <Cell key={index.toString() + uuidv4()} data={item[itemIndex]}/>
                                                    <p>{item[itemIndex]}</p>
                                                    </div>
                                                    <button className="flex justify-end text-black font-bold" onClick={() => { handleCancel(header.key, index) }}><RxCross2 size={"16px"} /></button>
                                                </div>
                                        }
                                    </div>
                                , clickableCallback: () => { }
                            }
                        }
                        let Cell: Function = cellFunction(header.type);
                        let itemIndex = keyHeader.indexOf(header.key);
                        return {
                            data: <Cell key={index.toString() + uuidv4()} data={item[itemIndex]} />,
                            clickableCallback: () => { }
                        }
                    })
                ]
            } || {})
        })
        setTableData(tempData)
    }, [data, files, newInput])

    return (
        <div>
            <div className="p-2 flex flex-row border border-gray-200 rounded-md shadow-md border-dotted">
                <div className="p-2 w-[75vw]">
                    <p>Please upload a folder of image for the the image fields.</p>
                    <p>Make sure that the images are of type .png and .jpg</p>
                    <DisplayTable
                        headers={fileHeader.map((item, index) => { return (<HeaderCell key={uuidv4() + index.toString()} title={item} />)})}
                        data={tableData} />
                </div>
                <ImageFolderUploader setDragActive={setDragActive} files={files} setFiles={setFiles} fileUrl={fileUrl} setFileUrl={setFileUrl} dragIndex={dragIndex} setDragIndex={setDragIndex} tableName={tableName} />
            </div>
            <div className="pt-2">
                <button className="p-1 bg-blue-700 text-white rounded-sm hover:bg-blue-500" onClick={() => { setBatchData(newInput); setPage(pageArray[pageArray.indexOf(page) + 1]) /*setPage((previous: number) => previous + 1)*/ }}>Proceed to database upload</button>
            </div>
        </div>
    )
}

export default BatchUploadImageUploader;
import { api_host } from "../config/hostConfig";
import axios from "axios";
import { getAuthenticationToken } from "./apiAuthentication";

// attach the jwt token to the header

const getAssetFilenameFromURL = (asset: string) => {
    if (!asset || asset === "") return ""
    // split the url by / and get the last element
    const split = asset.split("/")
    if (split.length === 0) return ""
    return split[split.length - 1]
}

export type FileUploadPostFields = {
    previous_file_name: string, // must -- else empty string
    table_name?: string,
    field_name?: string, //which column 
    record_id?: string,
    tag?: string // something meaningful but can be empty,
    fileNamePath?: string
}

export const uploadAssetFile = async (file: File, fileUploadData: FileUploadPostFields, host_url?: string): Promise<any> => {

    let api_url = `${api_host}/api/uploadAssetFile`

    const formData = new FormData();
    formData.append('file', file);

    //append the body
    const fieldData: { [key: string]: any } = {
        previous_file_name: fileUploadData?.previous_file_name || "-", // must -- else empty string
        fileNamePath: fileUploadData?.fileNamePath || "" 
    }

    Object.keys(fieldData).map((key) => {
        formData.append(key, fieldData[key])
    })

    try {
        const response = await axios.post(api_url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...getAuthenticationToken().headers
            }
        })
        if (response.status !== 200) return null
        return response.data?.data || null
    }
    catch (err: any) {
        return null
    }

}



export const uploadFile = async (file: File, fileUploadData: FileUploadPostFields, host_url?: string): Promise<any> => { //get the string
        
    let api_url = `${api_host}/api/uploadFile`

    if (host_url) api_url = `${host_url}/api/uploadFile`

    const formData = new FormData();
    formData.append('file', file);

    //append the body
    const fieldData: { [key: string]: any } = {
        previous_file_name: getAssetFilenameFromURL(fileUploadData.previous_file_name), // must -- else empty string
        table_name: fileUploadData?.table_name || "",
        field_name: fileUploadData?.field_name || "", //which column 
        record_id: fileUploadData?.record_id || "-1",
        tag: fileUploadData?.tag || "", // something meaningful but can be empty,
        fileNamePath: fileUploadData?.fileNamePath || ""
    }

    Object.keys(fieldData).map((key) => {
        formData.append(key, fieldData[key])
    })

    try {
        const response = await axios.post(api_url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...getAuthenticationToken().headers
            }
        })

        if (response.status !== 200) return null

        return response.data?.data || null
    }
    catch (err: any) {
        return null
    }
}
import { useAlertToastHook } from "../hooks/alertToastHook"
import { RxCross2 } from "react-icons/rx"
import {CiCircleAlert, CiCircleCheck} from 'react-icons/ci'

interface IAlertBox {
    //message: string,
    message: [string, boolean],
    closeMessage: () => void,
}

const AlertBox = ({ message, closeMessage }: IAlertBox) => {
    const alertSuccess = message[1]
    return (
        <div className="rounded-md p-4 w-full whitespace-pre-wrap text-left flex flex-col gap-2 shadow-md bg-gray-100 opacity-90">
            <div className="flex flex-row">
                {alertSuccess && <CiCircleCheck size="20px" className="text-green-500"/>}
                {!alertSuccess && <CiCircleAlert size="20px" className="text-red-500"/>}
                <button onClick={closeMessage} className="ml-auto"><RxCross2 /></button>
            </div>
            <p>{message}</p>
        </div>
    )
}

const AlertToast = () => {

    const { messageList, removeAllMessage, removeMessage } = useAlertToastHook()

    const closeSpecificMessage = (id: number) => {
        removeMessage(id)
    }

    return (
        <div className="flex flex-col gap-5 w-[20vw] bottom-2 right-5 fixed overflow-y-auto max-h-[90vh]">
            {
                messageList.length > 0 && <button className=" bg-gray-100 opacity-90 rounded-md shadow-md" onClick={removeAllMessage}>Clear all</button>
            }
            {
                messageList.map((ml, idx) => {
                    return (
                        <AlertBox key={idx} message={ml} closeMessage={() => { closeSpecificMessage(idx) }} />
                    )
                }).reverse()
            }
        </div>
    )
}

export default AlertToast
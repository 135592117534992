import { useState } from "react";
import { FormInputChange, FormInputs, obtainFormComponentType } from "../Form";
import { getDefaultWithPrimitiveType } from "../../utils/miscUtils";

interface IRadioField {
    label: string,
    component: FormInputs[],
    defaultInput: any[],
}

export interface IRadioDropdown {
    keyName: string,
    primitiveDataType: string,
    formComponent: {
        type: "radio",
        label: string,
        placeholder: string,
        radioFields: IRadioField[]
        disabled?: boolean,
        required?: boolean,
    },
    defaultValue?: any
}

const RadioDropdown = ({ handleInputChange, formComponent , keyName}: IRadioDropdown & FormInputChange) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [selectedComponent, setSelectedComponent] = useState<any>(formComponent.radioFields[0])

    // when radio button changes, set all the keys to the default value in the formData
    const onRadioChangeSetDefault = (e: any , option:IRadioField , optionIndex:number) => {
        setSelectedIndex(optionIndex);
        setSelectedComponent(option)
        const fields = formComponent.radioFields
        fields.map((field:IRadioField , idx:number) => {
            field.component.map((component: FormInputs , idx:number)=>{
                const defaultInput = field.defaultInput?.[idx]
                const defaultValue = defaultInput?.defaultId || defaultInput?.defaultValue || defaultInput?.checked || getDefaultWithPrimitiveType(component?.primitiveDataType)
                handleInputChange(component.keyName, defaultValue)
            })
        })
    }

    const handleChange = (key: string, value: any) => {
        const object = {
            priceType : key , 
            prices : value
        }
        handleInputChange(keyName, object)
      };


    return (
        <div key={selectedComponent.component.keyName}>
            <label htmlFor={selectedComponent.component.keyName} className="block mb-2 text-sm font-medium text-gray-900">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
            <fieldset >
                <div className="flex flex-col">
                    {
                        // display radio buttons
                        formComponent?.radioFields?.map((option, optionIndex) => (
                            <div className="flex flex-row items-center" key={optionIndex}>
                                <input type="radio" id={option.label} name={option.label} value={option.label} {...(optionIndex === selectedIndex ? { checked: true } : { checked: false })}
                                    onChange={(e) => {onRadioChangeSetDefault(e , option , optionIndex) }}
                                />
                                <label htmlFor={option.label}>{option.label}</label>
                            </div>
                        ))
                    }
                </div>
            </fieldset>

            {
                <div>
                    {
                        // display the nested form components based on the selected radio button
                        selectedComponent &&
                            <div className=" flex flex-col gap-y-2">
                                {
                                    selectedComponent.component.map((component:FormInputs, idx: number) => {
                                        const defaultInput = selectedComponent.defaultInput?.[idx]
                                        // dependents dropdowns ... display the filtered by the others dropdowns
                                        return obtainFormComponentType(component, handleChange, idx , defaultInput)
                                    })
                                }
                            </div>
                    }
                </div>
            }
        </div>
    )
}

export default RadioDropdown;

export const SpinnerLoader = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="py-4 w-full flex items-center justify-center ">
        <div className="w-20 h-20 rounded-full border-4 border-dashed border-blue-800 border-t-transparent animate-spin"></div>
      </div>
    </div>
  )
}

const Loading = () => {
  return (
    <div role="status" className=" animate-pulse">
      <div className="fixed pt-2 bg-gray-400 rounded-md  w-screen h-screen "></div>
    </div>
  )
}

export const ProgressBar = ({ current, total }: { current: number, total: number }) => {
  let percentage = 0;
  if(total===0)
    percentage = 100;
  else{
    percentage = Math.round((current / total) * 10000)/100;
    if (percentage > 100) percentage = 100;
  }
  return (
    <div className="flex flex-col items-center">
      <div className="w-full bg-gray-300 h-fit rounded-full">{
        percentage === 0 ?
          <p className="text-white w-full flex justify-center">0%</p> 
          :
          <div className="bg-blue-500 h-fit rounded-full" style={{ width: `${percentage}%` }}>
            <p className="text-white w-full flex justify-center">{percentage}%</p>
          </div>
      }
      </div>
    </div>
  )
}

export default Loading;

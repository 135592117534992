import { useState } from 'react'
import { FormInputChange } from '../Form'
import { BiErrorCircle } from 'react-icons/bi'

export interface ICheckbox {
  keyName: string,
  primitiveDataType: string,
  formComponent: {
    label: string,
    type: "checkbox",
    disabled?: boolean,
    required?: boolean
  }
  loading?: boolean,
  error?: any
}

export interface IDefaultCheckProps {
  checked: boolean,
}

const CheckBox = ({ keyName , checked=false, formComponent , loading, handleInputChange , error}: ICheckbox & FormInputChange & IDefaultCheckProps) => {
  //if checkbox input

  const [checkState, setCheckState] = useState(checked)

  return (
    <div key={keyName} className="flex items-center mb-4">
      {
        !loading ?
          <input
            className="w-4 h-4 text-blue-600 bg-blue-100 border-blue-300 rounded focus:ring-blue-500"
            type={formComponent.type}
            checked={checkState}
            disabled={formComponent.disabled}
            required={formComponent.required}
            onChange={
              (e) => {
                setCheckState(e.target.checked)
                handleInputChange(keyName, e.target.checked)
              }
            }
          />
          :
          <div className="w-4 h-4 animate-pulse bg-300"></div>
      }
      <label htmlFor={keyName} className="block ml-2 text-sm font-medium text-gray-900">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
      {
              error &&
                <div className="flex flex-row gap-x-1 items-center">
                  <div className="pt-1">
                    <BiErrorCircle color="red" />
                  </div>
                  <p className="text-red-600 text-sm pt-1">{error}</p>
                </div>
            }
    </div>
  )
}

export default CheckBox;
import axios from "axios"
import { getAuthenticationToken } from "./apiAuthentication"
import { api_host } from "../config/hostConfig"

export const getUserConfig = async () => {
    const api = `${api_host}/api/configuration`
    try{
        const response = await axios.get(api , getAuthenticationToken())
        if(response.status !== 200) return null
        return response.data
    }
    catch (err: any){
        console.log(err)
        return null
    }
}

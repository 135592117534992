
export type SortDirection = 'asc' | 'desc';

export const sortData = <T extends {[key:string]:any}>(data: T[], sortKey: string, sortDirection: SortDirection) => {
  return data.sort((a, b) => {
    const aValue = a[sortKey];
    const bValue = b[sortKey];

    if (aValue < bValue) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });
}

const listOfMediaExtensions = ['.mp4', '.mov', '.jpg', '.png', '.jpeg', '.gif', 'webp', 'pdf'];

export const isValidMediaFile = (mediaUrl: string) => {
  return listOfMediaExtensions.some((extension) => mediaUrl.endsWith(extension));
}

export const startAsyncDebounceTimer = async (delay: number, setBool: (b: boolean) => void) => {
  setBool(false)
  await new Promise((resolve) => { setTimeout(resolve, delay) })
  setBool(true)
}
import axios from "axios"
import { getCookie } from "../utils/miscUtils"

export const getAuthenticationToken = ()=> {
    const token = getCookie("token")

    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

//added
export const getAllWithParamsToken = async <T,>(api_url:string, param:string ,  value:string):Promise<T|null> => {
    try{
        const response = await axios.get(api_url, getAuthenticationToken())

        if(response.status !== 200) return null

        const filteredResponse = response.data?.data.filter((item:any) => item[param] === value)
        console.log("filter here: " , filteredResponse)

        return filteredResponse || null
    }
    catch (err: any){
        console.log(err)
        return null
    }
}
//

export const getAllWithToken = async <T,>(api_url:string):Promise<T|null> => {
    try{
        const response = await axios.get(api_url, getAuthenticationToken())

        if(response.status !== 200) return null

        return response.data?.data || null
    }
    catch (err: any){
        console.log(err)
        return null
    }
}

export const getSingleWithToken = async <T,>(api_url:string, id:number):Promise<T|null> => {
    const url = `${api_url}/${id}`
    try{
        const response = await axios.get(url, getAuthenticationToken())

        if(response.status !== 200) return null

        return response.data?.data || null
    }
    catch (err: any){
        console.log(err)
        return null
    }
}

export const postWithToken = async <T,>(api_url:string, payload:any):Promise<T|null> => {
    try{
        const response = await axios.post(api_url, payload, getAuthenticationToken())

        if(response.status !== 200) return null

        return response.data?.data || null
    }
    catch (err: any){
        console.log(err?.response?.data?.message?.issues)
        return err?.response?.data?.message?.issues || null
    }
}

export const patchWithToken = async <T,>(api_url:string, id:number, payload:any):Promise<T|null> => {
    const url = `${api_url}/${id}`
    try{
        const response = await axios.patch(url, payload, getAuthenticationToken())

        if(response.status !== 200) return null

        return response.data?.data || null
    }
    catch (err: any){
        console.log(err)
        return null
    }
}

export const deleteWithToken = async (api_url:string, id:number) => {
    const url = `${api_url}/${id}`
    try{
        const response = await axios.delete(url, getAuthenticationToken())

        if(response.status !== 200) return null

        return response.data?.data || null
    }
    catch (err: any){
        console.log(err)
        return null
    }
}
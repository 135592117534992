import { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

interface IAccordion {
    title: string;
    children: JSX.Element;
}

const Accordion = ({ title, children }: IAccordion) => {

    const [open, setOpen] = useState<boolean>(false);

    return (
        <div className="flex flex-col p-2">
            <div className="flex justify-between bg-gray-200 border border-gray-300 p-2" onClick={() => { setOpen(previous => !previous) }}>
                <h2>{title}</h2>
                {
                    open ? <AiOutlineUp /> : <AiOutlineDown />
                }
            </div>
            {
                open &&
                <div className="">
                {children}
            </div>
            }
        </div>
    )
}
export default Accordion
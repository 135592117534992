import { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useSequentialHook } from "../hooks/sequentialFileHook";
import { useSelectedRecordHook } from "../hooks/selectedRecordHook";

interface INavBar {
    title: string,
    selectedIdx: number,
    setSelectedIdx: (id: number) => void,
    headers: Array<Array<string>>, // [name, parent, path]
    parents: Array<string>,
    setNavBarOpen: (open: boolean) => void
}

const NavBar = ({ title, headers, selectedIdx, setSelectedIdx, parents, setNavBarOpen }: INavBar) => {

    const {removeAllPages} = useSequentialHook()
    const {removeStoredField , setDealershipGroupId , setDealershipId , setTableName} = useSelectedRecordHook()
    const [selectedParents, setSelectedParents] = useState<boolean[]>([true]);
    const navigate = useNavigate()

    const handleParent = (index: number) => {
        const newSelectedParents = [...selectedParents];
        newSelectedParents[index] = !newSelectedParents[index];
        setSelectedParents(newSelectedParents);
    }

    return (
        <div className="bg-blue-800 text-white h-[100vh] flex flex-col pt-3 text-left whitespace-pre-line overflow-x-hidden overflow-y-auto">

            <div className="translate-x-0 ease-in-out">
                <div className="flex flex-row">
                    <p className="text-xs px-2 font-bold">{title}</p>
                    <button className="w-fit mx-2 " onClick={() => { setNavBarOpen(false) }}>
                        <FiMenu size={"16px"} color={"white"} />
                    </button>
                </div>
                <div className="flex flex-col pt-10 text-xs">
                    {
                        // Generate the parent NavBar button
                        parents.map((parent, idx) => {
                            return (
                                <div key={uuidv4() + parent + idx.toString()}>
                                    <div className="cursor-pointer">
                                        <button className={`w-full text-left py-1 ${selectedParents[idx] ? `bg-blue-900` : ``}`} 
                                        onClick={() => { handleParent(idx) }}>
                                            {
                                                selectedParents[idx] ? 
                                                <div className="flex flex-row w-full justify-between">
                                                    <p className="pt-1 px-2 text-gray-200 font-bold">{parent}</p>
                                                    <div className="pt-0.5 pr-2 align-middle"><MdExpandLess size="18px" color="white"/></div>
                                                </div>
                                                :
                                                <div className="flex flex-row w-full justify-between">
                                                    <p className="pt-1 px-2 text-gray-200 font-bold">{parent}</p>
                                                    <div className="pt-0.5 pr-2 align-middle"><MdExpandMore size="18px" color="white"/></div>
                                                </div>
                                            }
                                        </button>
                                    </div>
                                    {
                                        // Generate the child NavBar button
                                        selectedParents[idx] && headers?.map((header, idx) => {
                                            if (header?.[1] !== parent) return <></>
                                            return (
                                                <div className="cursor-pointer" key={uuidv4() + idx + header?.[0]}>
                                                    <button className={`w-full text-left h-7 py-0.5 px-2 ${idx === selectedIdx ? `bg-blue-900 text-green-300 ` : `text-white`}`} 
                                                    onClick={() => {
                                                        removeAllPages(); 
                                                        removeStoredField(); 
                                                        setDealershipGroupId(-1) ; 
                                                        setDealershipId(-1); 
                                                        setSelectedIdx(idx);
                                                        setTableName(header?.[0])
                                                        navigate("/management" + header?.[2]);}}>
                                                        <p>{header?.[0]}</p>
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default NavBar
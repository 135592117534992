
import { create } from 'zustand'

interface IAlertToastStore {
    messageList: Array<[string,boolean]>,
    addMessage: (newMessage: string , success: boolean) => void,
    removeMessage: (id:number)=>void,
    removeAllMessage: () => void
}

const useAlertToastStore = create<IAlertToastStore>((set) => ({
    messageList: [],
    addMessage: (newMessage: string , success: boolean) => set((state) => ({ messageList: [...state.messageList, [newMessage , success]] })),
    

    removeMessage: (id: number) => set((state) => {
        const tempList = [...state.messageList]
        tempList.splice(id, 1)
        return { messageList: tempList }
    }),
    removeAllMessage: () => set(() => ({ messageList: [] }))
}))

// An intermediate hook is required before access the store for future possible hook implementations
export const useAlertToastHook = () => {

    const {messageList, addMessage, removeAllMessage, removeMessage} = useAlertToastStore()

    // const {messageList, addMessage, removeAllMessage} = useAlertToastStore()
    return({messageList, addMessage, removeAllMessage, removeMessage})
}
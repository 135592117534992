import {useState} from "react";
import { createObj } from "../api/manageAPI";
import { useCreateSingle, useCreateSingleWithoutInvalidate } from "../hooks/crudHook";
import { FormInputs } from "./Form";
import CreateUpdate from "./CreateUpdate";

export interface ICreatePanelProps<T> {
    title: string,
    fieldsCreate: Array<FormInputs>,
    queryNameList: string,
    pathName: string,
    handleClose: () => void
}

export const CreatePanel = <T extends { [key: string]: any }>({title , fieldsCreate , handleClose , queryNameList , pathName}:ICreatePanelProps<T>)=>{
    const [close , setClose] = useState(false);
    const createQuery = useCreateSingle<T>(createObj  ,queryNameList ,  pathName);

    return(
        <div className="pl-2">
           <div className='flex justify-between pt-4 '>
                <h2 className="text-lg font-bold">Create {title}</h2>
            </div>
            <CreateUpdate onClose={()=>{handleClose()}}
                fields={fieldsCreate} query={createQuery} action={"created"} pathName={pathName}/>
        </div>
    )
}
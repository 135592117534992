import axios from "axios";
import { useEffect, useState } from "react";
import { getAuthenticationToken } from "../../api/apiAuthentication";
import { useQuery, useQueryClient, useMutation } from "react-query";

interface IFileDirectoryQuery {
  name: string,
  url: string,
  type: string
}

export const useFetchFileDirectory = (path: string) => {

  const host = "https://api.ssp.dev.gotvsd.com"

  const queryClient = useQueryClient()

  const pathQuery = ['fileDirectory', { path }]

  const fetchFileDirectory = async () => {

    let api_url = `${host}/api/list-directory`

    if (path !== "/" && path !== "") {
      api_url = `${host}/api/list?folderPath=${path}`
    }

    const res = await axios.get(api_url, getAuthenticationToken())

    return res.data;
  }

  const refreshDirectory = () => {
    queryClient.invalidateQueries(pathQuery)
  }

  const { isLoading, error, data } = useQuery<IFileDirectoryQuery[]>({
    queryKey: pathQuery,
    queryFn: fetchFileDirectory
  });

  return { isLoading, error, data, refreshDirectory }
}

export const useSearchFile = (fileName: string) => {

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [data, setData] = useState<IFileDirectoryQuery[]>([])

  const host = "https://api.ssp.dev.gotvsd.com"

  const queryClient = useQueryClient()

  const pathQuery = ['searchFile', { fileName }]

  useEffect(() => {
    if (fileName === "") {
      setData([])
    }
  }, [fileName])

  const fetchSearchDirectory = async () => {

    setIsLoading(true)
    setError("")
    setData([])

    if (fileName === "") return null

    try {
      const api_url = `${host}/api/search-directory?fileName=${fileName}`

      const res = await axios.get(api_url, getAuthenticationToken());
      setData(res.data)
    } catch (err) {
      setError(JSON.stringify(err))
    }
    setIsLoading(false)
  }

  // const { isLoading, error, data } = useQuery<IFileDirectoryQuery[]>({
  //   queryKey: pathQuery,
  //   queryFn: fetchSearchDirectory
  // });

  // const refreshSearch = () => {
  //   queryClient.invalidateQueries("searchFile")
  // }

  return { isLoading, error, data, fetchSearchDirectory }
}
import { useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FormInputChange } from "../Form";

interface IMultipleSelect {
    keyName: string,
    primitiveDataType: string,
    formComponent: {
        label: string,
        type: "multiple-select",
        placeholder?: string,
        required?: boolean,
    },
    defaultValue?: string | number | readonly string[] | undefined,
    colourNames?: any,
    setDisabled?: Function
}

const MultipleSelectField = ({ keyName, formComponent, defaultValue, handleInputChange, colourNames , setDisabled}: IMultipleSelect & FormInputChange) => {
    const [openMultipleSelect, setOpenMultipleSelect] = useState<boolean>(false);
    const [addNew, setAddNew] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [newColour, setNewColour] = useState<string>("");

    const [checked, setChecked] = useState<Array<any>>([]); // array of checked items
    const [originalList, setOriginalList] = useState<Array<any>>(colourNames); // original list of items

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        var updatedChecked = [...checked];
        const modifiedValue = originalList[index];

        if (isChecked(modifiedValue, checked)) {
            // Remove the selected colour from the checked array
            const indexInChecked = updatedChecked.indexOf(modifiedValue);
            // console.log("index of modified value: ", indexInChecked)
            updatedChecked.splice(indexInChecked, 1);
        }
        else {
            // Add the selected colour to the checked array
            updatedChecked.push(modifiedValue);
        }
        setChecked(updatedChecked);
        // handleInputChange(keyName , updatedChecked)
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewColour(e.target.value)
    }

    const handleSave = () => {
        const updatedList = [...originalList];
        const item = {
            colour: {
                colourName: newColour,
                colourLogo: ""
            },
            overviewImage: {
                image_url: ""
            }
        }

        const checkedList = [...checked];
        // check if the item is already in the original list 
        if (isChecked(item, originalList)) {
            // if item is already in the original list...., don't add into the updateList
            if (!isChecked(item, checked)) {
                // if item is not in the checked list, add into the checkedList
                checkedList.push(item);
            }
        }
        else {
            // if item is not in the original list, add into the updateList
            updatedList.push(item);
            checkedList.push(item);
        }
        setChecked(checkedList)
        setOriginalList(updatedList);
        setSaved(true);
        setAddNew(false);
        // handleInputChange(keyName , checked)
    }

    const isChecked = (colour: any, array: Array<any>) => {
        return array.some((item) => item.colour.colourName === colour.colour.colourName);
    }

    const handleConfirmSelectionList = () =>{
        setOpenMultipleSelect(false)
        if(setDisabled){
            setDisabled(false)
        }
        handleInputChange(keyName , checked)
        // console.log("checked list in the confirm selection:"  , checked)
    }

    return (
        <div className="p-3">
            <label className="font-bold capitalize text-base pl-2">{formComponent.label}</label>
            <div className="bg-white rounded-md p-2 border border-gray-200 flex flex-row justify-between">
                {
                    checked.length > 0 ?
                        <div className=" flex flex-wrap gap-x-2 gap-y-2">
                            {
                                // List of checked items
                                checked.map((colour, index) => {
                                    return (
                                        <div className="flex flex-row p-0.5 gap-x-1 justify-between bg-gray-300 rounded-lg" key={index}>
                                            <p>{colour.colour.colourName}</p>
                                            {
                                                openMultipleSelect &&
                                                <button type="button" onClick={() => { setChecked(checked.filter((item) => item !== colour)) }}>X</button>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <p>Select Body Colours</p>
                }
                <button type="button" onClick={() => { setOpenMultipleSelect(previous => !previous) }}>
                    {
                        openMultipleSelect ?
                            <AiFillCaretUp />
                            :
                            <AiFillCaretDown />
                    }
                </button>
            </div>
            {
                openMultipleSelect &&
                <div>
                    {
                        // options for all the data that is in the original list
                        originalList.map((colour, index) => {
                            return (
                                <div className="flex flex-row p-2 gap-x-2" key={index}>
                                    <input type="checkbox" className="w-5 h-5" checked={isChecked(colour, checked)} onChange={(e) => { handleCheck(e, index) }} />
                                    <p>{colour.colour.colourName}</p>
                                </div>
                            )
                        })
                    }
                    {
                        addNew ?
                            <div className="flex flex-row w-full justify-between">
                                <input type="text" placeholder="Enter Colour Name" className="border border-gray-200 rounded-md p-2" onChange={handleTextChange}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSave();
                                        }
                                    }} />
                                <div className="flex flex0=-row gap-x-2">
                                    <button type="button" className="hover:bg-blue-300 rounded-md p-2" onClick={handleSave}>Save</button>
                                    <button type="button" onClick={() => { setAddNew(false) }}>X</button>
                                </div>
                            </div>
                            :
                            <div className="w-full flex flex-row pt-1 gap-x-2">
                                <button type="button" className="hover:bg-blue-300 rounded-md p-2 border border-gray-400" onClick={() => { setAddNew(true); setNewColour("") }}>
                                    Add New
                                </button>
                                <button type="button" className="hover:bg-blue-300 rounded-md p-2 border border-gray-400" onClick={()=>{handleConfirmSelectionList()}} >
                                    Confirm Colour Selection
                                </button>
                            </div>
                    }
                </div>
            }
        </div >
    )
}
export default MultipleSelectField;
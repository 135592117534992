import { useState, useEffect } from "react"
import { RxCross1 } from 'react-icons/rx'
import { filterDataByExact, filterDataBySimilar } from "../../utils/filteringData"

interface IFilterBar<T> {
    data: Array<T>,
    setFilterData: Function,
    columnNames: Array<string>,
    tableHeader?: Array<string>
}

interface IFilterMenu {
    addFilter: (key: string, filterData: FilterDataType) => void,
    closeMenu: () => void,
    toggleMenu: () => void,
    setCurrentFilter: Function,
    currentFilter: string,
    filterKeys: Array<string>
}

interface IFilterButton {
    filterKey: string,
    filterItem: FilterDataType | null,
    removeFilter: (key: string) => void
}

type FilterDataType = {
    value: string | number,
    type: "exact" | "similar"
}

const FilterMenu = ({ currentFilter, setCurrentFilter, filterKeys, addFilter, closeMenu }: IFilterMenu) => {

    const [filterValue, setFilterValue] = useState<string>("")
    const [filterType, setFilterType] = useState<string>("exact")

    const onAddFilter = () => {
        if (filterValue && (filterType === "exact" || filterType === "similar"))
            addFilter(currentFilter, {
                value: filterValue,
                type: filterType
            })
        closeMenu()
    }

    return (
        <div className="bg-white p-5 rounded-lg shadow-md">
            <div className="flex flex-row gap-4 pb-2">
                <p>Filter by:
                    <span className="pl-2 my-auto">
                        <select className="border-2 rounded-md shadow-sm" onChange={(e) => { setCurrentFilter(e.target?.value || "") }} value={currentFilter}>
                            {
                                filterKeys.map((key, idx) => {
                                    return (
                                        <option key={idx} value={key}>
                                            {key}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </span>
                </p>

            </div>
            <div className="flex flex-col min-w[380px]:flex-row sm:flex-row gap-4 pt-2">
                <input className="rounded-lg pl-1 border-2" type="text" value={filterValue} onChange={(e) => { setFilterValue(e?.target?.value || "") }} placeholder="Enter filter value" />

                <select className="rounded-lg border-2" onChange={(e) => { setFilterType(e?.target?.value || "") }} value={filterType}>
                    <option value="exact">Filter by exact</option>
                    <option value="similar">Filter by similar</option>
                </select>
                <button type="button" className="rounded-md p-1 shadow-md" onClick={onAddFilter}>Add Filter</button>
                <button type="button" className="" onClick={closeMenu}><RxCross1 /></button>
            </div>

        </div>
    )
}

const FilterButton = ({ filterKey, removeFilter, filterItem }: IFilterButton) => {

    return (
        <div className="text-white bg-blue-600 rounded-2xl p-1 flex flex-row justify-around text-xs shadow-md">
            <p className="uppercase">{filterKey}</p>
            {
                filterItem && <p>{`(${filterItem.type}): ${filterItem.value} `}</p>
            }
            <button type="button" onClick={() => { removeFilter(filterKey) }}><RxCross1 /></button>
        </div>
    )
}

// Input: Pass a whole table of data to be filtered with arbituary data field types
// Input: Data key fields in array

// Output: Filtered table data

// Features of filters: Have column name either with contain filter or drop down filter

// UI: A list of selected filters buttons, with a cross to remove the filter
// UI: Reset all filters

const FilterBar = <T extends { [key: string]: any }>({ data, columnNames, setFilterData , tableHeader=[] }: IFilterBar<T>) => {

    const dataNames = columnNames.filter((cn) => {
        return !cn?.includes("action")||""
    })
    const colNames = tableHeader.filter((cn) => {
        return !cn?.includes("action")||""
    })




    const [filters, setFilters] = useState<{ [filterKey: string]: FilterDataType }>()

    const [currentFilter, setCurrentFilter] = useState<string>(colNames?.[0] || "")

    const [displayFilterMenu, setDisplayFilterMenu] = useState(false)

    const addFilter = (key: string, filterData: FilterDataType) => {
        setFilters(filter => filter ? { ...filter, [key]: filterData } : { [key]: filterData })
    }

    const removeFilter = (key: string) => {
        const newFilters = Object.assign({}, filters)
        delete newFilters[key]
        setFilters(newFilters)
    }

    const toggleMenu = () => {
        setDisplayFilterMenu(previous => !previous)
    }

    useEffect(() => {
        let dataProcessFilter = data

        for (const filterKey of filterList) {
            const index = colNames.indexOf(filterKey)
            if (filters?.[filterKey].type === "exact") {
                dataProcessFilter = filterDataByExact(dataProcessFilter, dataNames[index], filters?.[filterKey].value) as T[]
            } else if (filters?.[filterKey].type === "similar") {
                dataProcessFilter = filterDataBySimilar(dataProcessFilter, dataNames[index], filters?.[filterKey].value) as T[]
            }
        }
        setFilterData(dataProcessFilter)
    }, [filters])

    const filterList: Array<string> = filters ? Object.keys(filters) : []

    return (
        <div className="w-full flex flex-col gap-1">
            <div className="flex flex-col gap-2 pb-1 relative">
                <button type="button" className="border-1 bg-white rounded-sm p-1 shadow-sm w-fit h-7.5" onClick={toggleMenu} title="Add Filter">Add Filter</button>
                {
                    displayFilterMenu &&
                    <div className="absolute mt-[40px]">
                        <FilterMenu filterKeys={colNames} currentFilter={currentFilter || ""} setCurrentFilter={setCurrentFilter} addFilter={addFilter} closeMenu={() => { setDisplayFilterMenu(false) }} toggleMenu={toggleMenu} />
                    </div>
                }
            </div>
            <div className="flex flex-col w-fit min-w[380px]:flex-row sm:flex-row gap-1">
                {
                    filterList.length > 0 && <p>Filters: </p>
                }
                {
                    filterList.map((filterKey, idx) => {
                        return (
                            <FilterButton filterKey={filterKey} key={idx} removeFilter={removeFilter} filterItem={filters?.[filterKey] || null} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default FilterBar
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai"
import { IBrochureAssetQuery, IConfigurationAssetQuery, IConfigurationQuery, IVehicleConfigurationQuery, useFetchBrochureAssets } from "./brochureAssetHook"
import {  useState } from "react"
import { BrochureAsset } from "./BrochureUploaderDropzone"
import Skeleton from "react-loading-skeleton"
import Accordion from "../../components/wrappers/Accordion"

const concatFileName = (fileName: string, concatWord: string) => {
    const parts = fileName.split(concatWord);
    return parts[1]
}

const VariantDropDown = (item: IBrochureAssetQuery) => {
    const [openVariant, setOpenVariant] = useState<boolean>(false)
    const bodyColourConfiguration: IConfigurationQuery[] = item.new_vehicle_configuration

    return (
        <div className="flex flex-col w-full" key={item.display_name}>
            <button className="border border-gray-200 p-2 rounded-md shadow-md" onClick={() => { console.log("clicked variant " + item.display_name); setOpenVariant(previous => !previous) }}>
                <div className="flex flex-row justify-between items-center text-base">
                    <p>{item.display_name}</p>
                    {
                        openVariant ? <AiFillCaretUp /> : <AiFillCaretDown />
                    }
                </div>
            </button>
            {
                openVariant &&
                <div className="flex flex-col gap-y-0.5">
                    {
                        bodyColourConfiguration.map((bodyColour: IConfigurationQuery, idx) => {
                            let vehicleBodyConfiguration: IVehicleConfigurationQuery = bodyColour.vehicle_configuration
                            let galleryConfiguration: IConfigurationAssetQuery[] = bodyColour.new_vehicle_configuration_assets
                            return (
                                <div className="flex flex-col" key={idx}>
                                    <Accordion title={"Assets for colour configurations " + vehicleBodyConfiguration.body_colour}>
                                        <div>
                                            <BodyColourDisplay bodyColour={vehicleBodyConfiguration} logoIdentifier={"body_colour_logo"} labelIdentifier={"body_colour"} />
                                            {
                                                galleryConfiguration.map((galleryItem: IConfigurationAssetQuery, idx) => {
                                                    return (
                                                        <div className="flex flex-col" key={idx}>
                                                            <BodyColourDisplay bodyColour={galleryItem} logoIdentifier={"image_url"} labelIdentifier={"image_type"} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Accordion>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

const labelGeberator = (identifier: string) => {
    switch (identifier) {
        case "body_colour_logo":
            return "Colour Logo Url"
        case "body_colour":
            return "Body Colour"
        case "image_url":
            return "Gallery Image Url"
        case "image_type":
            return "Gallery Image Type"
        default:
            return ""
    }
}


const BodyColourDisplay = ({ bodyColour, logoIdentifier, labelIdentifier }: { bodyColour: { [key: string]: any }, logoIdentifier: string, labelIdentifier: string }) => {

    const [fileName, setFileName] = useState<string>(concatFileName(bodyColour?.[logoIdentifier] || "cassie/", "cassie/"))
    const [isImageExist, setIsImageExist] = useState<boolean>(false)

    const Status = () => {
        return (
            <div>
                {
                    isImageExist ?
                        <div className="bg-green-300 border-green-500 rounded-xl p-1">
                            <p>Uploaded</p>
                        </div>
                        :
                        <div className="bg-red-300 border-red-500 rounded-xl p-1">
                            <p>Yet to Upload</p>
                        </div>
                }
            </div>
        )
    }

    const setImageExistance = () => {
        setIsImageExist(true)
    }

    return (
        <div className="flex flex-col w-full border border-t-2 border-b-2 border-gray-200 p-2">
            <div className="flex flex-col gap-y-2">
                <p className="text-base">

                    {labelGeberator(labelIdentifier)} : {bodyColour?.[labelIdentifier]}
                </p>
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-x-2">
                        <p className="flex items-center">
                            {labelGeberator(logoIdentifier)}:
                        </p>
                        <p className="border border-gray-300 rounded-lg p-2">
                            {concatFileName(bodyColour?.[logoIdentifier], "cassie/")}
                        </p>
                    </div>
                    <Status />
                </div>
                <BrochureAsset fileLink={bodyColour?.[logoIdentifier]} pathName={fileName} setImageLoaded={setImageExistance} />
            </div>
        </div>
    )
}






const BrochureAssetUploader = ({shortLink , brochureLink}:{shortLink:string , brochureLink:string}) => {

    const { isLoading, data, error, refreshAsset } = useFetchBrochureAssets(shortLink)
    console.log("data: ", data)

    const transformBrochureLink = (link:string) => {
        if(!link.startsWith("https://")){
            return("https://" + link)
        }
        return link
    }


    return (
        <div className="p-2 flex flex-col gap-y-2">
            <h1 className="text-xl font-bold capitalize">Successful Vehicle Creation</h1>
            <div>
                <p>New vehicle has been created. Click on the link below to have a preview of the new vehicle DIB. Note that the assets have not been uploaded, resulting in empty vehicle, features and gallery components.</p>
            </div>
            <div className="flex flex-row gap-x-2 pt-3">
                <p>DIB Link:</p>
                <a className=" text-blue-800" href={transformBrochureLink(brochureLink)} target="_blank" rel="noreferrer">{transformBrochureLink(brochureLink)}</a>
            </div>

            <div className="flex flex-col gap-y-2">
                <p className="text-base font-bold">Asset Upload:</p>
                {
                    isLoading ?
                        <div className="flex flex-col gap-y-2">
                            <Skeleton height={"15px"} />
                            <Skeleton height={"15px"} />
                            <Skeleton height={"15px"} />
                            <Skeleton height={"15px"} />
                        </div>
                        :
                        data?.map((item: IBrochureAssetQuery, idx) => {
                            return (
                                <div key={idx}>
                                    <VariantDropDown {...item} />
                                </div>
                            )
                        })
                }
            </div>
        </div>
    )
}

export default BrochureAssetUploader;
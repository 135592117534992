import { flattenObj } from "./miscUtils"

export const filterDataByExact = (data: Array<{ [fKey: string]: any }>, key: any, value: any) => {
    
    try {
        return data.filter((d) => {
            return (d[key]).toString() === value
        })
    } catch (err) {
        console.log(err)
        return data
    }

}

export const filterDataBySimilar = (data: Array<{ [fKey: string]: any }>, key: any, value: any) => {
    try {
        return data.filter((d) => {
            const keyData = d?.[key] || ""
            if (typeof(keyData) === "string") {
                return keyData.toLowerCase()?.includes(value?.toLowerCase())
            } else if(typeof(keyData) === "number") {
                return keyData.toString().toLowerCase()?.includes(value?.toLowerCase())
            } else if(typeof(keyData) === "object"){
                return keyData.includes(value)
            }
        })
    } catch (err) {
        console.log(err)
        return data
    }
}

export const searchData = (data: Array<{ [fKey: string]: any }>, columns: any, value: any) => {

    try {
        return data.filter((d) => {
            let dataRow = flattenObj(d)
            for (let col in columns) {
                let column = columns[col]
                if (typeof (dataRow?.[column]) === "string") {
                    if (dataRow?.[column]?.toString().toLowerCase()?.includes(value)) {
                        return true;
                    }
                } else {
                    if (dataRow?.[column]?.toString().includes(value)) {
                        return true;
                    }
                }
            }
            return false;
        })
    } catch (err) {
        console.log(err)
        return false
    }

}
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { BiSearchAlt } from 'react-icons/bi';
import { MdExpandMore } from 'react-icons/md';
import { useOnClickOutside } from '../../hooks/utilHooks/clickOutsideHook';
import { FormInputChange } from '../Form';

export type TSelectSearchDropdown = {
    value: string | number | readonly string[] | undefined,
    label: string,
    title? : string,
    path?: string,
    queryNameList?: string
}

export interface ISelectSearchDropdown {
    keyName: string,
    primitiveDataType: string,
    formComponent: {
        type: "select-search",
        label: string,
        placeholder: string,
        options: TSelectSearchDropdown[],
        disabled?: boolean,
        required?: boolean,
    },
    defaultValue?: string | number | readonly string[] | undefined,
    error?:any
}


const SelectSearchField = ({ handleInputChange, keyName, formComponent , defaultValue , error }: ISelectSearchDropdown & FormInputChange) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedOption, setSelectedOption] = useState<number>(-1);

    const dropdownMenuRef = useRef(null)
    const selectDisplayRef = useRef(null)

    useOnClickOutside(dropdownMenuRef, () => setShowDropdown(false), selectDisplayRef)

    const searched = useMemo(() => {

        let results = formComponent.options?.filter((option) => option.value?.toString().toLowerCase().includes(searchQuery)) || []
        if(results.length === 0) results = formComponent.options?.filter((option) => option.label.toLowerCase().includes(searchQuery)) || []

        return results
    }, [searchQuery])

    useEffect(() => {
        if (defaultValue) {
            formComponent.options.find((item: any, idx: number) => {
                if (item.value === defaultValue) {
                    setSelectedOption(idx)
                }
            })}
    },[defaultValue])

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const onSelectOption = (selectedIdx: number) => {
        setSelectedOption(selectedIdx);
        setShowDropdown(previous => !previous);
        handleInputChange?.(keyName, searched?.[selectedIdx] || "")
    };


    const onClickDropdown = () => {
        setShowDropdown(previous => !previous);
        setSearchQuery("")
    }
    //if search input 
    return (
        <div key={keyName} className={`relative`}>

            {/* Button for Dropdown */}
            <label htmlFor={keyName} className="block mb-2 text-sm font-medium text-gray-900 pt-5">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
            <button ref={selectDisplayRef} type="button" className={`inline-flex justify-between bg-gray-50 border ${error ? `border-red-500`: `border-gray-300`} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5`}
                onClick={onClickDropdown}>
                <>
                    {/* Display Names */}
                    <p>{searched?.[selectedOption]?.value || formComponent.placeholder}</p>
                    <MdExpandMore color="gray" />
                </>
            </button>

            {showDropdown ?
                <div ref={dropdownMenuRef} className="absolute z-30 w-full">
                    {/* Show Dropdown */}
                    <div className="pt-1">
                        <div className="relative">
                            {/* Search Input */}
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BiSearchAlt color="gray" />
                            </div>
                            <input type="text"
                                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                                value={searchQuery}
                                required={formComponent.required}
                                onChange={onSearchChange}
                                placeholder={formComponent.placeholder} />
                        </div>
                    </div>
                    {/*DropDown Options */}
                    <ul className="pt-2 border border-gray-500 bg-gray-100 overflow-y-scroll overflow-x-hidden max-h-64 text-sm text-gray-700 cursor-pointer">
                        {
                            searched?.map((option, idx) => (
                                <li key={idx} value={option.value} className="pl-2.5 hover:bg-blue-500" onClick={() => onSelectOption(idx)}>
                                    {option.label}
                                </li>
                            ))
                        }
                    </ul>
                </div>
                : <></>
            }
        </div>
    )
}

export default SelectSearchField;
import { BiDownload } from "react-icons/bi";
import { CSVLink } from "react-csv";
import { useState, useRef, useEffect } from "react";
import { useAlertToastHook } from "../../hooks/alertToastHook";
import { RxCross2 } from "react-icons/rx";
import filesize from 'filesize';
import * as XLSX from "xlsx";
import { utils } from "xlsx";
import { v4 as uuidv4 } from 'uuid';
import DragAndDropFile from "../../components/DragAndDropFile";
import { SpinnerLoader } from "../../components/Loader";

export interface BatchHeader {
    label: string;
    key: string;
    type: string;
    description: string;
    example?: string;
    required?: boolean;
}

interface BatchUploadProps {
    headers: BatchHeader[];
    page: number;
    setPage: Function;
    setBatchData: Function;
    dataType: string;
    pageArray: Array<number>;
}


export const UploadSteps = () => {
    return (
        <div className='break-before text-sm flex flex-col gap-2'>
            <p>1. Download the Comma-Separated Value {`(`}CSV{')'} template file provided and populate it.</p>
            <p>2. When completed, click the "Click here to upload" button or Drag and Drop file to select the completed file.</p>
            <p>3. Click the "Upload" button to start the validation process.</p>
            <p>4. The system will process the file and provide you with a preview of the data to be uploaded</p>
            <p>5. If any errors or warnings are detected during the validation process, you will be notified, and you can make the necessary changes before submitting your data.</p>
            <p>6. Click the "Submit" button to upload your data to the database.</p>
        </div>
    )
}

// Page that will take in csv/excel file for batch upload
const BatchUploadFileUploader = ({ headers, page, setBatchData, setPage, dataType, pageArray }: BatchUploadProps) => {
    const [file, setFile] = useState<File | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { addMessage } = useAlertToastHook();
    const [validHeader, setValidHeader] = useState<Array<string>>([]);
    const [loading, setLoading] = useState(false);



    const tableHeader = ["Data Item", "Requirement", "Data Purpose", "Example Input"]

    useEffect(() => {
        setValidHeader(headers.map(header => header.label))
    }, [headers])

    //handle cancel button click
    const handleFileCancel = () => {
        setFile(null);
    };

    //modify the data to to key_name instead of Name
    function dataProcessor(processData: Array<any>) {
        let processedData = processData.map((item) => {
            let newItem: any = [];


            headers.forEach((header) => {
                if (header.type === "boolean" && typeof item[header.label] === "string") {
                    newItem.push(item[header.label].toLowerCase() === "true" ? true : false);
                    return;
                }
                if (header.key === "person.country_code" && typeof item[header.label] === "number") {
                    newItem.push("+" + item[header.label].toString());
                    return;
                }
                else if (header.key === "person.phone_number" && typeof item[header.label] === "number") {
                    newItem.push(item[header.label].toString());
                    return;
                }
                if (typeof item[header.label] === "string" && item[header.label]?.includes("\"")) {
                    newItem.push(item[header.label].replaceAll("\"", ""))
                }
                else if (typeof item[header.label] === "string" && item[header.label] === "") {
                    newItem.push("");
                }
                else {
                    newItem.push(item[header.label])
                }
            })
            return newItem;
        });

        return processedData;
    }

    const excelCheck = () => {
        if (!file) return (addMessage("Please upload a file first", false));
        setLoading(true);
        const reader = new FileReader();
        let timer = 0;

        reader.onloadstart = () => {
            console.log("Start Loading file...");
            timer = new Date().getTime();
        }
        reader.onload = (e: ProgressEvent<FileReader>) => {
            console.log("Starting to Read")
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: "string" });     
            console.log("Read Complete")                                              //read the file
            const ws = workbook.Sheets[workbook.SheetNames[0]];                                                     //get the first sheet
            const getHeader: any[][] = utils.sheet_to_json(ws, { header: 1 });                                      //get the header row
            const invalidHeaders = getHeader[0]?.filter(headerFields => !validHeader.includes(headerFields)) || []; //check if the headers are valid
            const invalid = validHeader.filter(header => !getHeader[0].includes(header));                           //check if the headers are missing

            if (invalidHeaders?.length > 0) {
                addMessage(`Invalid headers found: ${invalidHeaders.join(', ')}`, false);
                return;
            }
            if (invalid?.length > 0) {
                addMessage(`Missing headers: ${invalid.join(', ')}`, false);
                return;
            }
            else {
                const jsonObj = utils.sheet_to_json(ws);
                console.log("jsonObj: ", jsonObj)
                setBatchData(dataProcessor(jsonObj));
                addMessage(`All headers are valid`, true);
                setPage(pageArray[pageArray.indexOf(page) + 1]);
                return;
            }
        };
        reader.onloadend = () => {
            setLoading(false);
            let end = new Date().getTime();
            console.log("time taken: ", (end - timer) / 1000, "seconds");
        }
        reader.readAsText(file , "UTF-8");
    }

    //header checker
    const handleHeaderCheck = () => {
        if (!file) return (addMessage("Please upload a file first", false));
        console.log("file: " , file)

        // if file name is excel, convert it into a csv file

        excelCheck();
        // csvCheck();
        // readFile({file});
    }

    function DownloadCSV() {
        return (
            <>
                <div className="pt-2">
                    <button className='bg-blue-700 text-white p-2 hover:bg-blue-500 flex flex-row gap-2'>
                        <div className="pt-0.5">
                            <BiDownload color="white" size="20px" />
                        </div>
                        <CSVLink data={[]} headers={headers} filename={"SSP_Sample_Template_" + dataType + ".csv"}>
                            Sample {dataType} CSV
                        </CSVLink>
                    </button>
                </div>
                <div className="pt-2 overflow-x-auto p">
                    <p className="font-semibold">To create the data, you need to upload a csv file with the follwing account data</p>
                    <p className="font-semibold text-red-600 text-xs">* Please keep the columns of image type to be empty</p>
                    <table className="w-full pt-2">
                        <thead>
                            <tr>
                                {
                                    tableHeader.map((header, index) => {
                                        return (
                                            <th key={uuidv4() + index.toString()} className=
                                                "text-left border border-gray-800 p-2 min-w-full bg-blue-400">{header}</th>
                                        )
                                    }
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                headers.map((header, index) => {
                                    return (
                                        <tr>
                                            <td key={uuidv4() + index.toString()} className="text-left break-before-auto border border-gray-800 p-2 align-top">{header.label}</td>
                                            <td key={uuidv4() + index.toString()} className={`text-left break-before-auto border border-gray-800 p-2 align-top font-bold ${header.required ? `text-green-600` : `text-red-600`}`}>{header.required ? "Compulsory" : "Optional"}</td>
                                            <td key={uuidv4() + index.toString()} className="text-left break-before-auto border border-gray-800 p-2 align-top">{header.description}</td>
                                            <td key={uuidv4() + index.toString()} className="text-left break-before-auto border border-gray-800 p-2 align-top">{header?.example || ""}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )

    }

    return (
        <div className='bg-gray-100 text-left px-3 pb-3 pt-6 h-full text-sm'>
            {
                !loading ?
                    <div>
                        <h1 className=" capitalize font-bold pl-2">upload data</h1>
                        <p className='text-sm pl-2 pb-2'>Follow the simple steps below to get started:</p>
                        <div className=" bg-[#C8D9F0] rounded-md p-2 border border-[#A7C7E7]">
                            {/* <p className="pb-2 font-bold text-[#3D426B] text-md">Batch Upload</p>
                    <UploadSteps /> */}
                            <p className="text-[#3D426B] font-bold pt-2">#1 Download Sample CSV Template</p>
                            <DownloadCSV />
                            <div className="flex flex-col">
                                <p className="text-[#3D426B] font-bold pt-2">#2 Upload Completed CSV File For Validation</p>
                                <p className=" text-red-600 text-xs">Warning : Duration of validating .xls or .xlsx file might take longer than .csv file.</p>
                                <DragAndDropFile setFile={setFile} validTypes={['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']} validTypeMessage="We support only .csv , .xls and .xlsx files" accept=".csv, .xlsx , .xls " />
                                {
                                    file && (
                                        <div className="pt-2 rounded-md">
                                            <p className="bg-slate-50 px-2 py-1">Uploaded File:</p>
                                            <div className="bg-slate-50 flex flex-row justify-between pr-3 h-8 border border-[#3D426B]">
                                                <p className="pl-2 w-full text-left pt-1">{file.name} {`(`}{filesize(file.size)}{`)`}</p>
                                                <button onClick={handleFileCancel} title="Cancel selection"><RxCross2 size="16px" /></button>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="pt-2">
                                    <button className={`${!loading ? `bg-blue-700 text-white px-2 py-1 hover:bg-blue-500` : `animate-pulse bg-gray-500 text-white px-2 py-1`}`} onClick={handleHeaderCheck}>Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="flex flex-col h-screen justify-items-center">
                        <div className=" h-fit place-items-center my-auto">
                            <SpinnerLoader/>
                            <p className="text-center">Uploading file...</p>
                        </div>
                    </div>
            }
        </div >
    )
}

export default BatchUploadFileUploader;
import { create } from 'zustand'

interface IScrollLockStore {
  lockScrollDict: { [key: string]: boolean },
  addScrollLock: (key: string) => void,
  removeScrollLock: (key: string) => void
}

export const useScrollLockStore = create<IScrollLockStore>((set) => ({
  lockScrollDict: {},
  addScrollLock: (key: string) => set((state) => ({ lockScrollDict: { ...state.lockScrollDict, [key]: true } })),
  removeScrollLock: (key: string) => set((state) => {
    const { [key]: _, ...rest } = state.lockScrollDict
    return { lockScrollDict: rest }
  })
}))
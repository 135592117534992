import { useEffect, useState } from "react"
import { useFetchFileDirectory } from "./fileDirectoryHook"
import { AiFillFolder, AiOutlineFile } from "react-icons/ai"
import Skeleton from "react-loading-skeleton"
import 'react-loading-skeleton/dist/skeleton.css'
import { AssetUploader } from "./AssetUploader"
import { isValidMediaFile } from "./AssetHelper"
import { BiRefresh } from "react-icons/bi"
import Loading from "../../components/Loader"
import { DirectoryPathSelector } from "./DirectoryPathSelector"
import { AssetSearch } from "./AssetSearch"

export const ImageWrapper = ({ imageUrl }: { imageUrl: string }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)

  const imageStyle = {
    width: "64px", height: "64px"
  }

  return (
    <div className="w-16 h-16 text-3xl flex items-center relative">
      {
        (!isLoaded && hasError) &&
        <Skeleton containerClassName="flex-1" style={imageStyle} className="absolute top-0" />
      }
      {
        hasError ?
          <AiOutlineFile className="" />
          :
          <div className="w-16 h-16">
            <img className="top-0 object-scale-down bg-white absolute rounded-md" style={{ ...imageStyle, display: isLoaded ? 'block' : 'none' }} src={imageUrl} alt="" onLoad={() => {
              setIsLoaded(true)
            }} onError={(e) => {
              setIsLoaded(true)
              setHasError(true)
            }} />
          </div>

      }
    </div>
  )
}

export const AssetManagementLibrary = () => {

  const [currentDirectory, setCurrentDirectory] = useState<string>("/")

  const { isLoading, data, error, refreshDirectory } = useFetchFileDirectory(currentDirectory)

  useEffect(() => {
    refreshDirectory()
  }, [currentDirectory])

  const handleDirectoryClick = (directory: string) => {
    let newDirectory = currentDirectory + directory

    if (newDirectory.startsWith("/"))
      newDirectory = newDirectory.slice(1)

    setCurrentDirectory(newDirectory)
  }

  const handleDirectoryBack = () => {
    if (currentDirectory === "" || currentDirectory === "/") return
    const splitDirectory = currentDirectory.split("/")

    if (splitDirectory[splitDirectory.length - 1] === "") splitDirectory.pop()

    splitDirectory.pop()
    setCurrentDirectory(`${splitDirectory.join("/")}/`)
  }

  const displayFolderName = (folderPath: string) => {

    const cutOffLength = 20
    const splitPath = folderPath.split("/")
    const name = splitPath[splitPath.length - 1] === "" ? splitPath[splitPath.length - 2] : splitPath[splitPath.length - 1]

    if (name.length > cutOffLength) {
      return name.slice(0, cutOffLength) + "..."
    } else {
      return name
    }
  }

  return (
    <div className="fixed w-80 right-0 border-l p-4 h-[100vh] flex flex-col">

      <AssetSearch />

      {/* Directory Paths */}
      <div className="flex my-2 gap-2 items-center">
        <button onClick={refreshDirectory} className="self-start rounded-md shadow py-1 px-3 w-fit  h-10"><BiRefresh /></button>
        <button onClick={handleDirectoryBack} className="self-start rounded-md shadow py-1 px-3 w-fit  h-10">..</button>
        <DirectoryPathSelector currentDirectory={currentDirectory} setCurrentDirectory={setCurrentDirectory} />
      </div>
      <AssetUploader currentDirectory={currentDirectory} refreshDirectory={refreshDirectory} />
      {/* Border line */}
      <div className="border-b w-full my-2" />
      {/* Main Directory files */}
      <div className="flex flex-col gap-2 overflow-auto h-full py-6">
        {
          isLoading && <Loading />
        }
        {
          !error && !isLoading && data &&
          data.map((item, idx) => {
            if (!isValidMediaFile(item.url) && item.type === "file") {
              return (<div key={idx} />)
            }
            return (
              <button className="flex flex-row w-fit items-center align-middle gap-2" key={idx} onClick={() => {
                if (item.type === "folder") {
                  handleDirectoryClick(item.name)
                } else {
                  window.open(item.url, "_blank")
                }
              }}>
                {/* Folder and file */}
                {
                  item.type === "file" ?
                    <ImageWrapper imageUrl={item.url} /> :
                    <AiFillFolder />
                }
                {/* File/Folder Name */}
                <p className="text-xs">{displayFolderName(item.name)}</p>
              </button>
            )
          })
        }
      </div>
    </div>

  )
}
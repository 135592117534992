import { IDropdownOption } from "./DropdownSearchField";
import { FormInputChange } from "../Form";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineDown } from "react-icons/ai";
import { useEffect, useState } from "react";

export interface ISelectDropdown {
  keyName: string,
  primitiveDataType: string,
  formComponent: {
    type: "select",
    label: string,
    placeholder: string,
    options: IDropdownOption[],
    disabled?: boolean,
    required?: boolean,
  }
  defaultValue?: string | number | readonly string[] | undefined | {[key:string]:any},
  error?: any
}

// id, label, required, value, defaultValue, disabled, onChange, options
const SelectDropdown = ({ keyName, handleInputChange, formComponent, defaultValue, error }: ISelectDropdown & FormInputChange) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [displayedSelectValue , setDisplayedSelectValue] = useState<string>(formComponent.placeholder)

  useEffect(()=>{
    // check if defaultValue is in options, it is the value .... find the index and set the display as the label instead
    if(defaultValue){
      const foundOption = formComponent.options?.find(option => option.value === defaultValue)
      setDisplayedSelectValue(foundOption?.label || formComponent.placeholder)
    }
  },[defaultValue])
  
  const handleSelect =(option:IDropdownOption)=>{
    handleInputChange(keyName, option.value); 
    setDisplayedSelectValue(option.label|| formComponent.placeholder)
    setOpenDropdown(false)
  }
  

  //if Select dropdown input
  return (
    <div key={keyName}>
      <label htmlFor={keyName} className="block mb-2 text-sm font-medium text-gray-900 pt-5">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
      {/*Dropdown Button*/}
      <div className={`bg-gray-50 border ${error ? `border-red-500` : `border-gray-300`} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} key={keyName}>
        <button type="button" className="w-full flex flex-row justify-between" onClick={() => { setOpenDropdown(previous => !previous) }}>
          <p>{displayedSelectValue}</p>
          <div className="flex items-center">
            <AiOutlineDown />
          </div>
        </button>
      </div>
      {
        openDropdown &&
        <ul className="border border-black bg-white">
          {formComponent.options?.map((option, optionIndex) => (
            <li key={optionIndex} className="hover:bg-blue-400 hover:text-white">
              <button type="button" className="w-full h-full text-left px-2" onClick={()=>{handleSelect(option)}}>
                {option.label}
              </button>
            </li>

          ))}
        </ul>
      }
      {
        error &&
        <div className="flex flex-row gap-x-1 items-center">
          <div className="pt-1">
            <BiErrorCircle color="red" />
          </div>
          <p className="text-red-600 text-sm pt-1">{error}</p>
        </div>
      }

    </div>
  )
}
export default SelectDropdown;

/**
 *         defaultValue={defaultValue}
        required={formComponent.required}
        disabled={formComponent.disabled}
 */

  {/* <select
        placeholder={formComponent.placeholder}
        className={`bg-gray-50 border ${error ? `border-red-500` : `border-gray-300`} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
        id={keyName}
        name={keyName}
        defaultValue={defaultValue}
        required={formComponent.required}
        disabled={formComponent.disabled}
        onChange={(e) => {
          console.log("target: ",e.target.value)
          handleInputChange(keyName, e.target.value);
        }}
      >
        {formComponent.options?.map((option, optionIndex) => (
            <option key={optionIndex} 
            value={option.value} 
            selected={defaultValue===option.value}>
              {option.label}
            </option>

        ))}
      </select> */}
import { useState } from 'react'
import { useSearchFile } from './fileDirectoryHook'
import { AiOutlineSearch } from 'react-icons/ai'
import { RxCross1 } from 'react-icons/rx'
import { ImageWrapper } from './AssetManagementLibrary'
import Skeleton from 'react-loading-skeleton'
import { GrDocumentPdf } from 'react-icons/gr'
import { isImageLink } from '../../utils/miscUtils'

const SearchLoading = () => {

  const count = [0, 0, 0]

  return (
    <div className='flex flex-col gap-1'>
      {
        count.map((item, idx) => {
          return (
            <div key={idx} className='flex gap-4 w-full items-center self-start'>
              <Skeleton style={{ width: "60px", height: "60px" }} />
              <Skeleton className='w-[50%]' containerClassName="flex-1" />
            </div>
          )
        })
      }
    </div>
  )
}

const SearchFileList = ({ searchFileData, searchLoading }:
  {
    searchFileData: {
      name: string,
      url: string
    }[],
    searchLoading: boolean
  }
) => {

  return (
    <div className="absolute z-10 top-12 overflow-y-auto h-[100vh] w-full bg-white">
      {
        searchLoading ? <SearchLoading /> :
          searchFileData.map((item, idx) => {
            return (
              <div key={idx} className="text-left flex items-center justify-items-center gap-3 cursor-pointer" onClick={() => {
                window.open(item.url, "_blank")
              }}>
                {
                  isImageLink(item.url) ? <ImageWrapper imageUrl={item.url} /> : <GrDocumentPdf className="w-16 h-16 text-3xl" />
                }
                <p className="text-xs">{item.name}</p>
              </div>
            )
          })
      }
    </div>
  )
}

export const AssetSearch = () => {

  const [searchFile, setSearchFile] = useState<string>("")

  const { data: searchFileData, isLoading: searchLoading, fetchSearchDirectory } = useSearchFile(searchFile)

  const handleSearch = () => {
    if (searchFile !== "") {
      fetchSearchDirectory()
    }
  }

  // Enables enter key to search
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <div className='flex flex-col relative w-full'>
      <div className="flex gap-3 w-full">
        {/* Search Bar */}
        <div className="flex w-full relative">
          <div className='relative w-full' onKeyDown={handleKeyDown} >
            <input type="text" className="w-full border focus:border rounded-l-md p-2" value={searchFile} onChange={(e) => {
              setSearchFile(e.target.value)
            }} />

            {/* Clear Search */}
            <button onClick={() => {
              setSearchFile("")
            }} className="absolute right-3 h-fit mt-[13px]" style={{ display: searchFile === "" ? "none" : "inline-block" }}><RxCross1 /></button>
          </div>
          <button className='h-full px-3 rounded-r-md border bg-slate-200' onClick={handleSearch}><AiOutlineSearch className="" /></button>
        </div>

      </div>
      {/* Search Result List */}
      {
        searchFile !== "" &&
        <SearchFileList searchFileData={searchFileData} searchLoading={searchLoading} />
      }
    </div>

  )
}
import React, { useState } from 'react';
import CheckBox, { ICheckbox } from './formComponents/CheckboxField';
import DisplayImage, { IDisplayImage } from './DisplayImage';
import DropdownRelationalSearch, { IDropdownRelationalSearchProps } from './formComponents/DropdownSearchField';
import FileUploaderField, { IFileUploadField } from './formComponents/FileUploaderField';
import JsonField, { IJsonField } from './formComponents/JSONField';
import SelectDropdown, { ISelectDropdown } from './formComponents/SelectDropdownField';
import TextInput, { ITextInput } from './formComponents/TextInputField';
import SelectSearchField, { ISelectSearchDropdown } from './formComponents/SelectSearchField';
import RadioDropdown, { IRadioDropdown } from './formComponents/RadioDropdown';
import DependableSelectField, { IDependableSelect } from './formComponents/DependableSelectField';
import MultipleFormFieldWrapper, { IMultipleFormFieldWrapperProps } from './wrappers/MultipleFormFieldWrapper';
import CardField, { ICard } from './formComponents/CardField';
import NestedForm from './formComponents/NestedForm';
import CheckBoxForm from './CheckBoxForm';

export type FormInputChange = { handleInputChange: (key: string, value: any) => void }

export type FormInputs = IDropdownRelationalSearchProps | IFileUploadField | ISelectDropdown | ICheckbox | IDisplayImage | ITextInput | IJsonField | ISelectSearchDropdown | IRadioDropdown | IDependableSelect | IMultipleFormFieldWrapperProps | ICard;

interface FormTemplateProps {
  inputFieldsParams: Array<FormInputs>;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  defaultValues?: Array<any>
  onChange: (keyName: string, value: any) => void
  errors?: Array<any>
  pathName: string
  action: string
}

export const obtainFormComponentType = (formComponent: FormInputs, onChange: (keyName: string, value: any) => void, defaultInput?: any, nested?: boolean, nestedIndex?: number, errorMessage?: any) => {

  const typeOfComponent = formComponent?.formComponent?.type || ""
  const idx = formComponent.keyName

  switch (typeOfComponent) {
    case "dropdown-search":
      const dropDownInput = formComponent as IDropdownRelationalSearchProps;
      return <DropdownRelationalSearch key={idx} {...dropDownInput} defaultId={defaultInput?.defaultValue} handleInputChange={onChange} error={errorMessage} />
    case "file":
      const fileInput = formComponent as IFileUploadField;
      return <FileUploaderField key={idx} {...fileInput} handleInputChange={onChange} defaultValue={defaultInput?.defaultValue} error={errorMessage} />
    case "select":
      const selectInput = formComponent as ISelectDropdown;
      return <SelectDropdown key={idx} {...selectInput} defaultValue={defaultInput?.defaultValue} handleInputChange={onChange} error={errorMessage} />
    case "multiple-select":
      const multipleSelectInput = formComponent as IDependableSelect;
      return <DependableSelectField key={idx} {...multipleSelectInput} defaultValue={defaultInput?.defaultValue} handleInputChange={onChange} nestedIndex={nestedIndex} />
    case "checkbox":
      const checkBoxInput = formComponent as ICheckbox;
      return <CheckBox key={idx} {...checkBoxInput} checked={defaultInput?.defaultValue} handleInputChange={onChange} error={errorMessage} />
    case "image":
      const displayImageInput = formComponent as IDisplayImage;
      return <DisplayImage key={idx} {...displayImageInput} value={defaultInput?.defaultValue} error={errorMessage} />
    case "json":
      const jsonInput = formComponent as IJsonField;
      return <JsonField key={idx} {...jsonInput} defaultValue={defaultInput?.defaultValue} handleInputChange={onChange} error={errorMessage} />
    case "select-search":
      const selectSearchInput = formComponent as ISelectSearchDropdown;
      return <SelectSearchField key={idx} {...selectSearchInput} defaultValue={defaultInput?.defaultValue} handleInputChange={onChange} error={errorMessage} />
    case "radio":
      const radioInput = formComponent as IRadioDropdown;
      return <RadioDropdown key={idx} {...radioInput} handleInputChange={onChange} />
    case "card":
      const cardInput = formComponent as ICard;
      return <CardField key={idx} {...cardInput} handleInputChange={onChange} defaultValue={defaultInput?.defaultValue} />
    case "multiple-form":
      const multipleFormInput = formComponent as any;
      return <MultipleFormFieldWrapper key={idx} {...multipleFormInput} defaultValue={defaultInput?.defaultValue} handleInputChange={onChange} error={errorMessage} />
    default:
      const textInput = formComponent as ITextInput;
      return <TextInput key={idx} {...textInput} defaultValue={defaultInput?.defaultValue} handleInputChange={onChange} error={errorMessage} nested={nested} />
  }
}


const FormTemplate = ({ inputFieldsParams, onSubmit, defaultValues, onChange, errors, pathName , action }: FormTemplateProps) => {

  const [colourSelection , setColourSelection ]  = useState<Array<any>>([])
  const [bodyConfiguration , setBodyConfiguration] = useState<{[key:string]:any}>({})
  const [showSummaryBlockMeta , setShowSummaryBlockMeta] = useState<boolean>(true)

  const vehicleField: Array<FormInputs> = [
    {
      keyName: "vehicle.make",
      primitiveDataType: "string",
      formComponent: {
        type: "text",
        inputType: "text",
        label: "Make",
        placeholder: "Enter Vehicle Make",
        required: true
      }
    },
    {
      keyName: "vehicle.model",
      primitiveDataType: "string",
      formComponent: {
        type: "text",
        inputType: "text",
        label: "Model",
        placeholder: "Enter Vehicle Model",
        required: true
      }
    },
    {
      keyName: "vehicle.year",
      primitiveDataType: "number",
      formComponent: {
        type: "text",
        inputType: "number float",
        label: "Year",
        placeholder: "Enter Vehicle Year",
        required: true
      }
    },
    {
      keyName: "vehicle_variant.name",
      primitiveDataType: "string",
      formComponent: {
        type: "text",
        inputType: "text",
        label: "Variant",
        placeholder: "Enter Vehicle Variant Name",
        required: true
      }
    }
  ]

  const setConfiguration = (newConfigs:{
    configuration: Array<any>,
    summaryBlockMeta: any
  })=> {
    setBodyConfiguration(newConfigs)
    // if the summary block meta is not null or empty array then we will hide the summary block meta and take the existing one
    if(newConfigs.summaryBlockMeta!==null && newConfigs.summaryBlockMeta!=="[]"){
      setShowSummaryBlockMeta(false)
    }
    else{
      setShowSummaryBlockMeta(true)
    }
    setColourSelection(newConfigs.configuration)
  }
  
  return (
    <form>
      <div className="mb-6 text-left flex flex-col px-4">
        {
          pathName === "new-vehicle" && action === "created" &&
          <NestedForm keyName='vehicleDetails' formComponent={{
            label: "Vehicle Details",
            type: "nested-form",
            required: true,
            pathName: "vehicle?dealershipGroupId=3",
            queryName: "vehicleList",
            pageName: "Vehicle",
            transformMapper: {
              title: ["vehicle.make", "vehicle.model", "name"],
              imageUrl: [],
              description: ["vehicle.make", "vehicle.model", "vehicle.year", "name"],
              value: ["id", "vehicle.make", "vehicle.model", "vehicle.year", "name" , "summary_block_meta"]
            },
            nestedCreateFields: vehicleField
          }} handleInputChange={onChange} primitiveDataType={'string'} setData={setConfiguration} />
        }
        {
          inputFieldsParams.map((fieldParams, idx) => {
            const defaultInput = defaultValues?.[idx]
            const errorMessage = errors?.[idx]  
            if(fieldParams.keyName==="summaryBlockMeta" && showSummaryBlockMeta===false){
              return
            }    
            return obtainFormComponentType(fieldParams, onChange, defaultInput, undefined, undefined, errorMessage)
          })
        }
        {
          pathName === "new-vehicle" && action==="created" &&
          <CheckBoxForm handleInputChange={onChange} configNames={colourSelection || []} />
        }
      </div>
      <div>
        <div className="border-t border-gray-500 w-full py-2 px-2" />
        <div className="flex justify-center">
          <button onClick={(e) => {
            if (onSubmit) {
              onSubmit(e as any)
            }
          }} type="submit" className="text-gray-100 bg-gray-800 shadow-md hover:bg-blue-800 
                  rounded-lg text-sm w-full sm:w-auto p-1 text-center" >
            {"Submit"}
          </button>
        </div>
      </div>
    </form>

  );
};

export default FormTemplate;
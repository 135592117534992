import { useScrollLockStore } from "../../stateManagement/scrollState"
import { useEffect } from 'react'

export const useScrollBodyLock = () => {

    const { lockScrollDict, removeScrollLock, addScrollLock } = useScrollLockStore()

    const lockPageScroll = ()=> {
        addScrollLock("page")
    }
    const unlockPageScroll = ()=> {
        removeScrollLock("page")
    }
    
    useEffect(()=> {
        if(lockScrollDict?.page === true){
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "visible"
        }
    }, [lockScrollDict])

    const getScrollLockClassName = (keyName:string)=> {
        return lockScrollDict?.[keyName] === true ? "overflow-y-hidden" : "overflow-y-auto"
    }

    return ({ lockPageScroll, unlockPageScroll, removeScrollLock, addScrollLock, getScrollLockClassName })
}
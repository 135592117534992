import { useNavigate } from "react-router";
import { useSequentialHook } from "../hooks/sequentialFileHook";
import { AiOutlineDoubleRight } from "react-icons/ai";

const ClickableStepper = ({ currentTitle }: { currentTitle: string }) => {
    const { pageList, removePage } = useSequentialHook();
    const navigate = useNavigate();


    const pageListRemove = (index: number) => {
        // if back -1 , 
        // page list == 3
        // last item == 2 , remove 2

        // if back -2
        // page list == 3
        // last item == 1 , remove 2 and 1

        let indexReturn = index - pageList.length
        const startIndex = pageList.length - 1

        for (let i = 0; i > indexReturn; i--) {
            removePage(startIndex + i)
        }
        navigate(index - pageList.length)
    }

    return (
        <div className="flex flex-row gap-x-2">
            {
                pageList?.map((item, index) => {
                    return (
                        <div className="flex flex-row gap-x-1" key={ index}>
                            <button className="flex mx-auto text-blue-800 hover:text-blue-500"
                                onClick={() => {/*navigate(index-pageList.length); removePage(pageList.length-1)*/ pageListRemove(index) }}>
                                    {item.pageName}
                                </button>
                                <div className="mt-1"><AiOutlineDoubleRight /></div>
                        </div>
                    )
                })
            }
            <p>{currentTitle}</p>
        </div>
    )
};

export default ClickableStepper;
import React, { useEffect, useMemo, useState } from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { createObj, deleteObj, fetchAll, updateObj } from "../api/manageAPI";
import { useCreateSingle, useDeleteSingle, useFetchList, useUpdateSingle } from "../hooks/crudHook";
import { useSelectedRecordHook } from "../hooks/selectedRecordHook";
import { useScrollBodyLock } from "../hooks/utilHooks/scrollLockHook";
import DataManipulationBar from "./filterDataComponents/DataManipulationBar";
import Delete from "./Delete";
import DisplayTable, { DisplayTableRows, HeaderCell } from "./DisplayTable";
import ResetPassword from "./ResetPassword";
import TableRelationalDisplay from "./TableRelationalDisplay";
import Tooltip from "./Tooltip";
import SideBarWrapper from "./wrappers/SideBarWrapper";
import { ActionCell, ResetDelete, TableCell } from "./CrudHelper";
import { flattenData } from "../utils/miscUtils";
import CreateUpdate from "./CreateUpdate";
import { BatchConfigObj } from "../pages/BatchUpload/BatchUploadMaster";
import { useSequentialHook } from "../hooks/sequentialFileHook";
import ClickableStepper from "./ClickableStepper";
import PopUpWrapper from "./wrappers/PopUpWrapper";
import BrochureAssetUploader from "../pages/BrochureAssetUploader/BrochureAssetUploader";
import { featureSwitches } from "../config/featureSwitch";

export interface IConfig<T> {
    pathName: string,
    queryNameList: string,
    crud: ICRUD<T>,
    bulk: BatchConfigObj
}

interface ICRUD<T> {
    dataFields: Array<string>,              //Fields for display table
    title: string,                          //Title for CRUD Page
    tooltip: TToolTip,                      //Tooltip for CRUD Page
    table: TTable,                          //Table for CRUD Page
    create: TCreateUpdate<T>,               //Create form for CRUD Page
    update: TCreateUpdate<T>,               //Update form for CRUD Page
    grid?: TGridDisplay,                    //Grid for CRUD Page
    nestedInputs?: nestedProps,             //Fields for nested layout
    queryParameters?: Array<string>,        //Query parameters for axios api url
    excludeFields?: Array<string>,          //Fields to exclude from display table
}

type TCreateUpdate<T> = {
    field: Array<any>,                      //Fields for create/update form
    formData: T | (() => T) | any,         //Form data for create/update form
}

type TToolTip = {
    title: string,                          //Title for tooltip   
    description: Array<string>              //Description for tooltip
}

type TTable = {
    field: Array<TableProps>               //Fields for display table
}

type TGridDisplay = {
    gridDisplay: boolean,                   //For grid layout
    gridInputs: TGrid,                      //Fields for grid layout
}

export interface nestedProps {
    pathName: string,
    queryParameter: string,
}

export interface TableProps {
    header: string;
    dataType: string;
    dataFieldName: string;
    relationalTable?: {
        pathName: string;
        queryName: string;
        idFieldName: string;
    }
    nestedDataField?: string;
    resetPassword?: boolean;
}

export type TGrid = {
    fieldsToHide?: Array<string>
    imageField?: string
    dealershipId?: number
}

export type ResetDataType = {
    resetPathName?: string
}

export type RelationDataType = {
    displayTable: boolean,
    id: number,
    queryName: string,                      //QueryNameList for invalidating query
    pathName: string                        //Path for axios api url
}


export type SortSettingType = {
    [sortColumn: string]: "asc" | "desc"
}

type TCrudProps<T> = {
    header: string,
    fields: Array<any>,
    query: (obj: T) => Promise<T | null>,
    action: string,
    item?: T | []
}

export type TResetDelete<T> = {
    id: number,
    item: T,
    query: (obj: T | number) => Promise<T | null>,
    header: string,
    component: React.ComponentType<{ id: number; item: T; query: (obj: number | T) => Promise<T | null>; onClose: () => void; }>
}

const CRUD = <T extends { [key: string]: any }>({ config }: { config: IConfig<T> }) => {

    const { pageList, addPage } = useSequentialHook();

    const { dealershipGroupId, dealershipId, storedField, setTableRecord, setStoredField } = useSelectedRecordHook();

    const navigate = useNavigate();

    const { crud, queryNameList, bulkUpload, pathName, fetchListPathName } = useMemo(() => {
        const crud: ICRUD<T> & ResetDataType = config.crud;
        const queryNameList: string = config.queryNameList;
        const bulkUpload: BatchConfigObj = { ...config.bulk, pathName: config.pathName, queryNameList: config.queryNameList }
        const pathName: string = config.pathName;

        let fetchListPathName: string = config.pathName;
        if (dealershipGroupId.dealershipGroupId !== "" && dealershipId.dealershipId !== "") {
            fetchListPathName = config.pathName + "?" + "dealershipGroupId=" + dealershipGroupId.dealershipGroupId + "&" + "dealershipId=" + dealershipId.dealershipId
        }
        else if (dealershipGroupId.dealershipGroupId !== "") {
            fetchListPathName = config.pathName + "?" + "dealershipGroupId=" + dealershipGroupId.dealershipGroupId
        }
        else if (dealershipId.dealershipId !== "") {
            fetchListPathName = config.pathName + "?" + "dealershipId=" + dealershipId.dealershipId
        }
        return { crud, queryNameList, bulkUpload, pathName, fetchListPathName };
    }, [config]);


    const [selectedRow, setSelectedRow] = useState(-1);
    const [close, setClose] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [crudOperation, setCrudOperation] = useState("");
    const [manipulatedData, setManipulatedData] = useState<Array<T>>()
    const [tableData, setTableData] = useState<Array<DisplayTableRows>>([])
    const { lockPageScroll, unlockPageScroll } = useScrollBodyLock()
    const [relationalData, setRelationalData] = useState<RelationDataType>()
    const [loading, setLoading] = useState<boolean>(false);
    const [customAnnouncement, setCustomAnnouncement] = useState<boolean>(false);
    const [vehicleCreationData, setVehicleCreationData] = useState<any>({})

    const updateQuery = useUpdateSingle<T>(updateObj, selectedRow, queryNameList, pathName);
    const resetQuery = useCreateSingle<T>(createObj, queryNameList, crud?.resetPathName || "");
    const createQuery = useCreateSingle<T>(createObj, queryNameList, pathName === "new-vehicle" ? "generate-vehicle-brochure" : pathName);
    const deleteQuery = useDeleteSingle<T>(deleteObj, queryNameList, pathName);


    // fetch all the data from the database
    const { isFetching, data, invalidateQuery } = useFetchList<T>(fetchAll, queryNameList, fetchListPathName);

    const finalData = useMemo(() => {
        //Transforming data for display table
        return flattenData(data || [], crud?.excludeFields)
    }, [data])

    // display of relational table data
    const openRelationalTable = (id: number, queryName: string, pathName: string) => {
        lockPageScroll()
        setRelationalData({
            displayTable: true, id, queryName, pathName
        })
    }

    const closeRelationalTable = () => {
        unlockPageScroll()
        setRelationalData((previous) => previous && { ...previous, displayTable: false })
    }

    // sort table data
    const [sortSetting, setSortSetting] = useState<SortSettingType>({ [crud?.table.field[0].dataType || ""]: "asc" })

    const sortColumn = (columnKey: string) => {
        if (typeof (sortSetting?.[columnKey]) === "undefined") {
            setSortSetting({ [columnKey]: "asc" })
            return
        }
        setSortSetting({ [columnKey]: sortSetting[columnKey] === "desc" ? "asc" : "desc" })
    }

    //Close CRUD SideBar
    const handleClose = () => {
        unlockPageScroll()
        setClose(false)
    }

    const openCrudUI = (itemId: number, rowId: number, crudType: "delete" | "update" | "resetPassword" | "") => {
        setTableRecord(crud?.title || "", itemId)
        setSelectedRow(itemId);
        setSelectedIndex(rowId);
        setClose(true);
        setCrudOperation(crudType)
        lockPageScroll()
    }

    useEffect(() => {
        const tempData = manipulatedData?.map((item, index) => {
            return ({
                rowData: [
                    {
                        data: <ActionCell index={index} item={item} title={crud?.title || ""} reset={crud?.resetPathName ? true : false} setStore={setStoredField} crudRecord={openCrudUI} nestedInputs={crud?.nestedInputs} />,
                        clickableCallback: () => { }
                    },
                    ...crud?.table.field.map((field) => {
                        return (TableCell(item, index, field, openRelationalTable))
                    }) || [{ data: <></>, clickableCallback: () => { } }]
                ]
            })
        }) || []
        setTableData(tempData)
    }, [selectedRow, data, manipulatedData, sortSetting])

    let crudProps: TCrudProps<T>;
    switch (crudOperation) {
        case "create":
            crudProps = {
                header: "Create ",
                fields: crud?.create.field || [],
                query: createQuery,
                action: "created",
                item: undefined
            }
            break;

        case "update":
            crudProps = {
                header: "Update ",
                fields: crud?.update.field,
                query: updateQuery,
                action: "updated",
                item: manipulatedData?.[selectedIndex] || []
            } as TCrudProps<T>
            break;

        default:
            crudProps = {
                header: "",
                fields: [],
                query: () => Promise.resolve(null),
                action: "",
                item: undefined
            }
    }

    let deleteResetProps: TResetDelete<T>;
    switch (crudOperation) {
        case "delete":
            deleteResetProps = {
                id: selectedRow,
                item: manipulatedData?.[selectedIndex] || [],
                query: deleteQuery,
                header: `Delete ${crud?.title}`,
                component: Delete
            } as TResetDelete<T>
            break;
        case "resetPassword":
            deleteResetProps = {
                id: selectedRow,
                item: manipulatedData?.[selectedIndex] || [],
                query: resetQuery,
                header: "Reset Password",
                component: ResetPassword
            } as TResetDelete<T>
            break;

        default:
            deleteResetProps = {
                id: -1,
                item: [] as unknown as T,
                query: () => Promise.resolve(null),
                header: "",
                component: () => <></>
            } as TResetDelete<T>
            break;
    }

    return (
        <div className="text-sm pt-2 pb-5 text-left w-full">
            {/* Sidebar for Relational Table Display */}
            {
                relationalData?.displayTable &&
                <SideBarWrapper closeMenu={closeRelationalTable} sideBarTag={crud?.title.toLowerCase() || ""}>
                    <div className="w-[250px] md:w-[600px]">
                        <TableRelationalDisplay<T> id={relationalData?.id} queryName={relationalData?.queryName} pathName={relationalData?.pathName} />
                    </div>
                </SideBarWrapper>
            }

            <div className="border-t border-stone-200 w-full py-2" />

            {/* Header */}
            <div className="flex flex-row w-full justify-between px-2">
                <div className="flex flex-row pb-3">
                    <h1 className="text-lg text-gray-800 font-bold pr-3 h-8">{
                        storedField.storedFieldName === "title" ? crud?.title + " for " + storedField.storedValue : crud?.title
                    }</h1>
                    <div className="pt-[4px]">
                        <Tooltip tooltipInfo={crud?.tooltip.description || ""} header={crud?.tooltip.title || ""} />
                    </div>
                    {
                        pageList.length > 0 &&
                        <div className="my-auto pl-3">
                            <ClickableStepper currentTitle={crud?.title || ""} />
                        </div>
                    }
                </div>
                <div className="flex flex-row gap-[10px]">
                    {
                        bulkUpload.headers && featureSwitches.createMultiple &&
                        <>
                            <button className="text-xs text-gray-800 bg-gray-100 border border-gray-300 justify-end p-2 rounded-md hover:bg-gray-300 h-8 disabled:bg-gray-400 disabled:cursor-not-allowed "
                                disabled
                                onClick={()=>{
                                    addPage({ pageName: crud.title })
                                    navigate('/directory' , { state: { title: crud?.title || "" } })
                                }}
                            >
                                View Asset Directory
                            </button>
                            <button className="text-xs text-gray-800 bg-gray-100 border border-gray-300 justify-end p-2 rounded-md hover:bg-gray-300 h-8"
                                onClick={() => {
                                    addPage({ pageName: crud.title })
                                    navigate('/management/bulk-upload', { state: { bulkUpload: bulkUpload, title: crud?.title || "" } })
                                }}
                            >
                                Create Multiple
                            </button>
                        </>
                    }
                    {
                        featureSwitches.createSingle &&
                        <>
                            <button className="text-xs text-gray-800 bg-gray-100 border border-gray-300 justify-end p-2 rounded-md hover:bg-gray-300 h-8"
                                onClick={
                                    () => {
                                        lockPageScroll()
                                        setClose(previous => !previous)
                                        setCrudOperation("create")
                                    }
                                }>
                                Create Single
                            </button>
                        </>
                    }
                </div>
            </div>

            {/*Sidebar for CRUD operations*/}
            {
                close &&
                <>
                    {featureSwitches.delete && crudOperation === "delete" && (
                        <PopUpWrapper closeMenu={handleClose} parentTag={crud?.title.toLowerCase() || ""}>
                            <div className="w-full">
                                <ResetDelete component={deleteResetProps.component} id={deleteResetProps.id} header={deleteResetProps.header} item={deleteResetProps.item} query={deleteResetProps.query} onClose={handleClose} />
                            </div>
                        </PopUpWrapper>
                    )}
                    {crudOperation === "resetPassword" && (
                        <PopUpWrapper closeMenu={handleClose} parentTag={crud?.title.toLowerCase() || ""}>
                            <div className="w-full">
                                <ResetDelete component={deleteResetProps.component} id={deleteResetProps.id} header={deleteResetProps.header} item={deleteResetProps.item} query={deleteResetProps.query} onClose={handleClose} />
                            </div>
                        </PopUpWrapper>
                    )}
                    {featureSwitches.edit && crudOperation !== "delete" && crudOperation !== "resetPassword" && (
                        <PopUpWrapper closeMenu={handleClose} parentTag={crud?.title.toLowerCase() || ""}>
                            <div className="w-full">
                                <div className='flex justify-between pt-4 pl-4 '>
                                    <h2 className="text-lg  font-bold">{crudProps.header}{crud?.title}</h2>
                                </div>
                                <CreateUpdate onClose={handleClose} item={crudProps.item} fields={crudProps.fields} query={crudProps.query} action={crudProps.action} pathName={pathName} setReturnData={pathName === "new-vehicle" ? setVehicleCreationData : undefined} />
                            </div>
                        </PopUpWrapper>
                    )}
                </>
            }
            {/*Table Display*/}
            {
                !isFetching && data && !loading ?
                    <div className="px-2">
                        {/* Filter , Search , Refresh Bar*/}
                        <div className="flex flex-row w-full">
                            <DataManipulationBar data={finalData || []} setManipulatedData={setManipulatedData} dataFields={crud?.table.field.map((item) => item.dataFieldName) || []} sortSetting={sortSetting} tableHeader={crud?.table.field.map((item) => item.header)} />
                            <div className="absolute ml-[280px] min-[380px]:ml-[300px] md:ml-[370px]">
                                <button onClick={invalidateQuery} className="bg-white rounded-md p-2 shadow-lg hover:bg-gray-300 h-7.5 " title="Refresh table"><HiOutlineRefresh /></button>
                            </div>
                        </div>
                        {
                            crud?.table.field?.[0].header &&
                            //Table Display
                            <DisplayTable
                                headers={
                                    [
                                        <p className="uppercase">Actions</p>,
                                        ...crud?.table.field.map((fieldObj, idx) => {
                                            return (<HeaderCell key={fieldObj.header + idx.toString()} title={fieldObj.header} sortCallback={() => { sortColumn(fieldObj.dataFieldName) }} sortDir={sortSetting[fieldObj.dataFieldName]} />)
                                        })
                                    ]
                                }
                                data={tableData}
                            />
                        }

                    </div>
                    :
                    <div className="px-2 bg-gray-400 rounded-md w-screen h-screen animate-pulse " />
            }
            {
                pathName !== "new-vehicle" ?
                    <div>
                    </div>
                    :
                    <button className="hover:bg-blue-300" onClick={() => { setCustomAnnouncement(prev => !prev) }}>
                        View Image Upload
                    </button>
                    
            }
            {
                pathName==="new-vehicle" && customAnnouncement &&
                <PopUpWrapper closeMenu={() => { console.log("close"); setCustomAnnouncement(prev => !prev); setVehicleCreationData({})}} parentTag={""}>
                    <BrochureAssetUploader shortLink={vehicleCreationData?.customerProposalShortLink || "Y3ey9L"} brochureLink={`tvsd.proposal.intg.gotvsd.com/${vehicleCreationData?.customerProposalShortLink || "Y3ey9L"}`} />
                </PopUpWrapper>
            }
            {
                pathName==="new-vehicle" && vehicleCreationData?.customerProposalShortLink &&
                <PopUpWrapper closeMenu={() => { console.log("close"); setCustomAnnouncement(prev => !prev); setVehicleCreationData({})}} parentTag={""}>
                    <BrochureAssetUploader shortLink={vehicleCreationData?.customerProposalShortLink || "Y3ey9L"} brochureLink={`tvsd.proposal.intg.gotvsd.com/${vehicleCreationData?.customerProposalShortLink || "Y3ey9L"}`} />
                </PopUpWrapper>
            }

        </div>
    )
}

export default CRUD;
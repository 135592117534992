import { BiErrorCircle } from "react-icons/bi";
import { FormInputChange } from "../Form";

export interface IJsonField {
  keyName: string,
  primitiveDataType: string,
  formComponent: {
    type: "json",
    label: string,
    placeholder?: string,
    disabled?: boolean,
    required?: boolean
  }
  defaultValue?: JSON,
  error?: any
}

const JsonField = ({ keyName, defaultValue, handleInputChange, formComponent, error }: IJsonField & FormInputChange) => {
  return (
    <div key={keyName}>
      <label htmlFor={keyName} className="block mb-2 text-sm font-medium text-gray-900 pt-5">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
      <textarea
        className={`bg-gray-50 border ${error ? `border-gray-500` : `border-gray-300`} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 hover:border-blue-500 `}
        placeholder={formComponent.placeholder}
        defaultValue={JSON.stringify(defaultValue)}
        disabled={formComponent.disabled}
        required={formComponent.required}
        onChange={(e) => {
          try {
            handleInputChange(keyName, JSON.parse(e.target.value))
          }
          catch (e) {
            console.log("Error parsing JSON")
          }
        }}
      />
      {
        error &&
        <div className="flex flex-row gap-x-1 items-center">
          <div className="pt-1">
            <BiErrorCircle color="red" />
          </div>
          <p className="text-red-600 text-sm pt-1">{error}</p>
        </div>
      }
    </div>
  )
}

export default JsonField;
import { useEffect } from "react";
// Hook
export const useOnClickOutside = (ref: React.RefObject<any> | null, handler: Function, secondaryRef?: React.RefObject<any> | null) => {
    useEffect(() => {
        const listener = (event: any) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref?.current || ref.current.contains(event.target) || (secondaryRef && secondaryRef.current.contains(event.target))) {
                return;
            }
            handler();
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
}
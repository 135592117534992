import axios from "axios";
import { api_host } from "../config/hostConfig";

export interface ILoginInfo {
    username: string;
    password: string;
}

export const verifyLogin = async (userInfo : ILoginInfo) =>{
    //tentative
    const api_url = `${api_host}/api/login`;

    try{
        const response = await axios.post(api_url, userInfo)

        if(response.status !== 200) return null;

        const token = response?.data?.token || null
        
        //confirm type of the response // return the token
        return token
    }catch (error: any){
        console.log("error ", error)
        return
    }
}

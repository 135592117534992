import { BiErrorCircle } from "react-icons/bi";

export interface IDisplayImage {
    keyName: string,
    primitiveDataType: string,
    formComponent: {
        type: "image",
        label: string,
        required?: boolean
    },
    value?: string,
    error?: any
}

const DisplayImage = ({ keyName, formComponent, value = "", error }: IDisplayImage) => {
    //if checkbox input
    return (
        <div>
            <div key={keyName}>
                <label htmlFor={keyName} className="block mb-2 text-sm font-medium text-gray-900 pt-5" >{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
                <div className="flex items-center justify-center w-full">
                    <label className={`flex flex-col w-full border-2 ${error ? `border-red-500` : `border-gray-500`}`}>
                        <div className='p-3 flex-center'>
                            {
                                value !== "" ?
                                    <>
                                        <p className="pb-2">Uploaded File: {value}</p>
                                        <img src={value?.toString()} alt="Not image" />
                                    </>
                                    :
                                    <p>File not uploaded yet</p>
                            }

                        </div>
                    </label>
                </div>
                {
                    error &&
                    <div className="flex flex-row gap-x-1 items-center">
                        <div className="pt-1">
                            <BiErrorCircle color="red" />
                        </div>
                        <p className="text-red-600 text-sm pt-1">{error}</p>
                    </div>
                }
            </div>
        </div>

    )
}

export default DisplayImage;
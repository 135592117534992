import { NumericalCell, TextCell } from "../components/DisplayTable"

// check if the uploaded file is of an image type
export const isImageLink = (value: string) => {

    let isImage = false
    try {
        isImage = value.match(/\.(jpg|jpeg|png|gif|bmp|webp)$/i) ? true : false
    } catch (err) {
        console.log(err, "Error Here")
    }
    return isImage
}

// obtain the cookie
export function getCookie(token: string) {
    let name = token + "=";
    var cookieArray = document.cookie.split(';');

    for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') cookie = cookie.substring(1);
        if (cookie.indexOf(name) === 0) return cookie.substring(name.length, cookie.length);
    }
    return "";
}

// flatten the object using dot notation
export function flattenObj<T extends { [key: string]: any }>(obj: T, excludeFields: string[] = [], prefix = ""): { [key: string]: unknown } {
    const result: { [key: string]: unknown } = {};
    Object.keys(obj).map((key) => {

        const value = obj[key];
        if (excludeFields.includes(key)) {
            return result[`${prefix}${key}`] = value;
        }
        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            const flattened = flattenObj(value, excludeFields, `${prefix}${key}.`);
            Object.assign(result, flattened);
        } else {
            result[`${prefix}${key}`] = value;
        }
    });

    return result;
}

export function flattenData<T extends { [key: string]: any }>(data: T[], excludeFields: string[] = []): { [key: string]: unknown }[] {
    return data?.reduce((result: { [key: string]: any }[], item) => {
        const flattenedItem = flattenObj(item, excludeFields, "");
        result.push(flattenedItem);
        return result;
    }, []) || data;
}

// obtain the table cell type 
export const cellFunction = (input: string): Function => {
    switch (input) {
        case "string":
            return TextCell;
        case "number":
            return NumericalCell;
        case "boolean":
            return NumericalCell;
        default:
            return TextCell;
    }
}

// create nested object from flattened data
export function obtainNested(flatObj: { [key: string]: any }) {
    const result = {};
    for (const key in flatObj) {
        const parts = key.split('.'); //split the key by .

        let currentObj: { [key: string]: any } = result;

        for (let i = 0; i < parts.length - 1; i++) {
            const part = parts[i];
            if (!currentObj[part]) {
                currentObj[part] = {};
            }
            currentObj = currentObj[part];
        }

        currentObj[parts[parts.length - 1]] = flatObj[key];
    }
    return result;
}

// obtain the default value based on the declared primitive type
export function getDefaultWithPrimitiveType(primitiveType: string) {
    switch (primitiveType) {
        case "string":
            return ""
        case "boolean":
            return false
        case "number":
            return 0
        case "object":
            return {}
        case "array":
            return []
        default:
            return ""
    }
}

// type is the inputType and keyValue is the value to be validated
export const validatorType = (type: string, keyValue: any) => {

    switch (type) {
        case "email":
            if (!keyValue.match("[a-z0-9._%+-]+@[a-z]+[.]{1}[a-z]{2,3}$"))
                return ("Enter a valid email address. Eg: john@doe.com")
            return ("")
        // break;
        //   return("[a-z0-9._%+-]+@[a-z]+[.]{1}[a-z]{2,3}$")
        case "phone":
            if (!keyValue.match("[0-9]{8,}$"))
                return ("Enter a valid phone number.")
            return ("")
        case "year":
            console.log("in years: ")
            if (!keyValue.match("[1-2]{1}[0,9]{3}$"))
                return ("Enter a valid year.")
            return ("")
        case "country_code":
            console.log("country code: " , keyValue.match("[+]{1}[0-9]{1,}$"))
            if (!keyValue.match("[+]{1}[0-9]{1,}$"))
                return ("Enter a valid country code. Eg: +65")
            return ("")
        // return ("[+]{1}[0-9]{1,}$")
        case "number float":
            if (!keyValue.match("[0-9]+([.][0-9]*)?|[.][0-9]+|^null$"))
                return ("Enter a valid number.")
            return ("")
        // return ("[0-9]+([.][0-9]*)?|[.][0-9]+|^null$")
        default:
            // regular text with no validation
            return ("")
    }
}

// function to convert flat object to nested object , which is done prior to submission
export function createUpdateNested(flatObj: { [key: string]: any }) {
    const result = {};
    for (const key in flatObj) {
      const parts = key.split('.'); //split the key by .
      let currentObj: { [key: string]: any } = result;
      for (let i = 0; i < parts.length - 1; i++) {
        const part = parts[i];
        if (!currentObj[part]) {
          currentObj[part] = {};
        }
        currentObj = currentObj[part];
      }
      if (typeof flatObj[key] === "number" && (flatObj[key] === -1 || flatObj[key] === 0)) {
        continue
      }
      if (typeof flatObj[key] === "string" && flatObj[key] === "") {
        continue
      }
      if (typeof flatObj[key] === "object" && JSON.stringify(flatObj[key]) === "{}") {
        continue
      }
      currentObj[parts[parts.length - 1]] = flatObj[key];
    }
    return result;
  }
import { AiOutlineDoubleRight } from 'react-icons/ai';

interface IStepper {
    headers: Array<string>,
    selectedIdx: number,
    setSelectedIdx: (id: number) => void,
}

const Stepper = ({ headers, setSelectedIdx, selectedIdx }: IStepper) => {

    return (
        <div className="flex flex-row gap-2 w-full align-middle">
            {
                headers.map((header, idx) => {
                    return (
                        <div className="flex flex-row align-middle text-xs" key={idx}>
                            <p className={`border rounded-full w-4 h-4 mr-2 flex items-center justify-center ${idx === selectedIdx  || selectedIdx===-1 ? `text-blue-800 border-blue-800`: `text-black border-black`}`}>{idx + 1}</p>
                            <button className={idx === selectedIdx || selectedIdx===-1 ? "mb-1 pr-1 text-blue-800 cursor-pointer " : ' mb-1 pr-1 text-black'}  disabled onClick={() => { setSelectedIdx(idx); }}>
                                {header}
                            </button>
                            {
                                idx !== headers.length - 1 &&
                                <div className="mt-1"><AiOutlineDoubleRight /></div>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}
export default Stepper;
import { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { FiLogOut, FiMenu } from "react-icons/fi"
import AlertToast from "../../components/AlertToast";
import CRUD, { IConfig } from "../../components/CRUD";

import { Route, Routes } from "react-router-dom";
import BatchUploadMaster from "../BatchUpload/BatchUploadMaster";
import { getUserConfig } from "../../api/configAPI";
import Vehicle from "../Vehicle/Vehicle";
import { SpinnerLoader } from "../../components/Loader";

interface ICMSTable<T> {
    title: string,
    parent: string,
    tableName: string,
    path: string,
    active: boolean,
    config?: IConfig<T>,
    page?: JSX.Element
}

const headerParents = ["Manage Table"]

// const vehicleCmsTables = [
//     {
//         title: "Salesperson",
//         parent: "Manage Table",
//         path: "/salesperson",
//         tableName: "salesperson",
//         active: true
//     },
//     {
//         title: "Dealership",
//         parent: "Manage Table",
//         path: "/dealership",
//         tableName: "dealership",
//         active: true
//     },
//     {
//         title: "Person",
//         parent: "Manage Table",
//         path: "/person",
//         tableName: "person",
//         active: true
//     },
//     {
//         title: "Branch",
//         parent: "Manage Table",
//         path: "/branch",
//         tableName: "branch",
//         active: false
//     },
//     {
//         title: "State",
//         parent: "Manage Table",
//         path: "/state",
//         tableName: "state",
//         active: false
//     },
//     {
//         title: "Dealership Group",
//         parent: "Manage Table",
//         path: "/dealership-group",
//         tableName: "dealership_group",
//         active: false
//     },
//     {
//         title: "Vehicle",
//         parent: "Manage table",
//         path: "/vehicle",
//         tableName: "vehicle",
//         page:<Vehicle />,
//         active: true
//     },
//     {
//         title: "Vehicle Variant",
//         parent: "Manage Table",
//         path: "/vehicle-variant",
//         tableName: "vehicle_variant",
//         active: false
//     },
//     {
//         title: "New Vehicle",
//         parent: "Manage Table",
//         path: "/new-vehicle",
//         tableName: "new_vehicle",
//         active: false
//     },
//     {
//         title: "Vehicle Configuration",
//         parent: "Manage Table",
//         path: "/vehicle-configuration",
//         tableName: "vehicle_configuration",
//         active: false
//     },
//     {
//         title: "Variant",
//         parent: "Mass Upload",
//         path: "/variant",
//         tableName: "variant",
//         active: false
//     },
//     {
//         title: "Sequential Form",
//         parent: "Mass Upload",
//         path: "/sequential-form",
//         tableName: "sequential_form",
//         active: false
//     },
//     {
//         title: "Batch Upload",
//         parent: "Mass Upload",
//         path: "/batch-upload",
//         tableName: "batch-upload",
//         page: <BatchUploadMaster />,
//         active: false
//     },
//     {
//         title: "Vehicle",
//         parent: "Manage Table",
//         path: "/vehicle-dealerships",
//         tableName: "vehicle-dealerships",
//         page: <Vehicle />,
//         active: true
//     }
// ]

const Management = <T extends { [key: string]: any }>() => {

    const [currentTableIdx, setCurrentTableIdx] = useState<number>(0);
    const [openNavBar, setOpenNavBar] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [cookie, setCookie, removeCookie] = useCookies(['token'])
    const [username, setUsername] = useState<string>(localStorage.getItem("username") || "");
    const [CMS, setCMS] = useState<Array<ICMSTable<T>>>([])
    const [navBarTitles, setNavBarTitles] = useState<Array<Array<string>>>([])
    const navigate = useNavigate();

    useEffect(() => {
        // Return users to login page when token is not found
        if (cookie.token === "" || cookie.token === undefined || document.cookie === "") {
            localStorage.setItem("pathFrom:", "/management")
            removeCookie("token")
            navigate("/login")
        };
    }, [currentTableIdx, cookie])

    useEffect(() => {
        let cmsTableConfig: Array<ICMSTable<T>> = []
        let configTableNameArray: Array<Array<string>> = []
        const fetchConfig = async () => {
            setLoading(true)

            // Obtain the list of configs that a user can access
            const configList = (await getUserConfig())?.message
            if (!configList) return

            configList.map((config: any) => {

                const configMessage: IConfig<T> = config

                let tableItem: ICMSTable<T> = {
                    title: configMessage?.crud?.title || "",
                    parent: "Manage Table",
                    path: "/" + configMessage?.pathName || "",
                    tableName: configMessage?.crud?.title || "",
                    config: configMessage,
                    active: true
                }
                cmsTableConfig = [...cmsTableConfig, tableItem]
                configTableNameArray = [...configTableNameArray, [tableItem.title, tableItem.parent, tableItem.path]]
            })
            setCMS(cmsTableConfig)
            setNavBarTitles(configTableNameArray)
        }
        if (cookie.token) {
            fetchConfig()
            setLoading(false)
        }
    }, [])

    const clearAllCookies = () => {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    };

    return (
        <div className="flex flex-row overflow-x-hidden">
            {
                !loading ?
                    <div>
                        {
                            // Navbar column
                            !openNavBar ?
                                // Closed Navbar        
                                <div className=" bg-blue-800 flex justify-between fixed top-0 left-0 h-screen align-text-top w-[40px] flex-col">
                                    <button className="pt-3 h-fit m-2.5" onClick={() => { setOpenNavBar(previous => !previous) }}>
                                        <FiMenu size={"16px"} color={"white"} />
                                    </button>
                                    <div className="bg-blue-900">
                                        <button className=" text-white shadow-md text-sm hover:bg-gray-600 m-2.5"
                                            onClick={() => {
                                                setUsername("");
                                                localStorage.clear();
                                                removeCookie("token");
                                                clearAllCookies();
                                                navigate("/login")
                                            }}>
                                            <FiLogOut size={"16px"} color="white" />
                                        </button>
                                    </div>
                                </div>
                                :
                                // Opened Navbar
                                <div className="flex flex-col h-screen fixed top-0 left-0 min-w-10 break-before-all max-w-[170px] translate-x-0 ease-in-out">
                                    <NavBar title="Content Management System" headers={
                                        navBarTitles.length > 0 ? navBarTitles : []
                                    } selectedIdx={currentTableIdx} setSelectedIdx={setCurrentTableIdx} parents={headerParents} setNavBarOpen={setOpenNavBar} />

                                    {/*Logout */}
                                    <button className="bg-blue-900 text-white p-2 shadow-md text-sm hover:bg-gray-600"
                                        onClick={() => {
                                            setUsername("");
                                            localStorage.clear();
                                            setCookie("token", "")
                                            navigate("/login")
                                        }}>
                                        <p className="text-left ">{username}</p>
                                        <div className="flex flex-row">
                                            <p>Logout</p>
                                            <div className="pt-1  pl-2"><FiLogOut color="white" /></div>
                                        </div>
                                    </button>
                                </div>
                        }
                        {
                            // Main Content Column
                            <div className="w-screen">
                                <div className={`flex flex-col pt-2 min-h-screen ${openNavBar ? `pl-[170px]` : `pl-[40px]`}  overflow-auto min-w-full`}>
                                    {
                                        // Creation of routes for each config table
                                        !loading && CMS.length > 0 ?
                                            <Routes>
                                                {
                                                    CMS.map((cmsIndividualTables) => {
                                                        return <Route key={cmsIndividualTables.path} path={cmsIndividualTables.path} element={<CRUD config={cmsIndividualTables?.config as IConfig<T>} />} />
                                                    })
                                                }
                                                <Route key={"/bulk-upload"} path={"/bulk-upload"} element={<BatchUploadMaster />} />
                                                <Route key={"/vehicle-dealerships"} path={"/vehicle-dealerships"} element={<Vehicle />} />
                                            </Routes>
                                            :
                                            <div className="h-screen">
                                                <SpinnerLoader />
                                            </div>
                                    }
                                </div>

                                {/** Toast box messages*/}
                                <div className="z-20">
                                    <AlertToast />
                                </div>
                            </div>
                        }
                    </div>
                    :
                    // Loading Screen
                    <div className="h-screen w-screen">
                        <SpinnerLoader />
                    </div>
            }
        </div>
    )
}

export default Management;
import axios from "axios"
import { api_host } from "../config/hostConfig"
import { getAuthenticationToken } from "./apiAuthentication"

export type TDataInputs = {
    id: number,
    data: Array<any>
}


export type TValidateDuplicates ={
    data : Array<TDataInputs>,
    keyHeader : Array<string>,
    fileHeader: Array<string>,
    config: {keys:Array<string>}
}

type TValidateSelfAndDatabase = {
    data : Array<TDataInputs>,
    keyHeader : Array<string>
}

// check if there are duplicated composite keys in the database
export const validateDuplicates = async (inputData: TValidateDuplicates): Promise<any|null> => { //get the string
    const api_url = `${api_host}/api/validate-duplicates`
    try{
        // check for the authentication of the user
        const response = await axios.post(api_url, inputData, getAuthenticationToken())
        if(response.status !== 200) return null
        return response.data || null
    }
    catch (err: any){
        console.log(err?.response?.data)
        return err?.response?.data || null
    }
}

// validate if object shape is correct and it does not exist in the database
export const validateSelfAndDatabase = async (inputData: TValidateSelfAndDatabase , path:string): Promise<any|null> => { //get the string

    const api_url = `${api_host}/api/${path}/validation`

    try{
        // check for the authentication of the user
        const response = await axios.post(api_url, inputData, getAuthenticationToken())
        if(response.status !== 200) return null
        return response.data || null
    }
    catch (err: any){
        console.log(err?.response?.data)
        return err?.response || null
    }
}
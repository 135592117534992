import { api_host } from "../config/hostConfig"
import { deleteWithToken, getAllWithParamsToken, getAllWithToken, getSingleWithToken, patchWithToken, postWithToken } from "./apiAuthentication"

export const fetchAll = async <T,>( path: string) => {
    const api_url = `${api_host}/api/${path}`
    return await getAllWithToken<T[]>(api_url)
}

export const fetchIndividual = async <T,>(path: string, id: number) => {
    const api_url = `${api_host}/api/${path}`
    return await getSingleWithToken<T>(api_url, id)
}


//added
export const fetchWithParam = async <T,>(path: string, param: string, value: any) => {
    const api_url = `${api_host}/api/${path}?${param}=${value}`
    return await getAllWithToken<T[]>(api_url)
}

export const fetchWithoutParamAPI = async <T,>(path: string , param: string , value: string) => {
    const api_url = `${api_host}/api/${path}`
    return await getAllWithParamsToken<T[]>(api_url , param , value)
}
//

export const createObj = async <T,>(path: string, newObj: object) => {
    const api_url = `${api_host}/api/${path}`
    return await postWithToken<T>(api_url, newObj)
}

export const updateObj = async<T,>(path: string, id: number, newObj: object):Promise<T|null> => {
    const api_url = `${api_host}/api/${path}`
    return await patchWithToken<T>(api_url, id, newObj)
}

export const deleteObj = async (path: string, id: number) => {
    const api_url = `${api_host}/api/${path}`
    return await deleteWithToken(api_url , id)
}
import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Management from './pages/Management/Management';
import { QueryClient, QueryClientProvider } from 'react-query';
import Login from './pages/Login/Login';
import DealershipDislay from './pages/TableInputs/DealershipGroupDisplay';
import { AssetManagementLibrary } from './pages/AssetManagementLibrary/AssetManagementLibrary';
// Import skeleton css from package
import 'react-loading-skeleton/dist/skeleton.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />
    },
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/management/*",
      element: <Management />
    },
    {
      path: "/directory",
      element: <AssetManagementLibrary />
    }
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <header className="App-header bg-gray-200">
          <RouterProvider router={router} />
        </header>
      </div>
    </QueryClientProvider>
  );
}

export default App;

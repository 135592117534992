import { useEffect, useState } from "react"
import { FormInputChange } from "../Form"
import { fetchDependablePath, useFetchDependablePath } from "../../api/manageDependableSelectApi"
import SelectDropdown, { ISelectDropdown } from "./SelectDropdownField"

export interface IDependableSelect {
    keyName: string,
    primitiveDataType: string,
    formComponent: {
        type: "multiple-select",
        pathName: string,
        label?: string,
        placeholder: string,
        options: ISelectDropdown[],
        disabled?: boolean,
        required?: boolean,
    }
    defaultValue?: string | number | readonly string[] | undefined,
    nestedIndex?: number,
}

const DependableSelectField = <T extends {[key:string]:any}>({ keyName, handleInputChange, formComponent, defaultValue , nestedIndex }: IDependableSelect & FormInputChange) => {

    const [data, setData] = useState<any[]>([])
    const [selectedData, setSelectedData] = useState<ISelectDropdown[]>(formComponent.options)
    const [loading, setLoading] = useState<boolean>(false)
    const { isFetching, data: fetchedData, invalidateQuery } = useFetchDependablePath<T>("defaultQueryName", formComponent.pathName)

    useEffect(() => {

        const fetchData = async () => {
            if(!fetchedData){
                return
            }
            const file = fetchedData[0]?.data[0].map((item: any) => {
                return item.dropdown[0]
            })

            setData(fetchedData)
            // get the select field options
            let firstDropdownOptions = formComponent.options[0].formComponent.options

            const existingValues = new Set(firstDropdownOptions.map(option => option.value))
            // filter out any data that is repeated
            const filteredFile = file.filter((fileItem: { value: any }) => !existingValues.has(fileItem.value))
            formComponent.options[0].formComponent.options = [...firstDropdownOptions, ...filteredFile]
            setSelectedData(formComponent.options)
        }
        fetchData()
    }, [])

    const handleSelectChange = (key:string, value: any , index:number) => {

        let comparisonValue = value
        if (selectedData[index].primitiveDataType === 'number' && typeof value !== 'number') {
            comparisonValue = parseInt(value);
        }
        // if the field selected is the last field, then do not proceed to find the next field
        if (index >= formComponent.options.length - 1) {
            // set the data to be the final value
            handleInputChange(key, comparisonValue)
            return
        }

        if (comparisonValue === -1) {
            selectedData[index + 1].formComponent.options = [selectedData[index + 1].formComponent.options[0]]
            setSelectedData(selectedData)
            return
        }

        let nextDropdownList: Array<any> = []
        // obtain the next list of data:
        const nextFormComponentData: Array<any> = data[index + 1]?.data[comparisonValue] || []
        // obtain the dropdown for the next list of data
        nextDropdownList = nextFormComponentData?.map((item: any) => {
            return item.dropdown
        })

        let dropdown = [selectedData[index + 1].formComponent.options[0]]
        dropdown.push(...nextDropdownList)
        selectedData[index + 1].formComponent.options = dropdown
        setSelectedData(selectedData)
    }

    const createChangeHandler = (index: number) => (key: string, value: any) => {
        handleSelectChange(key, value, index);
    };
    
    return (
        <div>
            <label htmlFor={keyName} className="block text-sm font-medium text-gray-900">{formComponent.label}{formComponent.required ? <span className="text-red-500"> *</span> : null}</label>
            <div key={keyName}>
                {
                    !fetchedData ? <div>Loading...</div>
                    :
                        selectedData.length > 0 &&
                        selectedData.map((item, idx) => {
                            const changeHandler = createChangeHandler(idx);
                            return (
                                    <SelectDropdown key={keyName+nestedIndex+idx} {...item} handleInputChange={changeHandler} defaultValue={defaultValue}/>
                            )
                        })
                }
            </div>
        </div>
    )
}

export default DependableSelectField;
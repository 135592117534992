import { useEffect, useState } from "react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import Stepper from "../../components/Stepper";
import BatchUploadValidator from "./BatchUploadValidator";
import BatchUploadFileUploader, { BatchHeader } from "./BatchUploadFileUploader";
import BatchUploadImageUploader from "./BatchUploadImageUploader";
import { BatchDatabaseUpload } from "./BatchDatabaseUpload";
import { useLocation, useNavigate } from "react-router";
import ClickableStepper from "../../components/ClickableStepper";

export interface BatchConfigObj {
  headers: BatchHeader[];
  pathName: string;
  queryNameList: string;
  compositeKeys?: string[];
}

//Main Page that manages the different steps of the batch upload
const BatchUploadMaster = () => {

  const location = useLocation();
  const title = location.state?.title || "Files"
  const navigate = useNavigate();


  const withImage = [0, 1, 2, 3,]
  const withoutImage = [0, 1, 3]
  const nestedPages = [{ "title": "Bulk Upload " + title }, { "title": "File Validation", }, { "title": "Upload Images", }, { "title": "Upload Into Database" }]
  const [activePage, setActivePage] = useState(0);
  const [data, setData] = useState<any>([]);
  const [stepper, setStepper] = useState<string[]>([]);
  const [headerVal, setHeaderVal] = useState<BatchConfigObj>({ headers: [], pathName: "", queryNameList: "" })
  const [pageArray, setPageArray] = useState<Array<number>>(withoutImage);

  const currentPage = nestedPages[activePage];
  let DisplayPage: JSX.Element = (<></>);

  // Selectable Dropdown for the different options
  // const selectableDropdown = [{ value: "", label: "Data Table" }, ...Object.keys(BatchUploadConfig).filter((key) => key !== "nestedPages").map((key) => { return ({ value: capitalise(key), label: capitalise(key) }) })]
  let defaultStepperArray = ["Upload File", "Validate and Rectify Data File", "Review and Update CSV Data", "Upload Images", "Upload to Database"]

  const handleNextPage = () => {
    setActivePage(pageArray[pageArray.indexOf(activePage) + 1]);
  };

  const handlePrevPage = () => {
    setActivePage(pageArray[pageArray.indexOf(activePage) - 1]);
  };

  useEffect(() => {
    // if there is a bulk upload object in the location state
    console.log("location.state\n", location.state.bulkUpload)

    if (location.state?.bulkUpload) {
      let headerObj: BatchConfigObj = location.state.bulkUpload
      setHeaderVal(headerObj)
      if (!headerObj) {
        setStepper(defaultStepperArray.slice(0, 3))
        return
      }
      const containImgField = checkImage(headerObj)
      // if there are any image field
      if (containImgField) {
        setStepper([...defaultStepperArray.slice(0, 2), ...defaultStepperArray.slice(3)])
        setPageArray(withImage)
      }
      else {
        // does not contain image field
        setStepper(defaultStepperArray.slice(0, 3))
      }
    }
  }, [])
  
  function checkImage(headerType: BatchConfigObj) {
    //check if there are any image headers
    return (headerType.headers.some((header) => header.type === "image"))
  }

  switch (activePage) {
    case 0:
      //main page for template and table selection
      DisplayPage = <BatchUploadFileUploader headers={headerVal.headers} page={activePage} setPage={setActivePage} setBatchData={setData} dataType={title} pageArray={pageArray} />
      break;
    case 1:
      //handles the data that have been validated and rectified
      DisplayPage = <BatchUploadValidator data={data} setPage={setActivePage} page={activePage} pageArray={pageArray} config={headerVal} headers={headerVal.headers} dataType={title} pathName={headerVal.pathName} queryNameList={headerVal.queryNameList} setBatchData={setData} />
      break;
    case 2:
      //set this as the last page
      //handles the data that will be uploaded to the database
      DisplayPage = <BatchUploadImageUploader headers={headerVal.headers} data={data} tableName={title.toLowerCase()} page={activePage} pageArray={pageArray} setPage={setActivePage} setBatchData={setData} />
      break;
    case 3:
      //handles upload into database
      DisplayPage = <BatchDatabaseUpload data={data} headers={headerVal.headers} pathName={headerVal.pathName} queryNameList={headerVal.queryNameList} dataType={title} />
      break;
    default:
      DisplayPage = <BatchUploadFileUploader headers={headerVal.headers} page={activePage} setPage={setActivePage} setBatchData={setData} dataType={title} pageArray={pageArray} />
      break;
  }

  console.log("batchData: " , data)

  return (
    <div className="bg-gray-100 text-left px-3 pt-6 h-full text-sm">
      <div className="flex flex-row gap-x-2">
        <h1 className='text-[#3D426B] font-bold capitalize text-lg pl-4'>{currentPage.title}</h1>
        {
          activePage === 0 &&
          <div className="pl-2 pt-1">
            <ClickableStepper currentTitle={currentPage.title} />
          </div>
        }
      </div>
      <div>
        <div className="flex flex-row justify-between">
          <div className="pl-2 pb-2">
            <button className={`bg-blue-700 text-white px-2 py-1 hover:bg-blue-500 ${activePage === nestedPages.length - 1 ? `rounded-sm` : `rounded-tl-sm rounded-bl-sm`}`} disabled={activePage === 0} hidden={activePage === 0} onClick={handlePrevPage}>
              <IoChevronBackOutline color="white" />
            </button>
            <button
              className="bg-blue-700 text-white px-2 py-1 rounded-tr-sm rounded-br-sm hover:bg-blue-500"
              disabled={activePage === nestedPages.length - 1} hidden={activePage === nestedPages.length - 1 || activePage === 0}
              onClick={handleNextPage}>
              <IoChevronForwardOutline color="white" />
            </button>
          </div>
          {
            activePage !== 0 &&
            <div className="text-xs pl-2">
              <Stepper selectedIdx={activePage} setSelectedIdx={setActivePage} headers={stepper} />
            </div>
          }
        </div>
        {
          activePage === 0 &&
          <div className="pt-2 px-2">
            <p className='text-sm pl-2'>Use this tool to upload multiple rows of data that are needed to display brochures.</p>
            <p className='text-sm pl-2'>The tool will follow flow that is provided below.</p>
            <div className="text-xs pl-2 pt-1">
              <Stepper selectedIdx={-1} setSelectedIdx={setActivePage} headers={stepper} />
            </div>
          </div>
        }
        <>
          {DisplayPage}
        </>
      </div>
    </div>
  );
}

export default BatchUploadMaster;
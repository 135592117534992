import FilterBar from "./FilterBar"
import { useState, useEffect } from 'react'
import Searchbar from "./Searchbar"
import { SortSettingType } from "../CRUD"
import { sortData } from "../../utils/sortingData"

interface IFilterSearch<T> {
    data: Array<T>,
    dataFields: Array<string>,
    setManipulatedData: Function,
    sortSetting?: SortSettingType
    tableHeader?: Array<string>
}

const DataManipulationBar = <T extends { [key: string]: any }>({ data, dataFields, setManipulatedData, sortSetting , tableHeader }: IFilterSearch<T>) => {

    const [filterData, setFilterData] = useState<Array<T>>(data)
    const [searchData, setSearchData] = useState<Array<T>>(data)

    useEffect(() => {
        if (sortSetting) {
            const sortKey = Object.keys(sortSetting)?.[0] || null
            if (sortKey) {
                setManipulatedData(sortData(searchData, sortKey, sortSetting[sortKey]))
                return
            }
        }

        // fail safe
        setManipulatedData(searchData)
    }, [searchData, filterData, sortSetting])



    return (
        <div className="flex flex-row w-full border-gray-900 rounded-md gap-2">
            <FilterBar data={data || []} columnNames={dataFields} setFilterData={setFilterData} tableHeader={tableHeader} />
            <div className="absolute ml-[70px] min-w[380px]:ml-[75px] md:ml-[80px] w-fit">
                <Searchbar data={filterData || []} columnNames={dataFields} setSearchResult={setSearchData} />
            </div>
        </div>
    )
}




export default DataManipulationBar;
import { useState } from "react"
import { useAlertToastHook } from "../hooks/alertToastHook"
import { SpinnerLoader } from "./Loader"
import { TableRelationalDisplayWithoutFetch } from "./TableRelationalDisplay"

interface IReset<T> {
    query: Function ,               // query to update
    onClose: () => void,
    id: number ,
    item: T,
}


const ResetPasswordPage = <T extends { [key: string]: any }>({query , onClose, id , item } : IReset<T>) => {

    const [isFetching, setIsFetching] = useState<boolean>(false)
    const { addMessage } = useAlertToastHook()

    const handleReset = async () => {
        setIsFetching(previous => !previous)

        const resetObj = {
            phone_number: item["person.phone_number"] ,
            country_code: item["person.country_code"]
        }
        
        const results = await query(resetObj as unknown as T)

        if(results){
            setIsFetching(previous => !previous)
            onClose();
            addMessage("Password has been resetted" , true)
        }
        else{
            setIsFetching(previous => !previous)
            onClose();
            addMessage("Error" , false)
        }
      }

    return(
        <>
            {
                isFetching?
                <SpinnerLoader/>
                :
                <div className="pt-3">
                    <TableRelationalDisplayWithoutFetch<T> data={item}/>
                    <div className="border-t border-gray-500 w-full py-2" />
                    <div className=" flex justify-center">
                        <button className="bg-white rounded-md text-sm hover:bg-slate-300 border border-gray-500 px-2 py-1 " onClick={() => handleReset()}>Reset Password</button>
                    </div>
                </div>
            }
        </>
    )
}

export default ResetPasswordPage;


interface PaginationItem {
  totalCount: number,
  pageSize: number,
  currentPage: number
}

const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ totalCount, pageSize, currentPage }: PaginationItem) => {

  const lastPage = Math.ceil(totalCount / pageSize)

  const startItemIndex = (currentPage-1) * pageSize
  const lastItemIndex = startItemIndex + pageSize > totalCount ? totalCount : startItemIndex + pageSize

  const paginationText = `${totalCount === 0 ? 0 : startItemIndex+1} - ${lastItemIndex} of ${totalCount}`

  return {startItemIndex, lastItemIndex, lastPage, paginationText};
};